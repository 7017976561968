import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react'

type NumberCellProps = FlexProps & {
  value: number,
}

const NumberCell = ({value, ...rest}: NumberCellProps) => {
  return (
    <Flex
      width="calc(25% - 1.5px)"
      minWidth="calc(25% - 1.5px)"
      background="linear-gradient(180deg, #ffefdc 0%, #fff5ef 100%)"
      color="#ff4218"
      fontSize="26px"
      fontWeight="500"
      lineHeight="1"
      aspectRatio="1/1"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      userSelect="none"
      {...rest}
    >
      {value}
    </Flex>
  )
}

export default NumberCell;
