import { decode } from '@/utils/crypto'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { AuthModel, LoginRequest, Player } from '@store/entities/auth.entity'
import authService from '@store/services/auth.service'
import { ServiceConfig } from '@store/services/service-config'
import { createThunk } from './common'

export interface AuthState {
  token: string
  user?: Player
  session_token: string
}

const initialState: AuthState = {
  token: '',
  session_token: '',
}

export const login = createThunk('auth/login', async (payload: LoginRequest) => {
  const { agency_id, session_token, access_token } = payload
  if (access_token) {
    const accessToken = await decode(access_token)
    if (!authService.isTokenExpired(accessToken)) {
      ServiceConfig.token = accessToken
      const player = await authService.loadPlayerInfo()
      const auth: AuthModel = { access_token: accessToken, player }
      return auth
    }
  }
  const auth = await authService.login(agency_id, session_token)
  return auth
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = ''
      state.user = undefined
      ServiceConfig.token = ''
      state.session_token = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.token = action.payload.access_token
      state.user = action.payload.player
      state.session_token = action.meta.arg.session_token!
      ServiceConfig.token = state.token
    })
  },
})

export const selectToken = (state: RootState) => state.auth.token
export const selectSessionToken = (state: RootState) => state.auth.session_token
export const selectUserId = (state: RootState) => state.auth.user?.id
export const selectPlayerName = (state: RootState) => state.auth.user?.name
export const selectUserCurrency = (state: RootState) => state.auth.user?.default_wallet.currency
export const selectUserName = (state: RootState) => state.auth.user?.name
export const selectUser = (state: RootState) => state.auth.user
export const selectAgencyId = (state: RootState) => state.auth.user?.agency_id
export const selectIsLogged = (state: RootState) => _selectIsLoggedIn(state)

const _selectIsLoggedIn = createSelector([selectToken], (token) => {
  return !!token && !authService.isTokenExpired(token)
})

export const { logout } = authSlice.actions
export default authSlice

export const authActions = {
  login,
}
