export function money(amount: number, minDecimal?: number, maxDecimal?: number) {
  return prettyPrintWallet(amount, minDecimal, maxDecimal)
}

export function prettyPrint(num: number, minDecimal?: number, maxDecimal?: number) {
  const opt: Intl.NumberFormatOptions = {}
  if (minDecimal) opt.minimumFractionDigits = minDecimal as number
  if (maxDecimal) opt.maximumFractionDigits = maxDecimal as number

  return new Intl.NumberFormat('en-US', opt).format(num)
}

export function prettyPrintWallet(num: number, minDecimal?: number, maxDecimal?: number): string {
  // Hàm làm tròn xuống số đến số chữ số thập phân nhất định
  function floorToDecimal(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals)
    return Math.floor(value * factor) / factor
  }

  const opt: Intl.NumberFormatOptions = {}
  if (minDecimal !== undefined) opt.minimumFractionDigits = minDecimal
  if (maxDecimal !== undefined) opt.maximumFractionDigits = maxDecimal

  // Làm tròn xuống số đến số chữ số thập phân maxDecimal
  const roundedNum = maxDecimal !== undefined ? floorToDecimal(num, maxDecimal) : num

  return new Intl.NumberFormat('en-US', opt).format(roundedNum)
}