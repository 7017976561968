import React from 'react';
import LotteryPattern from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern';
import { Flex } from '@chakra-ui/react';

const BottomRow = () => {
  return (
    <Flex
      flex="1"
      justifyContent="space-between"
      alignItems="center"
    >
      <LotteryPattern />
      <LotteryPattern />
      <LotteryPattern />
      <LotteryPattern />
      <LotteryPattern />
      <LotteryPattern />
      <LotteryPattern />
      <LotteryPattern />
    </Flex>
  )
}

export default BottomRow;
