// Function to shuffle and pick random numbers
import { bingoNumbers } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import { BingoGroup } from '@/bingo/types/bingo.ts'

const getRandomNumbers = (array: number[], count: number): number[] => {
  const shuffled = [...array].sort(() => Math.random() - 0.5); // Shuffle the array
  return shuffled.slice(0, count); // Pick the first `count` numbers
};

// Function to generate a flattened bingo card
export const generateBingoCard = (): number[] => {
  const columns: BingoGroup[] = ['B', 'I', 'N', 'G', 'O'];
  const bingoCard: number[] = [];

  for (const col of columns) {
    const randomNumbers = getRandomNumbers(bingoNumbers[col], 3); // Get 3 random numbers for the column
    bingoCard.push(...randomNumbers); // Flatten into the final array
  }

  return bingoCard;
};

export const convertToRowWiseCard = (input?: number[]): number[] => {
  if (!input || input.length === 0) return []

  const numRows = 3; // Bingo card has 3 rows
  const numCols = 5; // Bingo card has 5 columns

  const rowWiseCard: number[] = [];

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      rowWiseCard.push(input[col * numRows + row]);
    }
  }

  return rowWiseCard;
};

export const getBingoGroupByNumber = (value?: number): BingoGroup => {
  if (!value || value <= 0) return 'B'

  switch (true) {
    case value <= 12:
      return 'B'
    case value <= 24:
      return 'I'
    case value <= 36:
      return 'N'
    case value <= 48:
      return 'G'
    case value <= 60:
      return 'O'
    default:
      return 'B'
  }
}