import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react'

type PatternCaseProps = StackProps & {
  children: React.ReactNode;
}

const PatternContainer = ({children, ...rest}: PatternCaseProps) => {
  return (
    <Stack
      backgroundColor="#9e5d35"
      padding="1.2px"
      rowGap="1.2px"
      borderRadius="2.5px"
      minWidth="37.2px"
      width="37.2px"
      {...rest}
    >
      {children}
    </Stack>
  )
}

export default PatternContainer;
