import React from 'react';
import { Stack } from '@chakra-ui/react';
import TopRow from '@/bingo/components/GameplayStage/LotteryPatternsArea/TopRow';
import BottomRow from '@/bingo/components/GameplayStage/LotteryPatternsArea/BottomRow';
import GuideButton from '@/bingo/components/GameplayStage/LotteryPatternsArea/GuideButton.tsx'
import GuideButtonsContainer from '@/bingo/components/GameplayStage/LotteryPatternsArea/GuideButtonsContainer.tsx'

const LotteryPatternsArea = () => {
  return (
    <Stack
      height="40%"
      gap="5px"
      justifyContent="center"
      flexWrap="wrap"
      padding="10px 30px 5px 30px"
      position="relative"
    >
      <GuideButtonsContainer>
        <GuideButton>Description</GuideButton>
        <GuideButton>Fairness</GuideButton>
      </GuideButtonsContainer>
      <TopRow />
      <BottomRow />
    </Stack>
  )
}

export default LotteryPatternsArea;
