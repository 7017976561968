import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react';

type TicketMultiplierProps = BoxProps & {
  value?: number,
}

const TicketMultiplier = ({value, ...rest}: TicketMultiplierProps) => {
  if (!value) return <></>

  return (
    <Box
      padding="1px 10px"
      background="linear-gradient(180deg, #ff4218 0%, #ff4218 100%)"
      borderBottomLeftRadius="15px"
      borderTopRightRadius="15px"
      color="white"
      fontSize="14px"
      textAlign="center"
      display="inline-block"
      lineHeight="1"
      position="absolute"
      zIndex="1"
      top="-6px"
      right="-1px"
      {...rest}
    >
      *{value}
    </Box>
  )
}

export default TicketMultiplier;
