import { Flex, Stack, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import React, { useEffect, useMemo } from 'react'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import BettingTable from '../UI/Table.tsx'
import useGameId from '@/hooks/useGameId.ts'
import { rocketTshowActions, getListPasts, selectListPasts } from '@/redux/store/modules/rocketTshow.ts'
import { CrashRound } from '@/@generated/gql/graphql-hash.ts'
import { CopyBtn } from '../common/CopyBtn.tsx'
import useSubscription from '@/hooks/useSubscription.tsx'
import { Topics } from '@/redux/store/common/topic.ts'
import { colorByPoint } from './GamePlay/Stats/PointChip.tsx'
import { GameID } from '@/const/game-type.ts'

export default function ListPasts() {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  const dispatch = useAppDispatch()
  const listPasts = useAppSelector(selectListPasts)
  const _message = useSubscription(Topics.lastRound(gameId))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      dispatch(rocketTshowActions.updateListPasts(data))
    } catch (error) {
      console.warn('LastRoundSubscription error: ', error)
    }
  }, [message])

  useEffect(() => {
    dispatch(
      getListPasts({
        game_id: gameId,
        page: 0,
        limit: 10,
      }),
    ).then((res) => {
      // console.log('res', res)
    })
  }, [])

  const columns = useMemo(
    () => [
      {
        title: gameId === GameID.H_ROCKET ? t('explosionAltitude') : t('distance'),
        dataIndex: 'multiplier',
        key: 'multiplier',
        render: (row: CrashRound) => <Text color={colorByPoint(row?.multiplier)}>{row ? `${row?.multiplier}x` : ''}</Text>,
      },
      {
        title: t('prize.player'),
        dataIndex: 'ultimate_player',
        key: 'ultimate_player',
        render: (row: CrashRound) => (
          <>{row?.ultimate_player ? `...${row?.ultimate_player?.substring(row.ultimate_player.length - 6)}` : '--'}</>
        ),
      },
      {
        title: gameId === GameID.H_ROCKET ? t('extremeAltitude') : t('cashoutRatio'),
        dataIndex: 'extreme_multiplier',
        key: 'extreme_multiplier',
        align: 'center',
        render: (row: CrashRound) => <>{+row?.extreme_multiplier > 0 ? `${row?.extreme_multiplier ?? 0}x` : '--'}</>,
      },
      {
        title: t('hash'),
        dataIndex: 'win',
        key: 'win',
        align: 'center',
        render: (row: CrashRound) => (
          <Flex gap={1} alignItems="center" justifyContent={'end'}>
            <Text>...{row?.random_value?.substring(row?.random_value?.length - 8)}</Text>
            <CopyBtn text={row?.random_value} />
          </Flex>
        ),
      },
    ],
    [t],
  )

  return (
    <Stack w="100%" overflow="hidden" gap={0}>
      <BettingTable columns={columns} datasource={listPasts.slice(0, 10)} />
    </Stack>
  )
}
