import React from 'react';
import { Box } from '@chakra-ui/react';

type StageUIContainerProps = {
  children?: React.ReactNode
}

const StageUIContainer = ({children}: StageUIContainerProps) => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      bottom="0"
      right="0"
    >
      {children}
    </Box>
  )
}

export default StageUIContainer;
