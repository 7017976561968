import React from 'react'
import { Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import ListPasts from './ListPasts'
import ListCurrentLive from './ListCurrentLive'
import useCustomTranslation from '@/hooks/useCustomTranslation'

export default function TabSection() {
  const { t } = useCustomTranslation()
  return (
    <>
      <Tabs isFitted variant="unstyled">
        <TabList backgroundColor="#25242B">
          <Tab
            color="#4b4b4d"
            _selected={{
              color: '#fff',
            }}
          >
            {t('currentLive')}
          </Tab>
          <Tab
            color="#4b4b4d"
            _selected={{
              color: '#fff',
            }}
          >
            {t('past')}
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          backgroundImage="linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113))"
          borderRadius="1px"
        />
        <TabPanels mt={1}>
          <TabPanel padding={0}>
            <ListCurrentLive />
          </TabPanel>
          <TabPanel padding={0}>
            <ListPasts />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
