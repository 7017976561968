import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react';

type ButtonWinningTicketProps = ButtonProps

const ButtonWinningTicket = ({children, ...rest}: ButtonWinningTicketProps) => {
  return (
    <Button
      borderRadius="50px"
      background="gray"
      color="white"
      fontSize="18px"
      lineHeight="1"
      textAlign="center"
      height="auto"
      minHeight="auto"
      width="100px"
      padding="5px"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default ButtonWinningTicket;
