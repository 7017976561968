import { Box, Button, Flex, InputProps, Stack } from '@chakra-ui/react'
import { RootState, useAppSelector } from '@store'
import React, { HTMLProps, useEffect, useRef } from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation'

type Props = {
  disabled?: boolean
  isLoading?: boolean
  inputProps?: HTMLProps<HTMLInputElement> & InputProps
  text?: string
}

const ButtonBet = (props: Props) => {
  const { text, inputProps, disabled, isLoading } = props
  const { t } = useCustomTranslation()
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)

  useEffect(() => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [isMuted])

  return (
    <Box textAlign="center" flex={1}>
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      <Flex justifyContent="end" marginBottom="15px" marginTop="27px">
        <Stack
          border={disabled ? '1px solid #313e06' : '1px solid #25242B'}
          // width="310px"
          // w="68%"
          flex={1}
          borderRadius={12}
          position="relative"
        >
          <Button
            aria-label="Bet"
            aria-labelledby="Bet"
            top="-7px"
            borderRadius={12}
            // width="310px"
            height="38px"
            left="-1px"
            overflow="hidden"
            color="black"
            fontSize="1.2rem"
            fontWeight={700}
            type="submit"
            position="relative"
            boxShadow="0px 3px 0px  #799c00 !important"
            // transform="scale(1.03)"
            // _hover={{ bg: '#c3fb04' }}
            _active={{
              transform: disabled ? '' : 'translateY(5px)',
              // boxShadow: '0px 0px 0px  #80a500',
            }}
            _disabled={{
              opacity: 0.4,
              cursor: 'not-allowed',
            }}
            _after={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              right: 0,
              transform: 'translateX(50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            _before={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              left: 0,
              transform: 'translateX(-50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            // onClick={onBet}
            isLoading={isLoading}
            // spinner={}
            sx={{
              background: 'url(/images/btnBet.webp) no-repeat top center !important',
              backgroundSize: 'cover',
            }}
            {...inputProps}
          >
            {/* {disabled ? 'Pending' : `${t('bet')}`} */}
            {text ? text : t('bet')}
          </Button>
        </Stack>
      </Flex>
    </Box>
  )
}

export default ButtonBet
