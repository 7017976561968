import { PlinkoMultiplier, PlinkoOrder, PlinkoOrderHistory, PrePublicPlinkoRound } from '@/@generated/gql/graphql-hash'
import { gql } from '@apollo/client'

export interface GetPlinkoMultiplierQueryResp {
  plinkoMultiplier: PlinkoMultiplier[]
}
export const getPlinkoMultiplierQuery = gql`
  query GetPlinkoMultiplier {
    plinkoMultiplier {
      name
      rows
      risk
      multipliers
    }
  }
`

export interface GetPlinkoOrderHistoryQueryResp {
  plinkoOrderHistory: PlinkoOrderHistory
}

export const getPlinkoOrderHistoryQuery = gql`
  query GetPlinkoOrderHistory($input: FilterInput) {
    plinkoOrderHistory(input: $input) {
      total
      data {
        id
        game_id
        server_seed
        client_seed
        random_value
        proof
        player_id
        agency_id
        wallet_id
        amount
        currency
        risk_level
        player_name
        rows
        balls
        reward
        created_at
        ball_paths
        multipliers
      }
    }
  }
`

export interface CreatePlinkoOrderQueryResp {
  createPlinkoOrder: PlinkoOrder
}

export const createPlinkoOrderQuery = gql`
  mutation CreatePlinkoOrder($input: PlinkoBetInput!) {
    createPlinkoOrder(input: $input) {
      id
      game_id
      server_seed
      client_seed
      random_value
      proof
      player_id
      agency_id
      wallet_id
      amount
      currency
      risk_level
      player_name
      rows
      balls
      reward
      created_at
      ball_paths
      multipliers
    }
  }
`

export interface CreatePlinkoRoundQueryResp {
  createPlinkoRound: PrePublicPlinkoRound
}

export const createPlinkoRoundQuery = gql`
  mutation CreatePlinkoRound {
    createPlinkoRound {
      id
      game_id
      proof
      player_id
    }
  }
`

export const createBingoOrderMutate = gql`
  mutation CreateBingoOrder($input: BingoOrderInput!) {
    createBingoOrder(input: $input) {
      id
      amount
      extra_numbers
      round_id
      currency
      reward
      bingo_bet_tickets {
        bingo_bet_id
        multiplier
        numbers
        star
      }
    }
  }
`
