import React from 'react';
import { Box, Flex, Stack, StackProps } from '@chakra-ui/react'

type NumbersRatingProps = StackProps & {
  number: number,
  rate: number,
  variant: 'hot' | 'cold' | 'disabled',
  isActive?: boolean,
}

const backgroundByVariant: Record<string, string> = {
  hot: '#ff6d1c',
  cold: '#009fe8',
  disabled: '#e6e6e6'
}

const NumberRate = ({number, rate, variant, isActive, ...rest}: NumbersRatingProps) => {
  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="flex-end"
      gap="5px"
      {...rest}
    >
      <Flex
        flex="1"
        width="10px"
        alignItems="flex-end"
      >
        <Box
          width="100%"
          backgroundColor={backgroundByVariant[variant]}
          height={`${rate}%`}
          borderRadius="15px"
          transition=".3s ease-out"
        />
      </Flex>
      <Flex
        width="22px"
        height="22px"
        borderRadius="50%"
        border="1px solid #448bf7"
        alignItems="center"
        justifyContent="center"
        color="#448bf7"
        fontSize="14px"
        lineHeight="1"
        userSelect="none"
      >
        {number}
      </Flex>
    </Stack>
  )
}

export default NumberRate;
