import React from 'react';
import { Box, BoxProps, Image, SystemStyleObject } from '@chakra-ui/react'

type StarBlinkingProps = BoxProps & {
  rank: number,
  index: number,
}

const stylesByRank: Record<number, SystemStyleObject> = {
  1: {
    '&.star-1': {
      top: '27px',
      left: '26px',
    },
    '&.star-2': {
      top: '59px',
      left: '86px',
    },
    '&.star-3': {
      top: '65px',
      left: '25px',
    },
  },
  2: {
    '&.star-1': {
      top: '33px',
      left: '30px',
    },
    '&.star-2': {
      top: '64px',
      left: '80px',
    },
  },
  3: {
    '&.star-1': {
      top: '36px',
      left: '30px',
    },
  }
}

const StarBlinking = ({rank, index, ...rest}: StarBlinkingProps) => {
  return (
    <Box
      opacity="0"
      width="20px"
      height="20px"
      position="absolute"
      className={`star-scale-animation rank-${rank} star-${index}`}
      sx={stylesByRank[rank]}
      onAnimationEnd={async (event) => {
        const animationTargets = document.querySelectorAll('.star-scale-animation')
        if (!animationTargets || animationTargets?.length === 0) return

        const animationName = event.animationName
        if (animationName === 'star-scale-out') {
          animationTargets.forEach((target) => {
            target.classList.remove('star-scale-animation')
            setTimeout(() => {
              target.classList.add('star-scale-animation')
            }, 50)
          })
        }
      }}
      {...rest}
    >
      <Image
        src={`/images/rockets/end-game/star${rank}.png`}
        className="star-spin-animation"
        width="100%"
        height="100%"
      />
    </Box>
  )
}

export default StarBlinking;