import multiplierBest from '@sounds/multiplier-best.wav'
import multiplierGood from '@sounds/multiplier-good.wav'
import multiplierLow from '@sounds/multiplier-low.wav'
import multiplierRegular from '@sounds/multiplier-regular.wav'

export const multiplierSounds = [multiplierBest, multiplierGood, multiplierLow, multiplierRegular]

export function getWinRateSound(value: number, rates: number[]): string {
  if (value < 1) return multiplierLow
  if (value < 2) return multiplierRegular
  if (value == rates[rates.length - 1]) return multiplierBest
  return multiplierGood
}

interface MultiplierColor {
  fromColor: string
  toColor: string
  textColor: string
}

export const multiplierColors: MultiplierColor[] = [
  {
    fromColor: '#f1f100',
    toColor: '#f2a500',
    textColor: '#000',
  },
  {
    fromColor: '#ffe8b7',
    toColor: '#ffe8b7',
    textColor: '#000',
  },
  {
    fromColor: '#ffca9d',
    toColor: '#ffca9d',
    textColor: '#000',
  },
  {
    fromColor: '#e5ac7d',
    toColor: '#e5ac7d',
    textColor: '#000',
  },
  {
    fromColor: '#c88f5b',
    toColor: '#c88f5b',
    textColor: '#000',
  },
  {
    fromColor: '#b27740',
    toColor: '#b27740',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
  {
    fromColor: '#9c6024',
    toColor: '#9c6024',
    textColor: '#000',
  },
]

export const multiplierProbabilities: Record<number, number[]> = {
  8: [0.390625, 3.125, 10.9375, 21.875, 27.34375, 21.875, 10.9375, 3.125, 0.390625],
  9: [0.195313, 1.757813, 7.03125, 16.40625, 24.609375],
  10: [0.097656, 0.976563, 4.394531, 11.71875, 20.507813, 24, 609375],
  11: [0.048828, 0.537109, 2.685547, 8.056641, 16.113281, 22.558594],
  12: [0.024414, 0.292969, 1.611328, 5.371094, 12.084961, 19.335938, 22.558594],
  13: [0.012207, 0.158691, 0.952148, 3.491211, 8.728027, 15.710449, 20.947266],
  14: [0.006104, 0.085449, 0.55542, 2.22168, 6.109619, 12.219238, 18.328857, 20.947266],
  15: [0.003052, 0.045776, 0.320435, 1.38855, 4.165649, 9.164429, 15.274048, 19.638062],
  16: [0.001526, 0.024414, 0.183105, 0.854492, 2.7771, 6.665039, 12.219238, 17.456055, 19.638062],
}
