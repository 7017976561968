import React from 'react';
import { Flex } from '@chakra-ui/react'
import PatternCell from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternCell.tsx'

const PatternBoard = () => {
  return (
    <Flex
      flexWrap="wrap"
      gap="1.2px"
      borderRadius="1px"
      overflow="hidden"
    >
      <PatternCell variant="dark" />
      <PatternCell variant="light" />
      <PatternCell variant="dark" />
      <PatternCell variant="light" />
      <PatternCell variant="dark" />

      <PatternCell variant="light" />
      <PatternCell variant="dark" />
      <PatternCell variant="light" />
      <PatternCell variant="dark" />
      <PatternCell variant="light" />

      <PatternCell variant="dark" />
      <PatternCell variant="light" />
      <PatternCell variant="dark" />
      <PatternCell variant="light" />
      <PatternCell variant="dark" />
    </Flex>
  )
}

export default PatternBoard;
