import { useAppSelector } from '@/redux/store'
import { selectPlinkoRows } from '@/redux/store/modules/plinko.slice'
import { Bodies, Body, Composite, Engine } from 'matter-js'
import { useEffect, useRef } from 'react'
import { plinkoGameConfigs } from '../../../config'
import { usePinGap } from './usePinGap'

interface UseFloorWallsProps {
  worldHeight: number
  worldWidth: number
  engine: Engine
}

export function useFloorWalls({ engine, worldHeight, worldWidth }: UseFloorWallsProps) {
  const pinsConfig = plinkoGameConfigs.pins
  const rows = useAppSelector(selectPlinkoRows)
  const ref = useRef<Body[]>([])
  const { pinXGap, pinYGap, pinSize } = usePinGap({ worldHeight, worldWidth })

  useEffect(() => {
    const floorWalls: Body[] = []
    for (let i = 0; i < rows; i++) {
      const x = (i + 1) * pinXGap
      const y = (pinsConfig.startPins + rows - 1) * pinYGap + pinsConfig.headerHeight

      const wall = Bodies.rectangle(x, worldHeight, pinSize, (worldHeight - y) * 2, {
        angle: 0,
        render: { visible: false, fillStyle: 'red' },
        isStatic: true,
      })
      floorWalls.push(wall)
    }
    Composite.remove(engine.world, ref.current)
    Composite.add(engine.world, floorWalls)
    ref.current = floorWalls
  }, [engine, rows])
  return { floorWalls: ref.current, ref }
}
