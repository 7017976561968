import useGameId from '@/hooks/useGameId'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice'
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { RootState, useAppDispatch, useAppSelector } from '@store'
import { createOrder, orderActions, selectOrderAmount } from '@store/modules/order.slice'
import BigNumber from 'bignumber.js'
import React, { useEffect, useRef } from 'react'
import { ImCheckmark } from 'react-icons/im'
import { GameUrlMap } from '../../const/game-type'
import useCustomToast from '../../hooks/useCustomToast'
import useCustomTranslation from '../../hooks/useCustomTranslation'
import { prettyPrintWallet } from '../../utils/number'

const ButtonBetting = () => {
  const dispatch = useAppDispatch()
  const { t } = useCustomTranslation()
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const { showToastThrottle } = useCustomToast()
  const getAmountGame = useAppSelector(selectOrderAmount)
  const gameID = useGameId()

  const isLoading = useAppSelector(selectIsLoadingAction(createOrder.pending))

  function onBet() {
    if (audioRef.current) {
      audioRef.current.play()
    }
    dispatch(orderActions.createOrder()).then((res) => {
      if (res.meta.requestStatus == 'fulfilled') {
        toastGame()
      }
    })
  }

  function toastGame() {
    if (gameID === GameUrlMap.H_NIUNIU) {
      showToastThrottle({
        title: t('notification.successfully'),
        status: 'success',
        isClosable: false,
        duration: 2000,
        icon: <ImCheckmark fontSize={32} />,
        containerStyle: {
          width: '60px',
          paddingBlock: '0px',
          pointerEvents: 'none',
        },
        render: () => (
          <Stack
            alignItems="center"
            borderRadius={12}
            fontWeight={700}
            textAlign={'center'}
            p={5}
            backdropFilter={'blur(2px)'}
            background="rgba(255, 255, 255, 0.8)"
          >
            <Stack background={'#c3fb04'} w={'25px'} h={'25px'} borderRadius={'50%'} position={'relative'} p={'18px'}>
              <Stack position={'absolute'} top={'50%'} left={'50%'} transform={'translate(-50%, -50%)'}>
                <ImCheckmark fontSize={26} color="black" />
              </Stack>
            </Stack>
            <Text>{t('notification.successfully')}</Text>
            <Stack w={'100%'} fontSize={14} gap={1}>
              <Flex justifyContent={'space-between'} w={'100%'}>
                <Text color="gray">{t('betAmount')}</Text>
                <Text color="red.500">{prettyPrintWallet(getAmountGame)}</Text>
              </Flex>
              <Flex justifyContent={'space-between'} w={'100%'}>
                <Text color="gray">{t('freeze')}</Text>
                <Text color="red.500">{prettyPrintWallet(getAmountGame * 9)}</Text>
              </Flex>
            </Stack>
          </Stack>
        ),
      })
    } else {
      showToastThrottle({
        title: t('notification.successfully'),
        status: 'success',
        isClosable: false,
        duration: 2000,
        icon: <ImCheckmark fontSize={32} />,
        containerStyle: {
          width: '60px',
          paddingBlock: '0px',
          pointerEvents: 'none',
        },
      })
    }
  }

  useEffect(() => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [isMuted])

  return (
    <Box textAlign="center">
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      <Flex justifyContent="center" marginBottom="15px" marginTop="27px">
        <Stack border="1px solid #c3fb04" width="231px" borderRadius={12} position="relative">
          <Button
            data-id="btn-bet"
            aria-label="Bet"
            aria-labelledby="Bet"
            top="-7px"
            borderRadius={12}
            width="231px"
            height="38px"
            left="-1px"
            overflow="hidden"
            // background="#c3fb04"
            color="black"
            fontSize="1.2rem"
            fontWeight={700}
            type="submit"
            // backgroundImage="linear-gradient(-90deg, #e5ff8b, #c7fb15);"
            position="relative"
            boxShadow="0px 3px 0px  #799c00 !important"
            // transform="scale(1.03)"
            // _hover={{ bg: '#c3fb04' }}
            _active={{
              transform: 'translateY(5px)',
              // boxShadow: '0px 0px 0px  #80a500',
            }}
            _disabled={{
              opacity: 1,
            }}
            _after={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              right: 0,
              transform: 'translateX(50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            _before={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              left: 0,
              transform: 'translateX(-50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            onClick={onBet}
            isLoading={isLoading}
            sx={{
              background: 'url(/images/btnBet.webp) no-repeat top center !important',
              backgroundSize: 'cover',
            }}
          >
            {t('bet')}
          </Button>
        </Stack>
      </Flex>
    </Box>
  )
}

const isDivisible = (amountGame: string, amountStep: string): boolean => {
  const gameBN = new BigNumber(amountGame)
  const stepBN = new BigNumber(amountStep)

  return gameBN.mod(stepBN).isZero()
}

export default ButtonBetting
