import boomSprite from '@images/boom.svg'
import React, { useState } from 'react'
import { SpriteAnimator } from '../../Sprite'

export function Boom() {
  const [visible, setVisible] = useState(true)
  if (!visible) return null
  const imageSize = 170

  return (
    <div className={`w-8 h-8 relative scale-[0.15]`}>
      <SpriteAnimator
        width={imageSize}
        height={imageSize}
        sprite={boomSprite}
        shouldAnimate={true}
        fps={20}
        direction="vertical"
        startFrame={0}
        stopLastFrame={true}
        reset={false}
        frameCount={8}
        wrapAfter={1}
        onError={() => setVisible(false)}
        onEnd={() => setVisible(false)}
      />
    </div>
  )
}
