import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react';
import Ticket from '@/bingo/components/Common/Ticket'
import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { useAppSelector } from '@store'
import { selectBingoCurrentStep } from '@/bingo/redux/bingo.slice.ts'

type TicketListProps = FlexProps & {
  data?: LocalBingoTicket[],
  onEdit?: () => void,
}

const TicketList = ({data, onEdit}: TicketListProps) => {
  const currentStep = useAppSelector(selectBingoCurrentStep)

  return (
    <Flex
      flexWrap="wrap"
      gap="20px"
      flex="1"
      overflowY="auto"
      height="100%"
      padding="10px 10px 20px"
      alignItems="flex-start"
    >
      {data?.map((ticket, index) => (
        <Ticket data={ticket} onEdit={onEdit} isBetSuccess={currentStep === 'betSuccess'} key={index} />
      ))}
    </Flex>
  )
}

export default TicketList;