import React from 'react'
import { GameStateEnum } from './hooks/useRocketGamePlay'
import useCustomTranslation from '@/hooks/useCustomTranslation'

export const MultiplierDisplay = ({ multiplier, gameState }: { multiplier: string; gameState: GameStateEnum }) => {
  const { t } = useCustomTranslation()

  const multiplierDisplay = multiplier ? parseFloat(multiplier).toFixed(2) : ''

  return (
    <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
      <div
        className={`relative w-[290px] h-[290px] flex flex-col items-center justify-center ${
          gameState === 'REWARD' ? 'multiplier-box' : ''
        }`}
      >
        <div className="font-['Bahnschrift'] text-[60px] font-bold tracking-wider multiplier-text select-none">
          {multiplierDisplay}x
        </div>
        <div className="font-['Bahnschrift'] text-[22px] font-extrabold tracking-wide mt-[-20px] multiplier-label select-none">
          {gameState === 'PLAYING' ? t('rocket.flightAltitude') : t('rocket.explosionAltitude')}
        </div>
      </div>
    </div>
  )
}
