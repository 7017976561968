import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react'

type RocketFormContainerProps = BoxProps

const RocketFormContainer = ({children}: RocketFormContainerProps) => {
  return (
    <Box
      padding="10px 10px 0"
    >
      {children}
    </Box>
  )
}

export default RocketFormContainer;
