import { HiloRound } from '@/@generated/gql/graphql-hash'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import { Box, Flex, Image, Stack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import React, { useEffect, useState } from 'react'
import BetInfo from './BetInfo'
import { selectIsLogged } from '@/redux/store/modules/auth.slice'
import { getListOrdersHilo, selectMyOrdersHilo, selectTotalOrdersHilo } from '@/redux/store/modules/hilo'

export default function ListBetting({ noPaging = false }: { noPaging: boolean }) {
  const { t } = useCustomTranslation()
  const dispatch = useAppDispatch()
  const limit = 10
  const [page, setPage] = useState(1)
  const game_id = useGameId()
  const myOrders = useAppSelector(selectMyOrdersHilo)
  const totals = useAppSelector(selectTotalOrdersHilo)
  const isLogged = useAppSelector(selectIsLogged)
  
  useEffect(() => {
    if (isLogged) {
      dispatch(getListOrdersHilo({ game_id, page, limit }))
    }
  }, [page])
  const changePage = (page: number) => {
    setPage(page)
  }

  return (
    <>
      <Stack
      // sx={{
      //   opacity: isLoading ? '0.3' : '1',
      // }}
      >
        {myOrders?.length > 0 ? (
          myOrders.map((order: HiloRound, index: number) => <BetInfo order={order} key={index} />)
        ) : (
          <Flex
            w="100%"
            height="200px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="#3d3d43"
            gap={'11px'}
          >
            <Box>
              <Image w={'40px'} src="/images/norecord.webp" />
            </Box>
            <Box color={'#c1c1de'} fontSize={'13px'}>
              {t('notification.youHavenorecordsYet')}
            </Box>
          </Flex>
        )}
        {noPaging === false && (
          <Stack alignItems="center">
            {myOrders?.length > 0 && (
              <Flex direction="row" align="center" justify="center" mt={4}>
                <Pagination className="paging" onChange={changePage} current={page} total={totals} pageSize={limit} />
              </Flex>
            )}
          </Stack>
        )}
      </Stack>
    </>
  )
}
