import { bingoActions } from '@/bingo/redux/bingo.slice'
import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { ConfigRound } from '@/redux/store/entities/game.entity'
import React, { useEffect } from 'react'

export default function ConfigRoundSubscription() {
  const dispatch = useAppDispatch()

  const game_id = useGameId()

  const _message = useSubscription(Topics.configRound(game_id))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '') as ConfigRound
      dispatch(bingoActions.updateRoundConfig(data))
    } catch (error) {
      console.warn('ConfigRoundSubscription error: ', error)
    }
  }, [message])

  return <></>
}
