import { RiskLevel } from '@/pages/plinko/config'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PlinkoBetResult {
  result: string // LRLRLLLRRLR
  id: string
  rate: number
  amount: string
  currency: string
  createdAt: string
  lotteryHash: string
}

export interface PlinkoState {
  betAmount: string
  riskLevel: RiskLevel
  rows: number
  numOfBalls: number
}

const initialState: PlinkoState = {
  riskLevel: 'low',
  betAmount: '1',
  rows: 8,
  numOfBalls: 1,
}

export const plinkoPreferenceSlice = createSlice({
  name: 'plinkoPreference',
  initialState,
  reducers: {
    riskLevelUpdated: (state, action: PayloadAction<RiskLevel>) => {
      state.riskLevel = action.payload
    },
    rowsUpdated: (state, action: PayloadAction<number>) => {
      state.rows = action.payload
    },
    ballsUpdated: (state, action: PayloadAction<number>) => {
      state.numOfBalls = action.payload
    },
    betAmountUpdated: (state, action: PayloadAction<string>) => {
      state.betAmount = action.payload
    },
  },
})

export const plinkoPreferenceActions = plinkoPreferenceSlice.actions

export default plinkoPreferenceSlice
