export interface EventData {
  eventName?: string;
  message?: string;
  data?: any;
}

const eventBus = {
  on(event: string, callback?: any) {
    document.addEventListener(event, (data: any | EventData) => callback(data.detail));
  },
  dispatch(event: string, data?: EventData) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback?: any) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
