import useGameId from '@/hooks/useGameId'
import { Box, Flex, Stack } from '@chakra-ui/react'
import React from 'react'
import { GameUrlMap } from '../../const/game-type'
import RuleHBP from './rule-game/RuleHBP'
import RuleHBS from './rule-game/RuleHBS'
import RuleHLUCKY from './rule-game/RuleHLUCKY'
import RuleHNIUNIU from './rule-game/RuleHNIUNIU'
import RuleHOE from './rule-game/RuleHOE'
import RuleHRK from './rule-game/RuleHRK'

export default function RuleGame() {
  const gameID = useGameId()
  return (
    <div>
      <Stack color="white" gap={6}>
        <Flex color="white">
          <Stack>
            <Box maxW="768px" mx="auto">
              {/* Bảng luật chơi */}
              {gameID === GameUrlMap.H_BS && <RuleHBS />}
              {gameID === GameUrlMap.H_OE && <RuleHOE />}
              {gameID === GameUrlMap.H_BP && <RuleHBP />}
              {gameID === GameUrlMap.H_LUCKY && <RuleHLUCKY />}
              {gameID === GameUrlMap.H_NIUNIU && <RuleHNIUNIU />}
              {gameID === GameUrlMap.H_ROCKET && <RuleHRK />}
            </Box>
          </Stack>
        </Flex>
      </Stack>
    </div>
  )
}
