import { bingoActions } from '@/bingo/redux/bingo.slice'
import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { BingoRound } from '@/redux/store/entities/game.entity'
import React, { useEffect } from 'react'

export default function GameRoundSubscription() {
  const dispatch = useAppDispatch()
  const game_id = useGameId()

  const _message = useSubscription(Topics.gameRound(game_id), {
    qos: 1,
  })
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '') as BingoRound
      console.log('round: ', data)

      dispatch(bingoActions.updateGameRound(data))
      //Clear round
      // if (data?.state === 'End') {
      //   dispatch(bingoActions.updateMultiplier({}))
      //   dispatch(bingoActions.clearActiveOrders())
      // }
      // if (data?.state === 'Waiting') {
      //   dispatch(bingoActions.clearMyRoundOrders())
      // }
    } catch (error) {
      console.warn('GameRoundSubscription error: ', error)
    }
  }, [message])

  return <></>
}
