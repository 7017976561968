import React, { useState } from 'react'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import CurrentTicket from '@/bingo/components/FormSection/EditTicket/CurrentTicket'
import ButtonEP from '@/bingo/components/FormSection/EditTicket/ButtonEP.tsx'
import ButtonPer from '@/bingo/components/FormSection/EditTicket/ButtonPer.tsx'
import NumbersRating from '@/bingo/components/FormSection/EditTicket/NumbersRating'
import NumbersSelect from '@/bingo/components/FormSection/EditTicket/NumbersSelect'
import { bingoGroups } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import ButtonChangeGroup from '@/bingo/components/FormSection/EditTicket/ButtonChangeGroup.tsx'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'

const EditTicket = () => {
  const [groupIndex, setGroupIndex] = useState<number>(0)
  const [currentPer, setCurrentPer] = useState<number>(10)

  const handlePrevGroup = () => {
    if (groupIndex === 0) return

    setGroupIndex(prevGroupIndex => prevGroupIndex - 1)
  }
  const handleNextGroup = () => {
    if (groupIndex >= bingoGroups.length - 1) return

    setGroupIndex(prevGroupIndex => prevGroupIndex + 1)
  }

  return (
    <Box
      paddingBottom="25px"
    >
      <Text
        textAlign="center"
        lineHeight="1"
        margin="0 0 10px"
      >
        Draw the lottery in 58s.
      </Text>
      <Box
        background="linear-gradient(180deg, #fff 0%, rgba(255,255,255,0.5) 100%)"
        boxShadow="0px 0.313rem 0.313rem 0px rgba(255,205,205,0.5)"
        borderRadius="15px"
        padding="20px 30px"
      >
        <Flex
          alignItems="center"
          gap="20px"
          marginBottom="30px"
        >
          <CurrentTicket
            currentGroup={bingoGroups[groupIndex]}
          />
          <Stack
            gap="15px"
          >
            <ButtonEP epType="hot" />
            <ButtonEP epType="cold" />
          </Stack>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          gap="10px"
          marginBottom="20px"
        >
          <ButtonPer
            per={10}
            onClick={() => setCurrentPer(10)}
            isSelected={currentPer === 10}
          />
          <ButtonPer
            per={20}
            onClick={() => setCurrentPer(20)}
            isSelected={currentPer === 20}
          />
          <ButtonPer
            per={50}
            onClick={() => setCurrentPer(30)}
            isSelected={currentPer === 30}
          />
        </Flex>

        <NumbersRating
          currentGroup={bingoGroups[groupIndex]}
        />

        <Flex
          alignItems="center"
          justifyContent="center"
          gap="20px"
          marginBottom="30px"
        >
          <ButtonChangeGroup
            variant="prev"
            onClick={handlePrevGroup}
          />
          <NumbersSelect currentGroup={bingoGroups[groupIndex]} />
          <ButtonChangeGroup
            variant="next"
            onClick={handleNextGroup}
          />
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          gap="30px"
        >
          <FormButtonBig
            background="linear-gradient(180deg, rgba(255,145,130,0.38) 0%, rgba(255,145,130,0.6) 100%)"
            color="#d92727"
          >
            Close
          </FormButtonBig>
          <FormButtonBig
            background="linear-gradient(180deg, #efefef 0%, #cecece 100%)"
            color="#888888"
          >
            Save
          </FormButtonBig>
        </Flex>

      </Box>
    </Box>
  )
}

export default EditTicket
