import React from 'react';
import { Box } from '@chakra-ui/react';
import RoundId from '@/bingo/components/GameplayStage/MiningArea/RoundId.tsx';
import SoundController from '@/bingo/components/GameplayStage/MiningArea/SoundController.tsx';
import Miner from '@/bingo/components/GameplayStage/MiningArea/Miner.tsx';
import BallsCounter from '@/bingo/components/GameplayStage/MiningArea/BallsCounter.tsx';
import ExploitedBalls from '@/bingo/components/GameplayStage/MiningArea/ExploitedBalls.tsx';

const MiningArea = () => {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="60%"
    >
      <RoundId value="round id" />
      <SoundController />
      <Miner />
      <ExploitedBalls />
      <BallsCounter
        currentNormalBalls={0}
        totalNormalBalls={30}
        currentExtraBalls={0}
        totalExtraBalls={10}
      />
    </Box>
  )
}

export default MiningArea;
