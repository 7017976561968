import React from 'react';
import { Button, ButtonProps, Image } from '@chakra-ui/react'

type ButtonEPProps = ButtonProps & {
  epType: 'hot' | 'cold',
}

const variants: Record<string, any> = {
  hot: {
    styles: {
      background: 'linear-gradient(180deg, #ffdfdf 0%, #fff1f1 100%)',
      color: '#ff6d1c',
    },
    icon: '/bingo/images/hot.png',
    text: 'Hot EP.',
  },
  cold: {
    styles: {
      background: 'linear-gradient(180deg, #d8eeff 0%, #e8f5ff 100%)',
      color: '#40b7ee',
    },
    icon: '/bingo/images/cold.png',
    text: 'Cold EP.',
  },
}

const ButtonEP = ({epType, ...rest}: ButtonEPProps) => {
  return (
    <Button
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      gap="10px"
      padding="15px 20px"
      borderRadius="8px"
      width="115px"
      height="auto"
      _hover={{}}
      _active={{}}
      {...variants[epType].styles}
      {...rest}
    >
      <Image src={variants[epType].icon} width="35px" />
      {variants[epType].text}
    </Button>
  )
}

export default ButtonEP;
