import { Flex, FlexProps, Input, InputProps } from '@chakra-ui/react'
import React from 'react'
import { HTMLProps, ReactNode } from 'react'
import BtnQuickChange from './BtnQuickChange'
import { f } from 'fintech-number'

type QuickFormInputProps = {
  plusCallback?: (params?: any) => any
  minusCallback?: (params?: any) => any
  containerProps?: HTMLProps<HTMLDivElement> & FlexProps
  inputProps?: HTMLProps<HTMLInputElement> & InputProps
  leftIcon?: ReactNode
}

const QuickFormInput = (props: QuickFormInputProps) => {
  const { plusCallback, minusCallback, containerProps, inputProps, leftIcon } = props

  const handleOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const char = e.key
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
    if ((e.ctrlKey || e.metaKey) && char === 'r') {
      console.log('Ctrl+R or Cmd+R pressed!')
      return true
    }

    if (controlKeys.includes(char)) {
      return true
    }
    const input = e.currentTarget.value
    const [integerPart, decimalPart] = input.split('.')

    if (decimalPart && decimalPart.length >= 2) {
      e.preventDefault()
    }

    if (char >= '0' && char <= '9') {
      return true
    }

    if (char === '.') {
      return e.currentTarget.value.indexOf('.') === -1
    }
    
    e.preventDefault()
    return false
  }

  const handleChange = (e: any) => {
    const inputMaxAmount = 1000000000
    let value = e.target.value
    if (value === '') {
      return
    }
    value = e.target.value.replace(/[^0-9.]/g, '') // Remove anything that isn't a number or period
    let formatedValue = formatInputValue(value)
    let valueNumbered = Number(formatedValue.replace(/,/g, ''))

    if (valueNumbered > inputMaxAmount) {
      formatedValue = formatInputValue(inputMaxAmount)
      valueNumbered = inputMaxAmount
    }
    e.target.value = formatedValue
  }

  const formatInputValue = (value: string | number) => {
    // Format number with commas
    if (value.toString().includes('.')) {
      const [intPart, decimalPart] = value.toString().split('.')
      return f(Number(intPart)) + '.' + decimalPart
    } else {
      return f(Number(value)) // Format with commas
    }
  }

  return (
    <Flex
      backgroundColor="#131314"
      alignItems="center"
      justifyContent="space-between"
      padding="4px 8px"
      border="1px solid #29292f"
      borderRadius={8}
      height="auto"
      position="relative"
      gap="5px"
      {...containerProps}
    >
      {leftIcon && leftIcon}
      <Input
        height="28px"
        _placeholder={{ fontWeight: 500, color: '#2e2e2e', fontSize: 12 }}
        fontSize="16px"
        fontWeight={700}
        lineHeight={1}
        border="none"
        outline="none !important"
        boxShadow="none !important"
        backgroundColor="transparent !important"
        position="relative"
        padding="0"
        onChange={(e) => handleChange(e)}
        onKeyDown={handleOnInput}
        {...inputProps}
      />
      <Flex alignItems="center" gap="5px">
        <BtnQuickChange changeType="minus" onBtnClick={minusCallback} />
        <BtnQuickChange changeType="plus" onBtnClick={plusCallback} />
      </Flex>
    </Flex>
  )
}

export default QuickFormInput
