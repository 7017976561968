import React from 'react';
import { Image } from '@chakra-ui/react';

const StageBg = () => {
  return (
    <Image src="/bingo/images/bg-stage.png" width="100%" pointerEvents="none" userSelect="none" />
  )
}

export default StageBg;
