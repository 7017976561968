import React from 'react';
import { Box, Stack, StackProps } from '@chakra-ui/react'

type FormContainerProps = {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  backgroundProps?: StackProps;
}

const FormContainer = ({title, backgroundProps, children}: FormContainerProps) => {
  return (
    <Box
      padding="60px 5px 0"
      height="100%"
    >
      <Stack
        backgroundColor="#fde8e8"
        border="1px solid #fce2e3"
        borderTopRadius="20px"
        height="100%"
        gap="0"
        {...backgroundProps}
      >
        {title && (
          <Box
            background="linear-gradient(180deg, #f9b8bc 0%, #fdcdb7 100%)"
            borderTopRadius="20px"
            textAlign="center"
            color="#e82013"
            fontSize="26px"
            fontWeight="500"
            lineHeight="1"
            padding="10px 20px"
          >
            {title}
          </Box>
        )}
        {children}
      </Stack>
    </Box>
  )
}

export default FormContainer;
