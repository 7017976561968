import { Box, Button, Stack, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import Ticket from '@/bingo/components/Common/Ticket'
import { generateBingoCard } from '@/bingo/const/bingoHelpers.ts'
import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { defaultMultiplier } from '@/bingo/const/bingoConfigs.ts'
import ButtonWinningTicket from '@/bingo/components/FormSection/Mining/ButtonWinningTicket.tsx'
import TotalBonus from '@/bingo/components/FormSection/Mining/TotalBonus.tsx'
import BingoDrawer from '@/bingo/components/Common/BingoDrawer.tsx'
import ExtraBallsDrawer from '@/bingo/components/FormSection/Mining/ExtraBallsDrawer.tsx'

const mockTicket: LocalBingoTicket = {
  id: 'local-ticket-1',
  numbers: generateBingoCard(),
  multiplier: defaultMultiplier,
}

const WinningTicket = () => {
  const extraBallsDrawer = useDisclosure()

  useEffect(() => {
    extraBallsDrawer.onOpen()
  }, [])

  return (
    <>
      <Box
        position="absolute"
        top="-100px"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="rgba(0, 0, 0, 0.3)"
        backdropFilter="blur(2px)"
        zIndex="1"
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          width="50%"
        >
          <Stack
            alignItems="flex-end"
            marginBottom="20px"
          >
            <ButtonWinningTicket>Skip</ButtonWinningTicket>
            <ButtonWinningTicket>Next</ButtonWinningTicket>
          </Stack>
          <Ticket
            isBetSuccess
            data={mockTicket}
            width="100%"
          />
        </Box>
        <TotalBonus value={60} />
      </Box>
      <BingoDrawer
        isOpen={extraBallsDrawer.isOpen}
        onClose={extraBallsDrawer.onClose}
        showOverlay={false}
      >
        <ExtraBallsDrawer />
      </BingoDrawer>
    </>
  )
}

export default WinningTicket;
