import { Bodies, Body, Composite, Engine } from 'matter-js'
import { useEffect, useRef } from 'react'
import { plinkoGameConfigs } from '../../../config'

interface UseWallsProps {
  worldHeight: number
  worldWidth: number
  engine: Engine
}

export function useWalls({ engine, worldHeight, worldWidth }: UseWallsProps) {
  const pinsConfig = plinkoGameConfigs.pins
  const wallWidth = 10
  const ref = useRef<Body[]>([])

  useEffect(() => {
    const leftWall = Bodies.rectangle(
      worldWidth / 2 - pinsConfig.pinPadding - wallWidth,
      0,
      worldWidth * 5,
      wallWidth,
      {
        label: 'block-left',
        angle: 90,
        render: { visible: false },
        isStatic: true,
      },
    )
    const rightWall = Bodies.rectangle(
      worldWidth / 2 + pinsConfig.pinPadding + wallWidth,
      0,
      worldWidth * 5,
      wallWidth,
      {
        label: 'block-right',
        angle: -90,
        render: { visible: false },
        isStatic: true,
      },
    )
    const floor = Bodies.rectangle(0, worldHeight, worldWidth * 10, wallWidth, {
      label: 'block-floor',
      render: { visible: false },
      isStatic: true,
    })
    const walls = [leftWall, rightWall, floor]

    Composite.remove(engine.world, ref.current)
    Composite.add(engine.world, walls)
    ref.current = walls
  }, [engine])
  return { walls: ref.current, ref }
}
