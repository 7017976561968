import { Flex, FlexProps, Text } from '@chakra-ui/react'
import React from 'react';
import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import { getBingoGroupByNumber } from '@/bingo/const/bingoHelpers.ts'
import { BingoGroup } from '@/bingo/types/bingo.ts'

type ResultBallProps = {
  value: number,
}

const colorByVariant: Record<BingoGroup, string> = {
  B: '#448bf7',
  I: '#e25565',
  N: '#a12aff',
  G: '#4a8b37',
  O: '#a87e34',
}

const ResultBall = ({value}: ResultBallProps) => {
  const variant = getBingoGroupByNumber(value)

  if (!value) return <></>

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      border={`1px solid ${colorByVariant[variant]}`}
      width="23px"
      height="23px"
      padding="3px"
      lineHeight="1"
      fontSize="14px"
      color={colorByVariant[variant]}
      borderRadius="50%"
      marginLeft="-5px"
      backgroundColor="white"
      _first={{
        marginLeft: '0',
      }}
    >
      {value}
    </Flex>
  )
}

type ResultRowProps = FlexProps & {
  isPossible?: boolean,
  value?: number | string,
  balls?: number[],
}

const ResultRow = ({value, balls, isPossible, ...rest}: ResultRowProps) => {
  const moreBall = balls?.length ? balls?.length - 3 : 0

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      padding="5px"
      backgroundColor="white"
      borderRadius="50px"
      width="47%"
      minWidth="47%"
      {...rest}
    >
      <Flex
        alignItems="center"
        marginRight="10px"
        gap="5px"
      >
        <BingoCurrencyIcon />
        <Text
          lineHeight="1"
          color="#ff4218"
          fontSize="16px"
        >
          {value}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
      >
        {isPossible && (
          <Text
            color="rgb(175, 175, 175)"
            fontSize="14px"
            lineHeight="1"
            marginRight="3px"
          >
            POSSIBLE
          </Text>
        )}
        {!isPossible && balls?.length && (
          <>
            {balls?.slice(0, 3)?.map((ball) => <ResultBall value={ball} key={ball} />)}
            {moreBall > 0 && (
              <Text
                lineHeight="1"
                fontSize="16px"
                margin="0 2px"
              >
                {`+${moreBall}`}
              </Text>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default ResultRow;
