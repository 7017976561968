import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react'
import { IoStarOutline, IoStarSharp } from "react-icons/io5";

type TicketCollectProps = FlexProps & {
  isCollected?: boolean,
}

const TicketCollect = ({isCollected}: TicketCollectProps) => {
  return (
    <Flex
      position="absolute"
      top="-5px"
      left="-5px"
      width="20px"
      height="20px"
      borderRadius="50%"
      backgroundColor={isCollected ? '#fa533a' : 'white'}
      color={isCollected ? 'white' : 'gray'}
      alignItems="center"
      justifyContent="center"
      fontSize="14px"
      cursor="pointer"
      zIndex="1"
    >
      {isCollected ? <IoStarSharp /> : <IoStarOutline />}
    </Flex>
  )
}

export default TicketCollect;
