import { useEffect, useRef, useState, useCallback } from 'react'
import gsap from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { elapsedToMultiplier } from '../rocket-helper'
import { useAppSelector } from '@/redux/store'
import {
  selectCashoutOrders,
  selectGameRound,
  selectListActiveOrders,
  selectMultiplier,
  selectMyRoundOrders,
} from '@/redux/store/modules/rocketTshow'
import { selectDiffTime } from '@/redux/store/modules/game.slice'

gsap.registerPlugin(MotionPathPlugin)

interface UseRocketAnimationProps {
  rocketRef: React.RefObject<HTMLDivElement>
  containerRef: React.RefObject<HTMLDivElement>
  onMultiplierUpdate?: (multiplier: number) => void
  onCrash?: () => void
}

export type GameStateEnum = 'WAITING' | 'PLAYING' | 'ENDED' | 'REWARD' | 'INITIAL'

export type RoundReward = {
  maxMultiplier?: string
  rewards?: { currency?: string; amount?: number }[]
}

// Map socket game state to internal state
const mapSocketStateToGameState = (socketState: string, preState: GameStateEnum): GameStateEnum => {
  switch (socketState.toUpperCase()) {
    case 'RUNNING':
      return 'PLAYING'
    case 'END':
      if (preState === 'PLAYING') {
        return 'ENDED'
      }
      return 'REWARD'
    case 'WAITING':
    default:
      return 'WAITING'
  }
}

const topCashoutInitState = [
  { id: 1, name: '', multiplier: 0 },
  { id: 2, name: '', multiplier: 0 },
  { id: 3, name: '', multiplier: 0 },
]

export const useRocketGamePlay = ({ rocketRef, containerRef }: UseRocketAnimationProps) => {
  const [gameState, setGameState] = useState<GameStateEnum>('INITIAL')
  const gameStateRef = useRef<GameStateEnum>('WAITING')
  const startTimeRef = useRef<number>(new Date().getTime() + 7000)
  const multiplierRef = useRef(1)
  const timelineRef = useRef<gsap.core.Timeline | null>(null)

  const pathRef = useRef<SVGPathElement>(null)
  const initialRocketStateRef = useRef<{
    rect: DOMRect | null
    style: {
      transform: string
      width: string
      height: string
      left: string
      top: string
      bottom: string
    } | null
  }>({
    rect: null,
    style: null,
  })

  const pathPoints = useRef<{ x: number; y: number }[]>([])
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  const gameRound = useAppSelector(selectGameRound)
  const listActiveOrders = useAppSelector(selectListActiveOrders)

  const roundMultiplier = useAppSelector(selectMultiplier)
  const cashoutOrders = useAppSelector(selectCashoutOrders)
  const cashoutOrderHandled = useRef<{ [key: number | string]: boolean }>({})
  const [topCashoutOrder, setTopCashoutOrder] = useState(topCashoutInitState)

  const myRoundOrders = useAppSelector(selectMyRoundOrders)

  const [rewardInfo, setRewardInfo] = useState<RoundReward | null>()
  const isWinnerRef = useRef(false)
  const [countdown, setCountdown] = useState<number>(-1)
  const countdownRef = useRef(countdown)
  const [showWinAnim, setShowWinAnim] = useState(false)
  const diffTime = useAppSelector(selectDiffTime)
  const diffTimeRef = useRef(diffTime)

  useEffect(() => {
    console.log('diffTime: ', diffTime)
    diffTimeRef.current = diffTime
  }, [diffTime])

  // useEffect(() => {
  //   console.log('gameRound: ', gameRound)
  // }, [gameRound])

  useEffect(() => {
    if (gameState === 'WAITING') {
      isWinnerRef.current = false
      setRewardInfo(null)
      setTopCashoutOrder(topCashoutInitState)
      return
    }

    const _rewardOrders = [...myRoundOrders]
      .filter((order) => Number(order.reward) > 0)
      .sort((a, b) => b.reward - a.reward)

    const rewardOrder = _rewardOrders?.length > 0 ? _rewardOrders[0] : null

    if (rewardOrder) {
      setRewardInfo({
        maxMultiplier: Number(rewardOrder.cash_out) ? rewardOrder.cash_out : rewardOrder.auto_cash_out,
        rewards: _rewardOrders.reduce((acc: { currency?: string; amount?: number }[], order) => {
          const currency = order.currency
          const amount = Number(order.reward)
          const existingOrder = acc.find((o) => o.currency === currency)
          if (existingOrder) {
            existingOrder.amount = (existingOrder.amount || 0) + amount
          } else {
            acc.push({ currency, amount })
          }
          return acc
        }, []),
      })
    }

    if (isWinnerRef.current) {
      return
    }

    if (rewardOrder) {
      isWinnerRef.current = true
    }
  }, [myRoundOrders, gameState, gameRound.round_id])

  const triggerCashout = useCallback(
    (orderId: string, multiplier: number) => {
      if (!rocketRef.current || !containerRef.current) {
        console.log('not exist rocket or container')
        return
      }

      if (cashoutOrderHandled.current[orderId]) {
        return
      }
      cashoutOrderHandled.current[orderId] = true

      const containerRect = containerRef.current.getBoundingClientRect()
      const boxRect = rocketRef.current?.getBoundingClientRect()

      // Calculate position relative to container and add box center offset
      const _x = boxRect?.left - containerRect?.left + boxRect?.width / 2
      const x = _x + Math.floor(Math.random() * 10)

      const y = boxRect?.top - containerRect?.top + boxRect?.height / 2

      // const boxRect = rocketRef.current?.getBoundingClientRect()
      // Create container for box and label
      const cashoutBox = document.createElement('div')
      const uniqueId = `cashout-${orderId}`
      cashoutBox.id = uniqueId

      const cashoutBoxTop = Math.min(Math.max(y, 0), containerRect.height - 20)
      const realDistance = containerRect.height - cashoutBoxTop

      const distance = Math.min(realDistance - 20, 100)

      cashoutBox.style.cssText = `
        position: absolute;
        left: ${Math.min(Math.max(x, 0), containerRect.width - 20)}px;
        top: ${cashoutBoxTop}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
      `

      // Create multiplier label
      const label = document.createElement('p')
      label.textContent = `${multiplier.toFixed(2)}x`
      label.style.cssText = `
          color: white;
          font-size: 12px;
          margin-bottom: 4px;
        `
      cashoutBox.appendChild(label)

      // Create green box
      const playerBox = document.createElement('div')
      playerBox.style.cssText = `
        width: 25px;
        height: 37px;
        background: url('/images/rockets/img_crashout.png') no-repeat center center;
        background-size: contain;
      `
      cashoutBox.appendChild(playerBox)

      containerRef.current?.appendChild(cashoutBox)

      // Animate the cashoutBox
      const cashoutTl = gsap.timeline()
      cashoutTl
        .to(cashoutBox, {
          y: `+=${distance}`,
          duration: 2,
          ease: 'sine.out',
        })
        .to(cashoutBox, {
          opacity: 0,
          duration: 1,
          ease: 'sine.out',
          onComplete: () => {
            cashoutBox.remove()
          },
        })
    },
    [containerRef, rocketRef],
  )

  useEffect(() => {
    if (!cashoutOrders?.length) return

    // only triggerCashout only last 3 orders
    cashoutOrders.slice(-3).forEach((order) => {
      triggerCashout(order.id, Number(order.esc_multiplier))
    })
    const topOrders = [...cashoutOrders].sort((a, b) => Number(b.esc_multiplier) - Number(a.esc_multiplier))
    setTopCashoutOrder([
      { id: 1, name: topOrders[0]?.player_name, multiplier: Number(topOrders[0]?.esc_multiplier || '0') },
      { id: 2, name: topOrders[1]?.player_name, multiplier: Number(topOrders[1]?.esc_multiplier || '0') },
      { id: 3, name: topOrders[2]?.player_name, multiplier: Number(topOrders[2]?.esc_multiplier || '0') },
    ])
  }, [cashoutOrders, triggerCashout])

  // Custom easing function to match with backend
  useEffect(() => {
    gsap.registerEase('crashCurve', (progress: number) => {
      return Math.log(1 + progress * 9) / Math.log(10)
    })
  }, [])

  useEffect(() => {
    const containerRect = containerRef.current?.getBoundingClientRect()
    const _containerWidth = containerRect?.width
    const _containerHeight = containerRect?.height

    setContainerSize({
      width: _containerWidth ?? 0,
      height: _containerHeight ?? 0,
    })
  }, [containerRef, setContainerSize])

  // Tạo đường cong cho rocket
  const createCrashCurve = useCallback(() => {
    const containerRect = containerRef.current?.getBoundingClientRect()
    const width = containerRect?.width || window.innerWidth
    const height = containerRect?.height || window.innerHeight
    const rocketElRect = rocketRef.current?.getBoundingClientRect()
    const rocketHeight = (rocketElRect?.width ?? 30) / 2
    const maxHeight = height - rocketHeight - 169

    return [
      { x: 0, y: 0 },
      { x: width / 2, y: -20 },
      { x: width - 60, y: -60 },
      { x: width - 60, y: -maxHeight },
    ]
    // return `M 0,${height * 0.8} Q ${width * 0.5},${height * 0.8} ${width * 0.8},0`
  }, [containerRef, rocketRef])

  // Tạo animation cho rocket
  const createRocketAnimation = useCallback(() => {
    if (!rocketRef.current) {
      console.log('rocketRef not exist')
      return
    }

    // Kill old animation
    if (timelineRef.current) {
      timelineRef.current.kill()
    }

    gsap.set(rocketRef.current, {
      scale: 1,
      opacity: 1,
      rotation: 0,
      x: 0,
      y: 0,
    })

    gsap.to(rocketRef.current, {
      duration: 0.1,
      opacity: 1,
      ease: 'none',
    })

    const tl = gsap.timeline({
      onUpdate: () => {
        if (!rocketRef.current || !containerRef.current) {
          console.log('not exist rocket or container')
          return
        }

        if (gameStateRef.current !== 'PLAYING') {
          gsap.set(pathRef.current, { attr: { d: 'M 0, 0' } })
          return
        }

        // Get the container's transform matrix to account for any scaling/transforms
        const containerMatrix = window.getComputedStyle(containerRef.current).transform
        const matrix = new DOMMatrix(containerMatrix === 'none' ? undefined : containerMatrix)

        // Get rects for both elements
        const containerRect = containerRef.current.getBoundingClientRect()
        const rocketRect = rocketRef.current?.getBoundingClientRect()

        if (!rocketRect) return

        // Calculate the rocket's position relative to the container using client coordinates
        const rocketX = rocketRect.left + rocketRect.width / 2
        const rocketY = rocketRect.top + rocketRect.height / 2
        const containerX = containerRect.left
        const containerY = containerRect.top

        // Apply the inverse of the container's transform to get the true position
        const point = new DOMPoint(rocketX - containerX, rocketY - containerY)
        const transformedPoint = matrix.inverse().transformPoint(point)

        const x = transformedPoint.x
        const y = transformedPoint.y

        pathPoints.current.push({ x, y })

        // Create path string from points
        const pathString = pathPoints.current.reduce((acc, point, index) => {
          return index === 0 ? `M ${point.x}, ${point.y}` : `${acc} L ${point.x}, ${point.y}`
        }, '')
        gsap.set(pathRef.current, { attr: { d: pathString } })
      },
    })

    tl.to(rocketRef.current, {
      duration: 10,
      // ease: 'crashCurve',
      ease: 'sine.in',
      motionPath: {
        path: createCrashCurve(),
        type: 'cubic',
        // align: 'self',
        autoRotate: 90,
        // alignOrigin: [0.5, 0.5],
      },
      force3D: true,
    })

    timelineRef.current = tl
  }, [createCrashCurve, rocketRef, containerRef])

  // Save initial rocket state when component mounts
  useEffect(() => {
    if (rocketRef.current) {
      initialRocketStateRef.current = {
        rect: rocketRef.current.getBoundingClientRect(),
        style: {
          transform: window.getComputedStyle(rocketRef.current).transform,
          width: window.getComputedStyle(rocketRef.current).width,
          height: window.getComputedStyle(rocketRef.current).height,
          left: window.getComputedStyle(rocketRef.current).left,
          top: window.getComputedStyle(rocketRef.current).top,
          bottom: window.getComputedStyle(rocketRef.current).bottom,
        },
      }
    }
  }, [rocketRef])

  // Update game state when gameRound changes
  useEffect(() => {
    if (!gameRound?.state) return

    const currentState = mapSocketStateToGameState(gameRound.state, gameStateRef.current)
    const startTime = new Date(gameRound.start_time).getTime()

    gameStateRef.current = currentState
    startTimeRef.current = startTime
    setGameState(currentState)
  }, [gameRound])

  // Handle countdown timer
  useEffect(() => {
    if (gameState !== 'WAITING') return

    const timer = setInterval(() => {
      if (countdownRef.current < 0) {
        console.log('countdownRef.current <= 0')
        return
      }

      setCountdown((prev) => {
        if (prev <= 0) {
          clearInterval(timer)
        }
        countdownRef.current = prev - 1
        return prev - 1
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [gameState])

  useEffect(() => {
    const handleGameStateChange = () => {
      switch (gameState) {
        case 'WAITING':
          handleWating()
          break
        case 'PLAYING':
          handleGameStart()
          break
        case 'ENDED':
          handleGameEnd()
          break
        default:
          break
      }
    }

    const resetAllGameState = (includeRocket = true) => {
      gsap.set(pathRef.current, { attr: { d: 'M 0, 0' } })
      if (includeRocket && rocketRef.current) {
        // gsap.set(rocketRef.current, {
        //   opacity: 0,
        // })
        rocketRef.current.style.opacity = '0'
      }
      pathPoints.current = []
      multiplierRef.current = 1
      countdownRef.current = -1
      setCountdown(-1)
      setShowWinAnim(false)
    }

    const handleWating = () => {
      // reset info
      resetAllGameState()
      const countdownDuration = Math.floor((startTimeRef.current - (new Date().getTime() + diffTimeRef.current)) / 1000)
      const initCountdown = Math.max(countdownDuration - 1, 0)
      setCountdown(initCountdown)
      countdownRef.current = initCountdown
    }

    const handleGameStart = () => {
      createRocketAnimation()
      timelineRef.current?.play()

      // If loading during playing state, fast forward the animation to current time
      const elapsedTs = Date.now() - startTimeRef.current
      const elapsedSeconds = Math.min(Math.floor(elapsedTs / 1000), 10)

      if (elapsedTs > 1000 && timelineRef.current && gameStateRef.current === 'PLAYING') {
        gsap.to(timelineRef.current, {
          duration: 1,
          timeScale: elapsedSeconds,
          onComplete: () => {
            gsap.to(timelineRef.current, { timeScale: 1 })
          },
        })
      }
    }

    const handleGameEnd = () => {
      if (!rocketRef.current) return

      // Kill current animation
      if (timelineRef.current) {
        timelineRef.current.kill()
      }

      // Remove the rocket sprite class if it exists
      // rocketRef.current.classList.remove('rocket-sprite')

      // Add explode animation to list class
      // rocketRef.current.classList.add('rocket-explode')

      resetAllGameState(false)

      // Listen for animation end then show state reward
      rocketRef.current.addEventListener('animationend', () => {
        setShowWinAnim(true)
        // hide rocket ref
        // gsap.set(rocketRef.current, {
        //   opacity: 0,
        // })
        if (rocketRef.current) {
          rocketRef.current.style.opacity = '0'
        }

        // remove rocket-explode class, add rocket-sprite class
        // rocketRef.current?.classList.remove('rocket-explode')
        // rocketRef.current?.classList.add('rocket-sprite')

        const showRewardState = () => {
          if (gameStateRef.current !== 'ENDED') {
            return
          }

          setGameState('REWARD')
        }

        if (isWinnerRef.current) {
          // After 3 seconds, show top winner
          setTimeout(() => {
            showRewardState()
          }, 3000)
        } else {
          showRewardState()
        }
      })
    }

    handleGameStateChange()

    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill()
      }
    }
  }, [gameState, createRocketAnimation, setCountdown, rocketRef])

  // check multiplier to stop animation
  useEffect(() => {
    if (gameState === 'PLAYING') {
      const timer = setInterval(() => {
        const elapsed = Date.now() - startTimeRef.current
        const multiplierFromDuration = elapsedToMultiplier(elapsed)

        if (multiplierFromDuration >= multiplierRef.current) {
          // Todo: handle from socket to update multiplier (maybe not need, keep rocket playing)
          // timelineRef.current?.pause()
          // clearInterval(timer)
        }
      }, 250)

      return () => {
        clearInterval(timer)
      }
    }
  }, [gameState])

  useEffect(() => {
    const handleVisibilityChange = () => {
      const isVisible = document.visibilityState === 'visible'
      if (isVisible) {
        if (gameStateRef.current === 'PLAYING') {
          // if (rocketRef.current) {
          //   rocketRef.current.style.opacity = '1'
          // }

          const elapsedTs = Date.now() - startTimeRef.current
          const elapsedSeconds = Math.min(Math.floor(elapsedTs / 1000), 10)

          if (elapsedTs > 1500 && timelineRef.current && gameStateRef.current === 'PLAYING') {
            gsap.to(timelineRef.current, {
              duration: 1,
              timeScale: elapsedSeconds,
              onComplete: () => {
                gsap.to(timelineRef.current, { timeScale: 1 })
              },
            })
          }
        }
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [gameStateRef, rocketRef])

  return {
    gameState,
    gameRound,
    pathRef,
    containerSize,
    countdown,
    roundMultiplier,
    topCashoutOrder,
    listActiveOrders,
    rewardInfo,
    showWinAnim,
    resetAnimation: () => {
      if (rocketRef.current) {
        gsap.set(rocketRef.current, {
          clearProps: 'all',
        })
      }
    },
  }
}
