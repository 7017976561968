import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { selectUserId } from '@/redux/store/modules/auth.slice'
import { rocketTshowActions } from '@/redux/store/modules/rocketTshow'
import React, { useEffect } from 'react'

export default function OrdersSubscription() {
  const dispatch = useAppDispatch()
  const game_id = useGameId()
  const userId = useAppSelector(selectUserId) || ''
  const _message = useSubscription(Topics.rewardOrder(game_id, userId))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      data['game_id'] = game_id
      dispatch(rocketTshowActions.updateMyOrders(data))
      dispatch(rocketTshowActions.updateMyRoundOrders(data))
    } catch (error) {
      console.warn('OrdersSubscription error: ', error)
    }
  }, [message, dispatch, game_id])

  return <></>
}
