import { Configs } from '@/const/configs'
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloQueryResult,
  createHttpLink,
  DefaultContext,
  FetchResult,
  InMemoryCache,
  MaybeMasked,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { GqlError } from '../models/error.model'
import { ServiceConfig } from './service-config'

const clientCache = new InMemoryCache()

class CgApolloClient<T> extends ApolloClient<T> {
  constructor(options: ApolloClientOptions<T>) {
    super(options)
  }
  async query<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>,
  ): Promise<ApolloQueryResult<MaybeMasked<T>>> {
    return super.query(options).catch((err) => {
      throw new GqlError(err).errors
    })
  }

  async mutate<
    TData = any,
    TVariables extends OperationVariables = OperationVariables,
    TContext extends Record<string, any> = DefaultContext
  >(options: MutationOptions<TData, TVariables, TContext>): Promise<FetchResult<MaybeMasked<TData>>> {
    return super.mutate(options).catch((err) => {
      throw new GqlError(err).errors
    })
  }
}

function createApolloClient(uri: string, cache: InMemoryCache) {
  const httpLink = createHttpLink({ uri })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${ServiceConfig.token}`,
      },
    }
  })

  return new CgApolloClient({
    link: authLink.concat(httpLink),
    cache: cache,
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })
}

export const gqlClient = createApolloClient(Configs.graphApiUrl, clientCache)
