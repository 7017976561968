export const fairnessEn = `
<h1>How to verify Ball Path by Hash?</h1>
<img src="/images/plinko/plinko.ex.0.png" />
<div>
  When Rolling the ball in the Plinko game, the system generates a <tag>Randomness</tag> string using a hash function,
  combined with the <tag>Ball Index</tag>, and converts it into <tag>Path Bit</tag> to determine <tag>Ball Path</tag>.
  This process ensures that the ball path is unpredictable, fair, and transparent for all players.
</div>
<h2>Example:</h2>
<div>
  Let’s say you are playing a Plinko game and you drop <b>5 balls with 8 Rows</b>. Each ball will have a unique
  <b>Ball Index</b> (0, 1, 2, 3, 4).
</div>
<img src="/images/plinko/plinko.ex.1.png" />
<img src="/images/plinko/plinko.ex.2.png" />
<h3>Step 1: Ball Index</h3>
<ul>
  <li><b>Ball 0:</b> Ball Index = 0</li>
  <li><b>Ball 1:</b> Ball Index = 1</li>
  <li><b>Ball 2:</b> Ball Index = 2</li>
  <li><b>Ball 3:</b> Ball Index = 3</li>
  <li><b>Ball 4:</b> Ball Index = 4</li>
</ul>

<h3>Step 2: Randomness String</h3>
<div>
  At the start of a round of Plinko, the system generates a <tag>Randomness</tag> string for that round. This randomness
  string is used to generate the final <tag>Randomness string</tag> for each ball by combining it with the
  <tag>Ball Index</tag>.
</div>
<div>For example:</div>
<ul>
  <li>
    Let’s assume the generated <tag>Randomness</tag> for this round is
    <b>122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6</b>
  </li>
  <img src="/images/plinko/plinko.ex.3.png" />
  <li>
    For <b>Ball 0</b>, the system combines the <tag>Randomness</tag> with the <b>Ball Index</b> (0) to create the
    randomness string: 122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b>0</b>
  </li>
  <li>
    For <b>Ball 1</b>, the system combines the <tag>Randomness</tag> with the <b>Ball Index</b> (1) to create the
    randomness string: 122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b>1</b>
  </li>
</ul>

<h3>Step 3: Generate Hash</h3>
<div>
  The system processes the randomness string through a hash function. This function generates a fixed-length
  <b>hash</b> that represents the input randomness.
</div>
<div>For example:</div>

<ul>
  <li>
    <b>Ball 0's randomness string</b> (122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b>0</b>) produce
    a hash like: 57a0df686fc842a7c930492feafa337b1129b99583f16e5303613151b8b4ae96.
  </li>
  <li>
    <b>Ball 1's randomness string</b> (122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b>1</b>) produce
    a hash like: a4b716af92f0d050b12068e3659d99f5ccd3f8475813b87e6306e039bb5ce283.
  </li>
</ul>

<div>You can verify the hash generated by the system using this hash verification tool:</div>
<a href="https://emn178.github.io/online-tools/sha256.html" target="_blank"
  >https://emn178.github.io/online-tools/sha256.html</a
>

<h3>Step 4: Convert Hash into Path Bit</h3>
<div>
  The hash is then converted into a <tag>Path Bit</tag>, a series of binary digits (0s and 1s). These digits will
  determine the ball's path through the Plinko board. The number of bits required depends on the number of rows (pegs)
  in the Plinko board. In this case:
</div>
<ul>
  <li><b>8 rows→ 8 bits.</b></li>
  <li><b>0</b> means the ball goes <b>L (Left).</b></li>
  <img src="/images/plinko/plinko.ex.4.png" />
  <li><b>1</b> means the ball goes <b>R (Right).</b></li>
  <img src="/images/plinko/plinko.ex.5.png" />
</ul>

<div>For example:</div>
<ul>
  <li>
    <b>Ball 0's hash</b> (<code>122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc60</code>) could be converted into a path bit string like <code>01010111</code>,
    which translates to the path: L R L R L R R R.
  </li>
  <li>
    <b>Ball 1's hash</b> (<code>122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc61</code>) might produce a different path bit string like <code>10100100</code>,
    which translates to the path: R L R L L R L L.
  </li>
</ul>

<div>You can verify the path bits from a hash using this bit verification tool:</div>
<a href="https://www.rapidtables.com" target="_blank">https://www.rapidtables.com</a>
`
