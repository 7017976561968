import React from 'react'
import { useSprite } from './useSprite'
export { loadImage, useSprite } from './useSprite'

declare type OnError = (err: any) => void
interface SpriteAnimatorProps {
  className?: string
  width: number
  height: number
  sprite: string
  scale?: number
  direction?: 'horizontal' | 'vertical'
  shouldAnimate?: boolean
  //   loop?: boolean
  startFrame?: number
  fps?: number
  stopLastFrame: boolean
  onError?: OnError
  onLoad?: () => void
  onEnd?: () => void
  frameCount?: number
  wrapAfter?: number
  frame?: number
  reset: boolean
}

export const SpriteAnimator = ({
  startFrame = 0,
  sprite,
  width,
  height,
  direction = 'horizontal',
  onError,
  onLoad,
  onEnd,
  frameCount,
  fps = 60,
  shouldAnimate = true,
  stopLastFrame,
  reset,
  frame = 0,
  className = '',
  scale = 1,
  wrapAfter,
}: SpriteAnimatorProps) => {
  const style = useSprite({
    startFrame,
    sprite,
    width,
    height,
    direction,
    onError,
    onLoad,
    onEnd,
    frameCount,
    fps,
    shouldAnimate,
    stopLastFrame,
    reset,
    frame,
    scale,
    wrapAfter,
  })

  return <div className={className} style={style} />
}
