import { useEffect, useRef } from 'react'
import { GameStateEnum } from './useTShowGamePlay'
import { RootState, useAppSelector } from '@/redux/store'

export const useTShowSound = (gameState: GameStateEnum, countdown: number) => {
  const countdownSound = useRef<HTMLAudioElement | null>(null)
  const takeOffSound = useRef<HTMLAudioElement | null>(null)
  const gameStartSound = useRef<HTMLAudioElement | null>(null)
  const blastSound = useRef<HTMLAudioElement | null>(null)
  const gameOverSound = useRef<HTMLAudioElement | null>(null)

  const isMusicEnabled = useAppSelector((state: RootState) => state.sound.isMuted)
  const musicEnableRef = useRef(isMusicEnabled)
  const hasInteracted = useRef<boolean>(false)
  const pendingSound = useRef<HTMLAudioElement | null>(null)

  // if browser tab not active, pause all audio
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        countdownSound.current?.pause()
        takeOffSound.current?.pause()
        gameStartSound.current?.pause()
        blastSound.current?.pause()
        gameOverSound.current?.pause()
      } else if (musicEnableRef.current) {
        pendingSound.current?.play().catch(console.error)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // listen focus + blur to start/stop audio
  useEffect(() => {
    const handleFocus = () => {
      if (musicEnableRef.current) {
        pendingSound.current?.play().catch(console.error)
      }
    }

    const handleBlur = () => {
      if (!musicEnableRef.current) {
        countdownSound.current?.pause()
        takeOffSound.current?.pause()
        gameStartSound.current?.pause()
        blastSound.current?.pause()
        gameOverSound.current?.pause()
      }
    }

    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [])

  // Handle user interaction
  useEffect(() => {
    const handleInteraction = () => {
      hasInteracted.current = true
      if (pendingSound.current && musicEnableRef.current) {
        pendingSound.current.play().catch(console.error)
      }
    }

    window.addEventListener('click', handleInteraction)
    window.addEventListener('touchstart', handleInteraction)
    window.addEventListener('keydown', handleInteraction)

    return () => {
      window.removeEventListener('click', handleInteraction)
      window.removeEventListener('touchstart', handleInteraction)
      window.removeEventListener('keydown', handleInteraction)
    }
  }, [])

  // Initialize audio context on first interaction
  useEffect(() => {
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)()

    const resumeAudioContext = () => {
      if (audioContext.state === 'suspended') {
        audioContext.resume()
      }
    }

    window.addEventListener('click', resumeAudioContext)
    window.addEventListener('touchstart', resumeAudioContext)
    window.addEventListener('keydown', resumeAudioContext)

    return () => {
      window.removeEventListener('click', resumeAudioContext)
      window.removeEventListener('touchstart', resumeAudioContext)
      window.removeEventListener('keydown', resumeAudioContext)
      audioContext.close()
    }
  }, [])

  // Modified playSound function
  const playSound = async (audio: HTMLAudioElement | null) => {
    try {
      if (!audio) return
      pendingSound.current = audio

      if (!hasInteracted.current) {
        return
      }

      audio.currentTime = 0
      const playPromise = audio.play()
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error('Playback failed:', error)
          // Store the sound to play it when interaction happens
          // pendingSound.current = audio
        })
      }
    } catch (error) {
      console.error('Error playing sound:', error)
    }
  }

  // Initialize audio elements
  useEffect(() => {
    const initializeAudio = async () => {
      try {
        countdownSound.current = new Audio('/sound/tshows/countdown.mp3')
        takeOffSound.current = new Audio('/sound/rockets/takeOff.mp3')
        gameStartSound.current = new Audio('/sound/tshows/gameStart.mp3')
        blastSound.current = new Audio('/sound/tshows/blast.mp3')
        gameOverSound.current = new Audio('/sound/rockets/gameOver.mp3')

        // Set loop property for sounds that need to loop
        countdownSound.current.loop = true
        gameStartSound.current.loop = true
        gameOverSound.current.loop = true

        // Pre-load the audio files
        await Promise.all([
          countdownSound.current.load(),
          takeOffSound.current.load(),
          gameStartSound.current.load(),
          blastSound.current.load(),
          gameOverSound.current.load(),
        ])
      } catch (error) {
        console.error('Error initializing audio:', error)
      }
    }

    initializeAudio()

    // Cleanup function
    return () => {
      const listSoundRef = [countdownSound, takeOffSound, gameStartSound, blastSound, gameOverSound]
      listSoundRef.forEach((soundRef) => {
        if (soundRef.current) {
          soundRef.current.pause()
          soundRef.current.currentTime = 0
        }
      })
    }
  }, [gameState])

  // Stop all sounds
  const stopAllSounds = () => {
    const listSoundRef = [countdownSound, takeOffSound, gameStartSound, blastSound, gameOverSound]
    listSoundRef.forEach((soundRef) => {
      if (soundRef.current) {
        soundRef.current.pause()
        soundRef.current.currentTime = 0
      }
    })
  }

  // Handle game state changes
  useEffect(() => {
    if (!isMusicEnabled) return

    stopAllSounds()

    switch (gameState) {
      case 'WAITING':
        playSound(countdownSound.current)
        break
      case 'PLAYING':
        playSound(gameStartSound.current)
        break
      case 'ENDED':
        playSound(blastSound.current)
        break
      case 'REWARD':
        playSound(gameOverSound.current)
        break
      default:
        break
    }
  }, [gameState, isMusicEnabled])

  // Handle countdown reaching zero
  useEffect(() => {
    if (!isMusicEnabled) return

    if (countdown === 0 && gameState === 'WAITING') {
      stopAllSounds()
      playSound(takeOffSound.current)
    }
  }, [countdown, gameState, isMusicEnabled])

  useEffect(() => {
    musicEnableRef.current = isMusicEnabled

    if (!isMusicEnabled) {
      stopAllSounds()
    }
  }, [isMusicEnabled])

  return { stopAllSounds }
}
