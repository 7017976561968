import useGameId from '@/hooks/useGameId'
import { Flex, Image, Stack, SystemStyleObject, Text } from '@chakra-ui/react'
import { RootState, useAppDispatch, useAppSelector } from '@store'
import { selectDiffTime, selectGameConfigs, selectGameEndTime, selectGameStartTime } from '@store/modules/game.slice'
import orderSlice, { selectBetAmountByType, selectOrderBetType } from '@store/modules/order.slice'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { GrMoney } from 'react-icons/gr'
import { gameConfigs } from '../../const/game-type'
import useCustomTranslation from '../../hooks/useCustomTranslation'
import Speedometer from '../UI/Clock'

function Clock({ sx }: { sx?: SystemStyleObject }) {
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const startTime = useAppSelector(selectGameStartTime) || 0
  const endTime = useAppSelector(selectGameEndTime) || 0
  const diffTime = useAppSelector(selectDiffTime)
  const total = Math.round((endTime - startTime) / 1000) || 30
  const value = (Date.now() + diffTime - startTime) / 1000
  const current = value >= 0 && value <= total ? value : 0
  const [currentSecond, setCurrentSecond] = useState(current)
  const audioRef: any = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const value = (Date.now() + diffTime - startTime) / 1000
      const current = value >= 0 && value <= total ? value : 0
      setCurrentSecond(Math.round(current))
    }, 200)

    return () => clearInterval(interval)
  }, [startTime, diffTime, total])

  useEffect(() => {
    if (currentSecond === 0) {
      if (audioRef.current) {
        if (isMuted) audioRef.current.play()
        else audioRef.current.pause()
      }
    }
  }, [currentSecond])

  return (
    <Flex sx={sx} alignItems="center">
      <audio autoPlay ref={audioRef} src="/sound/open.mp3" />
      <Speedometer totalSeconds={total} current={currentSecond} />
    </Flex>
  )
}

function BigAndSmall({ style }: { style?: React.CSSProperties }) {
  const { t } = useCustomTranslation()
  const gameId = useGameId()

  const dispatch = useAppDispatch()
  const gameConfig = useAppSelector(selectGameConfigs)
  const winAmount = useAppSelector(selectBetAmountByType(gameId)('WIN'))

  const bet = useAppSelector(selectOrderBetType)
  const config = gameConfigs[gameId]
  const betTypes = config?.choices || config?.type || []

  useEffect(() => {
    if (!isEmpty(betTypes) && !betTypes.includes(bet)) {
      dispatch(orderSlice.actions.betTypeUpdated(betTypes[0]))
    }
  }, [gameId])

  return (
    <Flex
      style={style}
      position={'absolute'}
      top={'50%'}
      left={'50%'}
      transform={'translate(-50%, -50%)'}
      w="100%"
      display={'flex'}
      flexDirection={'column'}
      alignItems="center"
      justifyContent="center"
    >
      <Clock
        sx={{
          marginTop: {
            '375px': '-6px'
          }
        }}
      />
      <Stack
        cursor="pointer"
        width={'100%'}
        alignItems="center"
        textAlign={'center'}
        color={'#B4FF02'}
        gap={{ base: 0 }}
        mt={{ base: -9, 'iphone-xr': -8, 'pc-500-up': -6 }}
      >
        <Text
          className={`type0`}
          textTransform="uppercase"
          fontSize={{
            base: '14px',
          }}
          fontWeight={500}
        >
          {t('gamePlay.request-lucky')}
        </Text>
        <Flex
          className="typeBig"
          position="relative"
          textTransform="uppercase"
          mt={{
            base: 0,
            'iphone-xr': 1,
            'galaxy-zfold5': -1,
            'galaxy-s20': '4px',
            'pc-500-up': 1,
          }}
          fontSize={{
            base: '12px',
            // 'iphone-14-pro-max': '14px',
          }}
          fontWeight={600}
          cursor="pointer"
          color={'#B4FF02'}
          gap={1}
        >
          <Text>1</Text>
          <Text>:</Text>
          <Text>{gameConfig.winRate}</Text>
        </Flex>
        <Flex
          gap={2}
          alignItems="center"
          mt={{
            base: 0,
            'iphone-xr': 1,
            'galaxy-zfold5': -1,
            'galaxy-s20': '4px',
            'pc-500-up': 1,
          }}
        >
          <GrMoney fontSize={10} />
          <Text
            fontSize={{
              base: '12px',
            }}
          >
            {winAmount.toFixed(2)}
          </Text>
        </Flex>
      </Stack>
    </Flex>
  )
}

export default function CountdownLucky() {
  return (
    <Stack
      background="rgba(76, 175, 80, 0.0)"
      mt={{
        base: -8,
      }}
      gap={1}
      position="relative"
    >
      <Image src="/images/Group1.webp" alt="" />
      <Stack
        position="absolute"
        inset="0"
        p={3}
        sx={{
          top: {
            base: '-2px',
            '500px': '1px',
          }
        }}
      >
        <Image src="/images/gaming/atable.png" />
        <BigAndSmall />
      </Stack>
    </Stack>
  )
}
