import { bgColorApp, textGrayWhite } from '@/const/color'
import { Box, Button, Flex, Image, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef } from 'react'
import {
  getPositionCard,
  getRandomValue,
  getTypeBettingFromCard,
  LIST_SUIT_HILO,
  LIST_VALUE_HILO,
} from '../ultis/ultis'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import {
  createNewOrderHilo,
  getLastRoundHilo,
  hiloActions,
  selectCardHilo,
  selectConfigHilo,
  selectGameRoundHilo,
  selectIsRoundEndHilo,
  switchCardInRoundHilo,
} from '@/redux/store/modules/hilo'
import { HiloOrderInput, HiloRound, HiloSwitchInput, RoundStatus } from '@/@generated/gql/graphql-hash'
import TimerHilo from './Timer'
import { selectDiffTime } from '@/redux/store/modules/game.slice'
import { f } from 'fintech-number'
import ls from '@/libs/local-storage'
import _ from 'lodash'

export default function CardTable() {
  const cardRef = useRef(null)
  const bgCardRef = useRef(null)
  const card = useAppSelector(selectCardHilo)
  const dispatch = useAppDispatch()
  const gameRound = useAppSelector(selectGameRoundHilo)
  const isRoundEnd = useAppSelector(selectIsRoundEndHilo)
  const diffTime = useAppSelector(selectDiffTime)
  const config = useAppSelector(selectConfigHilo)
  //Set default card
  useEffect(() => {
    if (card && cardRef && cardRef.current) {
      const { x, y } = getPositionCard(card, 1625)
      cardRef.current!.style.backgroundPosition = `-${x}px -${y}px`
    }
  }, [card])

  useEffect(() => {
    dispatch(getLastRoundHilo({})).then((res) => {
    })
  }, [])

  useEffect(() => {
    const cardLS = ls.get('card-hilo')
    if (!_.isEmpty(cardLS)) {
      dispatch(hiloActions.updateCard(cardLS))
    } else {
      const randomCard = {
        value: getRandomValue(LIST_VALUE_HILO),
        suit: getRandomValue(LIST_SUIT_HILO),
      }
      dispatch(hiloActions.updateCard(randomCard))
      ls.set('card-hilo', randomCard)
    }

    cardRef.current!.classList.add('rotate-bg-card')
    // setTimeout(() => {
    //   bgCardRef.current!.classList.add('rotate-bg-card-anim')
    // }, 100)
    // setTimeout(() => {
    //   bgCardRef.current!.classList.remove('rotate-bg-card-anim')
    // }, 600)
    setTimeout(() => {
      cardRef.current!.classList.remove('rotate-bg-card')
      const { x, y } = getPositionCard(cardLS, 1625)
      cardRef.current!.style.backgroundPosition = `-${x}px -${y}px`
    }, 400)
  }, [])

  //Switch random card
  const handleSwitchCard = () => {
    if (gameRound.round_status === RoundStatus.Running) {
      const params: HiloSwitchInput = {
        game_id: gameRound.game_id,
        round_id: gameRound.id,
        index: gameRound.max_index + 1,
      }
      dispatch(switchCardInRoundHilo(params)).then((res) => {
        // console.log('res', res)
      })
      return
    }
    const randomCard = {
      value: getRandomValue(LIST_VALUE_HILO),
      suit: getRandomValue(LIST_SUIT_HILO),
    }
    dispatch(hiloActions.updateCard(randomCard))
    dispatch(hiloActions.updateGameRound({} as HiloRound))
    ls.set('card-hilo', randomCard)
    cardRef.current!.classList.add('rotate-card-anim-1')
    // setTimeout(() => {
    //   bgCardRef.current!.classList.add('rotate-bg-card-anim')
    // }, 100)
    // setTimeout(() => {
    //   bgCardRef.current!.classList.remove('rotate-bg-card-anim')
    // }, 600)
    setTimeout(() => {
      cardRef.current!.classList.remove('rotate-card-anim-1')
      const { x, y } = getPositionCard(randomCard, 1625)
      cardRef.current!.style.backgroundPosition = `-${x}px -${y}px`
    }, 400)
  }

  const cardTypeBetting = useMemo(() => {
    return getTypeBettingFromCard(card)
  }, [card])

  const handleClickBetting = (type: string) => {
    if (isRoundEnd) return
    // dispatch()
    const param: HiloOrderInput = {
      game_id: gameRound.game_id,
      round_id: gameRound.id,
      bet: type,
      index: gameRound.max_index + 1,
    }

    dispatch(createNewOrderHilo(param)).then((res) => {})
  }

  const seconds = useMemo(() => {
    return new Date(gameRound?.last_time).valueOf() - new Date().valueOf() - diffTime
  }, [gameRound?.last_time])

  const multiplierByCard = useMemo(() => {
    if (!config?.hiloMultipliers) return []
    return config?.hiloMultipliers?.[+card?.value - 1]
  }, [card?.value, config?.hiloMultipliers])

  return (
    <Stack position="relative" height="420px">
      <Image
        src="/images/Group1.webp"
        zIndex="0"
        position="absolute"
        top={0}
        left={0}
        w="100%"
        aspectRatio={2 / 1.6}
        alt=""
        pointerEvents="none"
      />
      <Image
        position="absolute"
        inset="0"
        p={3}
        sx={{
          top: {
            base: '-2px',
            '500px': '1px',
          },
        }}
        src="/images/gaming/atable.png"
        aspectRatio={2 / 1.55}
        pointerEvents="none"
      />
      <Stack
        className="flex !flex-row justify-between"
        px={4}
        position="absolute"
        top="24px"
        left="0"
        zIndex={1}
        w="100%"
      >
        <Stack
          background="#171718"
          pl={2}
          pr={4}
          py={2}
          borderRadius="0 24px 24px 0"
          boxShadow="0px 1.067vw 0.533vw rgba(28,33,62,0.2)"
        >
          <Text fontSize={16} lineHeight={1} color="#f5b638">
            Odds: {gameRound.round_status === RoundStatus.Running ? `${(+gameRound?.odds).toFixed(2)}x` : `--`}
          </Text>
        </Stack>
        <Stack
          background="#171718"
          pl={2}
          pr={4}
          py={2}
          borderRadius="24px 0 0 24px"
          boxShadow="0px 1.067vw 0.533vw rgba(28,33,62,0.2)"
        >
          <Text fontSize={16} lineHeight={1} color="#f5b638">
            Bonus:{' '}
            {gameRound.round_status === RoundStatus.Running
              ? `${f(gameRound?.reward, {
                  decimal: 2,
                  round: 'down',
                })}`
              : `--`}
          </Text>
        </Stack>
      </Stack>
      <Stack position="relative" zIndex={1} w="fit-content" color="#fff" top="18%" left="22%" gap={0}>
        {/* {!isRoundEnd && <TimerHilo seconds={seconds} />} */}
        <Flex gap={6} alignItems="center">
          <Stack
            w="125px"
            height="164px"
            position="relative"
            backgroundImage="url(/images/cards-spirit.png)"
            backgroundPosition="0px 165px"
            backgroundSize="1625px"
          >
            <Stack
              //   w="125px"
              ref={cardRef}
              //   height="164px"
              backgroundImage="url(/images/cards-spirit.png)"
              backgroundPosition="250px 0px"
              backgroundSize="1625px"
              position="absolute"
              top={0}
              left={0}
              w="100%"
              h="100%"
              zIndex={1}
              // className="rotate-card-anim-1"
            ></Stack>
            <Stack
              // w="125px"
              ref={bgCardRef}
              // height="164px"
              position="relative"
              //   top={0}
              //   left={0}
              w="100%"
              h="100%"
              zIndex={0}
              backgroundImage="url(/images/cards-spirit.png)"
              backgroundPosition="0px 165px"
              backgroundSize="1625px"
              // className="rotate-card-anim-1"
            ></Stack>
          </Stack>

          <Stack>
            <Stack
              w="125px"
              height="125px"
              position="relative"
              cursor={isRoundEnd ? 'not-allowed' : 'pointer'}
              _before={{
                content: `""`,
                backgroundImage: 'url(/images/bg-btn-bigger.png)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                aspectRatio: 1 / 1,
                backgroundSize: 'cover',
                zIndex: -1,
              }}
              _after={{
                content: isRoundEnd ? `""` : 'unset',
                backgroundImage: 'url(/images/bg-shadow.png)',
                position: 'absolute',
                top: '-7px',
                left: 0,
                width: '100%',
                aspectRatio: 1 / 1,
                backgroundSize: 'cover',
                zIndex: 2,
              }}
              className="flex items-center justify-center"
              onClick={() => {
                handleClickBetting(cardTypeBetting?.big as string)
              }}
            >
              <Text color="#000">{cardTypeBetting?.big}</Text>
              <Stack color="#f3b242" background="#3d3d3dcc" padding="0 8px" borderRadius={6}>
                <Text fontSize={16} fontWeight="500">
                  {multiplierByCard?.[0]}
                </Text>
              </Stack>
            </Stack>
            <Stack
              w="125px"
              height="125px"
              position="relative"
              cursor={isRoundEnd ? 'not-allowed' : 'pointer'}
              _before={{
                content: `""`,
                backgroundImage: 'url(/images/bg-btn-smaller.png)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                aspectRatio: 1 / 1,
                backgroundSize: 'cover',
                zIndex: -1,
              }}
              _after={{
                content: isRoundEnd ? `""` : 'unset',
                backgroundImage: 'url(/images/bg-shadow.png)',
                transform: 'rotate(180deg)',
                // background: "#3d3d3dcc",
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                aspectRatio: 1 / 1,
                backgroundSize: 'cover',
                zIndex: 2,
              }}
              className="flex items-center justify-center"
              onClick={() => {
                handleClickBetting(cardTypeBetting?.small as string)
              }}
            >
              <Text color="#000">{cardTypeBetting?.small}</Text>
              <Stack color="#f3b242" background="#3d3d3dcc" padding="0 8px" borderRadius={6}>
                <Text fontSize={16} fontWeight="500">
                  {/* {multiplierByCard?.[1]} */}
                  {multiplierByCard?.[1]}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
        <Stack alignItems="center" mt="-8px">
          <Button
            aria-label="Amount"
            aria-labelledby="Amount"
            height={'30px'}
            minWidth="70px"
            fontSize="1rem"
            backgroundColor={bgColorApp}
            borderRadius={8}
            color={textGrayWhite}
            transition="0.2s all"
            fontWeight={500}
            // isActive={amount === _amount}
            _hover={{ backgroundColor: '#29292f', color: '#fff' }}
            _active={{
              backgroundColor: '#4e4f50',
              color: '#b2fc00',
              textShadow: '0px 0px 10px #b2fc00',
              fontWeight: 600,
            }}
            // onClick={() => dispatch(orderSlice.actions.amountUpdated(_amount))}
            onClick={() => handleSwitchCard()}
            lineHeight={1}
          >
            <Box
              as="span"
              // position="relative"
              // bottom="-1px"
            >
              Switch{gameRound?.round_status === RoundStatus.Running && `*0${gameRound?.switch_time}`}
            </Box>
          </Button>
        </Stack>
      </Stack>
      <Stack
        position="relative"
        left={0}
        bottom="20px"
        content={' '}
        w="110px"
        h="110px"
        zIndex={1}
        backgroundImage="url(/images/img-treasure.png)"
        backgroundPosition="0px 0px"
        backgroundSize="3200px"
      >
        {gameRound.round_status === RoundStatus.Running && (
          <Stack
            className="flex items-center justify-center"
            position="absolute"
            w="22px"
            h="22px"
            right="10px"
            top="30px"
            borderRadius="50%"
            background="rgba(27,24,62,0.8)"
            border="1px solid #ff9276"
          >
            <Text color="#ff9276">{gameRound?.win_streak}</Text>
          </Stack>
        )}
      </Stack>
      {!isRoundEnd && (
        <Stack
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          top="4px"
          content={' '}
          w="110px"
          h="110px"
          zIndex={1}
        >
          <TimerHilo seconds={seconds} />
        </Stack>
      )}
    </Stack>
  )
}
