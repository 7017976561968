import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { rocketTshowActions } from '@/redux/store/modules/rocketTshow'
import React, { useEffect } from 'react'

export default function MultiplierSubscription() {
  const dispatch = useAppDispatch()
  const game_id = useGameId()
  const _message = useSubscription(Topics.getMultiplier(game_id))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      dispatch(rocketTshowActions.updateMultiplier(data))
    } catch (error) {
      console.warn('MutipilierSubscription error: ', error)
    }
  }, [message])

  return <></>
}
