import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

type SubTitleProps = FlexProps

const SubTitle = ({children, ...rest}: SubTitleProps) => {
  return (
    <Flex
      lineHeight="1"
      alignItems="center"
      justifyContent="center"
      fontSize="20px"
      fontWeight="400"
      color="#ff4218"
      gap="5px"
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default SubTitle;
