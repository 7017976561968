import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react';

type TotalBonusProps = BoxProps & {
  value: number,
}

const TotalBonus = ({value}: TotalBonusProps) => {
  return (
    <Box
      borderRadius="50px"
      backgroundColor="#fde8e8"
      color="#ff4218"
      padding="2px 5px"
      lineHeight="1"
      fontSize="20px"
      textAlign="center"
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      bottom="40px"
      width="60%"
      zIndex="1"
      _before={{
        content: '""',
        width: '40px',
        aspectRatio: '73/61',
        background: 'url("/bingo/images/diamond.webp") no-repeat center',
        backgroundSize: 'contain',
        position: 'absolute',
        top: '-2px',
        left: '-15px',
      }}
      _after={{
        content: '""',
        width: '40px',
        aspectRatio: '73/61',
        background: 'url("/bingo/images/diamond.webp") no-repeat center',
        backgroundSize: 'contain',
        position: 'absolute',
        top: '-2px',
        right: '-15px',
      }}
    >
      Total bonus: {value}
    </Box>
  )
}

export default TotalBonus;
