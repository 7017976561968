import { Flex } from '@chakra-ui/react'
import React from 'react';
import SubTitle from '@/bingo/components/FormSection/SubTitle.tsx'
import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import { useAppSelector } from '@store'
import { selectBingoNumbersOfTickets, selectBingoTicketUnitPrice } from '@/bingo/redux/bingo.slice.ts'

const SubTotal = () => {
  const numbersOfTickets = useAppSelector(selectBingoNumbersOfTickets)
  const ticketUnitPrice = useAppSelector(selectBingoTicketUnitPrice)
  const subTotal = numbersOfTickets * ticketUnitPrice

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderTop="2px solid white"
      padding="10px 0 0"
      marginTop="auto"
    >
      <SubTitle>Subtotal</SubTitle>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="5px"
      >
        <BingoCurrencyIcon />
        <SubTitle>{subTotal}</SubTitle>
      </Flex>
    </Flex>
  )
}

export default SubTotal;
