import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react'

type ButtonPerProps = ButtonProps & {
  per: number,
  isSelected?: boolean,
}

const ButtonPer = ({per, isSelected, ...rest}: ButtonPerProps) => {
  return (
    <Button
      color="#560f40"
      background="linear-gradient(180deg, #ffe1e1 0%, #ffd7d7 100%)"
      borderRadius="6px"
      padding="5px 10px"
      minWidth="80px"
      minHeight="28px"
      height="28px"
      fontSize="14px"
      fontWeight="400"
      lineHeight="1"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      opacity={isSelected ? 1 : 0.5}
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {per} per.
    </Button>
  )
}

export default ButtonPer;
