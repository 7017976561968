import React from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'
import { bingoNumbers } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import NumberRate from '@/bingo/components/FormSection/EditTicket/NumbersRating/NumberRate.tsx'
import { BingoGroup } from '@/bingo/types/bingo.ts'

type NumbersRatingProps = FlexProps & {
  currentGroup: BingoGroup,
}

const NumbersRating = ({currentGroup}: NumbersRatingProps) => {
  return (
    <Flex
      alignItems="flex-end"
      justifyContent="space-between"
      height="100px"
      marginBottom="30px"
    >
      {bingoNumbers[currentGroup].map((number, index) => (
        <NumberRate number={number} rate={Math.floor(Math.random() * (100 - 30 + 1)) + 30} variant={index % 2 === 0 ? 'hot' : 'cold'} />
      ))}
    </Flex>
  )
}

export default NumbersRating;
