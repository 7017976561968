import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react'

type TicketPatternCellProps = FlexProps & {
  value?: number | string,
  selectable?: boolean,
  isSelected?: boolean,
}

const TicketPatternCell = ({value, selectable, isSelected,...rest}: TicketPatternCellProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      background="linear-gradient(180deg, #fff8f1 0%, #ffeac5 100%)"
      color={isSelected ? 'white' : '#ff4218'}
      fontSize="20px"
      fontWeight="500"
      lineHeight="1"
      minWidth="calc(20% - 2px)"
      width="calc(20% - 2px)"
      aspectRatio="1/1"
      flex="1"
      position="relative"
      cursor={selectable ? 'pointer' : 'auto'}
      _after={isSelected ? {
        content: '""',
        width: '70%',
        height: '70%',
        borderRadius: '50%',
        background: '#ff4218',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '0'
      } : ''}
      {...rest}
    >
      <Box
        as="span"
        position="relative"
        zIndex="1"
      >
        {value}
      </Box>
    </Flex>
  )
}

export default TicketPatternCell;
