import React from 'react';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import TicketPatternBoard from '@/bingo/components/Common/Ticket/TicketPatternBoard.tsx'
import TicketButton from '@/bingo/components/Common/Ticket/TicketButton.tsx'
import TicketCollect from '@/bingo/components/Common/Ticket/TicketCollect.tsx'
import TicketMultiplier from '@/bingo/components/Common/Ticket/TicketMultiplier.tsx'
import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { generateBingoCard } from '@/bingo/const/bingoHelpers.ts'
import { useAppDispatch, useAppSelector } from '@store'
import { bingoActions, selectBingoTicketsGenerated } from '@/bingo/redux/bingo.slice.ts'

type TicketProps = BoxProps & {
  data?: LocalBingoTicket,
  onEdit?: () => void,
  isBetSuccess?: boolean,
}

const Ticket = ({data, onEdit, isBetSuccess, ...rest}: TicketProps) => {
  const generatedTickets = useAppSelector(selectBingoTicketsGenerated)
  const dispatch = useAppDispatch()

  const handleSwitchTicket = () => {
    const newTicket: LocalBingoTicket = {
      ...data,
      numbers: generateBingoCard(),
    }

    const tempGeneratedTickets = [...generatedTickets]
    const index = tempGeneratedTickets.findIndex((item) => item.id === newTicket.id)

    if (index !== -1) {
      tempGeneratedTickets[index] = newTicket
      dispatch(bingoActions.updateTicketsGenerated(tempGeneratedTickets))
    }
  }

  const handleEditTicket = () => {
    if (!onEdit) return

    onEdit()
  }

  return (
    <Box
      backgroundColor="white"
      borderRadius="5px"
      minWidth="calc(50% - 10px)"
      width="calc(50% - 10px)"
      position="relative"
      {...rest}
    >
      <TicketCollect />
      <TicketMultiplier value={data?.multiplier} />
      <TicketPatternBoard numbers={data?.numbers} />
      <Flex
        alignItems="center"
        justifyContent="center"
        padding="5px 10px 8px"
        gap="10px"
      >
        {!isBetSuccess && (
          <>
            <TicketButton
              onClick={handleSwitchTicket}
            >
              Switch
            </TicketButton>
            <TicketButton
              onClick={handleEditTicket}
            >
              Edit
            </TicketButton>
          </>
        )}
        {isBetSuccess && (
          <Text
            color="#ff4218"
            fontSize="20px"
            fontWeight="500"
            lineHeight="1"
            padding="6px 0"
          >
            Win: 0
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export default Ticket;
