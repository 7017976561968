import { Button, ButtonProps, Flex, Image } from '@chakra-ui/react'
import React from 'react'

type FooterButonProps = ButtonProps

const FooterButton = ({children, ...rest}: FooterButonProps) => {
  return (
    <Button
      background="none"
      border="none"
      outline="none"
      width="auto"
      height="auto"
      display="inline-flex"
      alignItems="center"
      gap="6px"
      color="#4d5c72"
      fontSize="14px"
      lineHeight="1"
      padding="0"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}

const RocketTabFooter = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderBottomRadius="13.3px"
      background="rgba(34, 42, 54, 0.7)"
      padding="8.3px 16.3px"
      gap="21.3px"
    >
      <FooterButton>
        <Image src="/rocket/images/form/icon-star.png" width="14.3px" height="14.3px" />
        收藏
      </FooterButton>
      <FooterButton>
        <Image src="/rocket/images/form/icon-share.png" width="14.3px" height="14.3px" />
        分享
      </FooterButton>
      <FooterButton>
        <Image src="/rocket/images/form/icon-sound-on.png" width="17px" height="13px" />
        声音
      </FooterButton>
      <FooterButton
        marginLeft="auto"
      >
        <Image src="/rocket/images/form/icon-help.png" width="15px" height="15px" />
        玩法
      </FooterButton>
    </Flex>
  )
}

export default RocketTabFooter;
