import React from 'react'
import Rocket from '@components/rocket/GamePlay/Rocket.tsx'
import RKQuickForm from '@/components/rocket/QuickForm/index'
import TabSection from '@/components/rocket/TabSection'
import MyBets from '@/components/rocket/MyBets'
import AuthGame from '@/components/rocket/AuthGame'
import GameRoundSubscription from '@/components/rocket/Subcription/GameRoundSubscription'
import ConfigRoundSubscription from '@/components/rocket/Subcription/ConfigRoundSubscription'
import MultiplierSubscription from '@/components/rocket/Subcription/MultiplierSubscription'
import OrdersSubscription from '@/components/rocket/Subcription/OrdersSubscription'
import RoundOrderSubscription from '@/components/rocket/Subcription/RoundOrderSubscription'
import RoundConfig from '@/components/rocket/RoundConfig'
import RocketTabs from '@components/rocket/NewUI/RocketTabs'
import RocketFormContainer from '@components/rocket/NewUI/RocketFormContainer.tsx'
import RocketGameBackground from '@components/rocket/NewUI/RocketGameBackground.tsx'
import RocketTables from '@components/rocket/NewUI/RocketTables'
import RocketAgencyLogo from '@components/rocket/NewUI/Common/RocketAgencyLogo.tsx'

export default function MainGameRocket() {
  return (
    <AuthGame>
      <RocketGameBackground>
        <Rocket />
        {/*<RoundConfig />*/}
        <GameRoundSubscription />
        <ConfigRoundSubscription />
        <MultiplierSubscription />
        <OrdersSubscription />
        <RoundOrderSubscription />
        <RocketFormContainer>
          <RocketTabs />
          <RocketTables />
          <RocketAgencyLogo
            imageProps={{
              src: '/rocket/images/logo-x-game.png',
              width: '99.6px',
              aspectRatio: '299/53',
            }}
          />
          {/*<RKQuickForm />*/}
          {/*<TabSection />*/}
          {/*<MyBets />*/}
        </RocketFormContainer>
      </RocketGameBackground>
    </AuthGame>
  )
}
