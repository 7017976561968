import useGameId from '@/hooks/useGameId'
import { Flex, Image, Stack, SystemStyleObject, Text } from '@chakra-ui/react'
import { RootState, useAppDispatch, useAppSelector } from '@store'
import { selectDiffTime, selectGameConfigs, selectGameEndTime, selectGameStartTime } from '@store/modules/game.slice'
import orderSlice, { selectBetAmountByTypes, selectOrderBetType } from '@store/modules/order.slice'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { gameConfigs } from '../../const/game-type'
import useCustomTranslation from '../../hooks/useCustomTranslation'
import Speedometer from '../UI/Clock'

function HashData({ style, total }: { style?: React.CSSProperties; total: number }) {
  return (
    <Flex
      style={style}
      backgroundColor="#2e0712"
      borderRadius={50}
      gap={1.5}
      alignItems="center"
      paddingInline={1}
      paddingRight={2}
      paddingBlock={1}
    >
      <Stack backgroundColor={Number(total) > 0 ? 'white' : '#4e0b1d'} borderRadius="50%" p={1}>
        <FaPlus fontSize={9} color="#b13e5e" />
      </Stack>
      <Text
        fontSize={14}
        paddingRight={1}
        color={Number(total) > 0 ? 'white' : '#b46177'}
        fontWeight={Number(total) > 0 ? 500 : 500}
        lineHeight={1}
        // mt="2px"
      >
        {total}
      </Text>
    </Flex>
  )
}

function Clock({ sx }: { sx?: SystemStyleObject }) {
  const isMuted = useAppSelector((state: RootState) => state.sound.isMuted)
  const startTime = useAppSelector(selectGameStartTime) || 0
  const endTime = useAppSelector(selectGameEndTime) || 0
  const diffTime = useAppSelector(selectDiffTime)
  const total = Math.round((endTime - startTime) / 1000) || 30
  const value = (Date.now() + diffTime - startTime) / 1000
  const current = value >= 0 && value <= total ? value : 0
  const [currentSecond, setCurrentSecond] = useState(current)
  const audioRef: any = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      const value = (Date.now() + diffTime - startTime) / 1000
      const current = value >= 0 && value <= total ? value : 0
      setCurrentSecond(Math.round(current))
    }, 200)

    return () => clearInterval(interval)
  }, [startTime, diffTime, total])

  useEffect(() => {
    if (currentSecond === 0) {
      if (audioRef.current) {
        if (isMuted) audioRef.current.play()
        else audioRef.current.pause()
      }
    }
  }, [currentSecond])

  return (
    <Flex sx={sx} alignItems="center">
      <audio autoPlay ref={audioRef} src="/sound/open.mp3" />
      <Speedometer totalSeconds={total} current={currentSecond} />
    </Flex>
  )
}

function BigAndSmall({ sx }: { sx?: SystemStyleObject }) {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  const dispatch = useAppDispatch()
  const gameConfig = useAppSelector(selectGameConfigs)
  const bet = useAppSelector(selectOrderBetType)
  const config = gameConfigs[gameId]
  const betTypes = config?.choices || config?.type || []

  useEffect(() => {
    if (!isEmpty(betTypes) && !betTypes.includes(bet)) {
      dispatch(orderSlice.actions.betTypeUpdated(betTypes[0]))
    }
  }, [gameId])

  return (
    <Flex sx={sx} w="100%" alignItems="center" justifyContent="space-around" gap={3}>
      {betTypes.map((item) => {
        const isActive = item == bet
        return (
          <Stack
            key={item}
            textAlign="center"
            w="50%"
            bgSize="cover"
            alignItems="center"
            justifyContent="center"
            position="relative"
            cursor="pointer"
            transition="0.5s all"
            onClick={() => {
              dispatch(orderSlice.actions.betTypeUpdated(item))
            }}
            data-id={`bet-option-${item}`}
          >
            <Image
              src={
                isActive
                  ? `/images/gaming/betting${gameId}${item}Focus.png`
                  : `/images/gaming/betting${gameId}${item}.png`
              }
            />
            <Stack
              alignItems="center"
              position="absolute"
              // top={gameId === GameUrlMap.H_BS ? '-31px' : 'unset'}
              // left={(gameId === GameUrlMap.H_BS) ? (item === 'BIG' ? '57px' : '50px') : 'unset'}
              color={isActive ? '#B4FF02' : 'white'}
            >
              <Text
                className={`type_${item}`}
                position="relative"
                textTransform="uppercase"
                fontSize={{ base: '23px' }}
                color={isActive ? '#9FE00' : 'white'}
                fontWeight={500}
                cursor="pointer"
                // letterSpacing={(gameId === GameUrlMap.H_BS) ? (item === 'BIG' ? '-0.6px' : '-1.8px') : ''}
              >
                {t(item)}
              </Text>
              <Flex
                gap={1}
                alignItems="center"
                mt={-3}
                fontSize="16px"
                // color={focusedBig === true ? '#e4ff87' : '#dc8098'}
                cursor="pointer"
                color={isActive ? '#9FE00' : '#dc8098'}
              >
                <Text>1</Text>
                <Text>:</Text>
                <Text>{gameConfig.winRate}</Text>
              </Flex>
            </Stack>
          </Stack>
        )
      })}
    </Flex>
  )
}

export default function Countdown() {
  const gameId = useGameId()
  const config = gameConfigs[gameId]
  const betTypes = config?.choices || config?.type || []
  const amountByTypes = useAppSelector(selectBetAmountByTypes(gameId))
  const big = amountByTypes[betTypes[0]] || 0
  const small = amountByTypes[betTypes[1]] || 0

  return (
    <Stack
      background="rgba(76, 175, 80, 0.0)"
      mt={{
        base: -8,
      }}
      gap={1}
      position="relative"
    >
      <Image src="/images/Group1.webp" alt="" />
      <Stack position="absolute" w="100%" p={2} gap="0" alignItems="center">
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <HashData total={big || 0} />
          <HashData total={small || 0} />
        </Flex>
        <Clock
          sx={{
            marginTop: {
              base: '-32px',
              '350px': '-28px',
              '375px': '-28px',
              '400px': '-25px',
              '412px': '-24px',
              '414px': '-24px',
              '430px': '-20px',
              '500px': '-16px',
            }
          }}
        />
        <BigAndSmall
          sx={{
            padding: '8px',
            marginTop: {
              base: '-58px',
              '375px': '-61px',
            }
          }}
        />
      </Stack>
    </Stack>
  )
}
