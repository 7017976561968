import { Box, Flex, InputProps } from '@chakra-ui/react'
import React, { HTMLProps } from 'react'
import AutoEscapeInput from '@components/rocket/NewUI/AutoEscapeInput.tsx'
import RocketInputTitle from '@components/rocket/NewUI/RocketTabs/RocketInputTitle.tsx'
import RocketInputSubTitle from '@components/rocket/NewUI/RocketTabs/RocketInputSubTitle.tsx'

type ClassicTabProps = {
  inputProps?: HTMLProps<HTMLInputElement> & InputProps,
  plusCallback?: (params?: any) => any,
  minusCallback?: (params?: any) => any,
  isFormDisabled?: boolean,
}

const ClassicTab = ({inputProps, plusCallback, minusCallback, isFormDisabled}: ClassicTabProps) => {
  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap="10px"
        lineHeight="1"
        fontStyle="italic"
        marginBottom="7.5px"
      >
        <RocketInputTitle>自动逃生点</RocketInputTitle>
        <RocketInputSubTitle>预估机会0.99%</RocketInputSubTitle>
      </Flex>
      <AutoEscapeInput
        inputProps={inputProps}
        plusCallback={plusCallback}
        minusCallback={minusCallback}
        isFormDisabled={isFormDisabled}
      />
    </Box>
  )
}

export default ClassicTab;
