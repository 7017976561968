import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react'
import GroupColumn from '@/bingo/components/FormSection/EditTicket/CurrentTicket/GroupColumn.tsx'
import { bingoGroups, bingoNumbers } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import { BingoGroup } from '@/bingo/types/bingo.ts'

type CurrentTicketProps = FlexProps & {
  currentGroup: BingoGroup,
}

const CurrentTicket = ({currentGroup}: CurrentTicketProps) => {
  return (
    <Flex
      borderRadius="12px"
      overflow="hidden"
      flex="1"
    >
      {bingoGroups.map((group) => (
        <GroupColumn group={group} key={group} isActive={currentGroup === group} data={[...bingoNumbers[group].slice(0, 3)]} />
      ))}
    </Flex>
  )
}

export default CurrentTicket;
