import { bgColorApp } from '@/const/color'
import Connector from '@/hooks/Connector.tsx'
import { login, selectIsLogged } from '@/redux/store/modules/auth.slice'
import { Stack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ErrorHandlerWrapper } from '../common/ErrorHandlerWrapper'
import WalletBalanceSubscription from '../common/WalletBallanceSubscription'
import { gameActions } from '@/redux/store/modules/game.slice'
import BreadcrumbRocket from './Breadcrumb'

export default function AuthGame({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  const isLogged = useAppSelector(selectIsLogged)
  const navigate = useNavigate()

  const [params] = useSearchParams()
  const agency_id = params.get('agency_id') || ''
  const session_token = params.get('session_token') || ''
  const access_token = params.get('access_token') || ''
  const isRelogin = !!(agency_id && session_token) || !!access_token
  const [isLogging, setLogging] = useState(isRelogin)

  useEffect(() => {
    if (isLogged && !isLogging) {
      dispatch(gameActions.getServerTime())
    }
  }, [isLogged, isLogging, dispatch])

  useEffect(() => {
    if (!isRelogin) return
    setLogging(true)
    dispatch(login({ agency_id, session_token, access_token }))
      .then((resp) => {
        if (resp.meta.requestStatus === 'fulfilled') {
          const newParams = new URLSearchParams(params)
          newParams.delete('agency_id')
          newParams.delete('session_token')
          newParams.delete('access_token')
          newParams.delete('currency')
          navigate(`?${newParams.toString()}`, { replace: true })
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLogging(false)
        }, 500)
      })
  }, [agency_id, session_token])

  return (
    <Connector>
      <Stack
        w="100%"
        color="white"
        backgroundColor={bgColorApp}
        gap={3}
        position="relative"
        overflowX="hidden"
        minHeight="100vh"
      >
        <ErrorHandlerWrapper />
        {/* <BreadcrumbRocket /> */}
        <WalletBalanceSubscription />
        {isLogged && !isLogging && <>{children}</>}
      </Stack>
    </Connector>
  )
}
