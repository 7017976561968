import React from 'react';
import PatternContainer from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternContainer.tsx';
import PatternBoard from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternBoard.tsx';
import PatternMultiplier from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern/PatternMultiplier.tsx';
import { StackProps } from '@chakra-ui/react';

type LotteryPatternProps = StackProps

const LotteryPattern = ({...rest}: LotteryPatternProps) => {
  return (
    <PatternContainer
      {...rest}
    >
      <PatternBoard />
      <PatternMultiplier value={1000} />
    </PatternContainer>
  )
}

export default LotteryPattern;
