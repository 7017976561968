import React from 'react'
import GameplayStage from '@/bingo/components/GameplayStage'
import FormSection from '@/bingo/components/FormSection'
import { Stack } from '@chakra-ui/react'
import AuthGame from '@/components/rocket/AuthGame'
import OrdersSubscription from '@/components/rocket/Subcription/OrdersSubscription'
import ConfigRoundSubscription from '@/bingo/components/Subcription/ConfigRoundSubscription'
import GameRoundSubscription from '@/bingo/components/Subcription/GameRoundSubscription'

export default function MainGameBingo() {
  return (
    <AuthGame>
      <GameRoundSubscription />
      <ConfigRoundSubscription />
      <OrdersSubscription />
      <Stack height="100vh" gap="0" overflow="auto">
        <GameplayStage />
        <FormSection />
      </Stack>
    </AuthGame>
  )
}
