import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react'
import GroupName from '@/bingo/components/FormSection/EditTicket/CurrentTicket/GroupName.tsx'
import TicketCell from '@/bingo/components/FormSection/EditTicket/CurrentTicket/TicketCell.tsx'
import { BingoGroup } from '@/bingo/types/bingo.ts'

type GroupColumnProps = BoxProps & {
  group: BingoGroup,
  isActive?: boolean,
  data?: number[],
  showData?: boolean,
}

const NumbersRenderer = ({data}: {data?: number[]}) => {
  if (!data || data.length === 0) {
    return (
      <>
        <TicketCell />
        <TicketCell />
        <TicketCell />
      </>
    )
  }

  return (
    <>
      {data.map((item) => (
        <TicketCell value={item} key={item} />
      ))}
    </>
  )
}

const GroupColumn = ({group, data, isActive, showData = true, ...rest}: GroupColumnProps) => {
  return (
    <Box
      width="20%"
      minWidth="20%"
      position="relative"
      userSelect="none"
      _before={!isActive ? {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: 'rgba(0, 0, 0, 0.45)'
      } : undefined}
      {...rest}
    >
      <GroupName group={group} />
      {!showData ? <></> : <NumbersRenderer data={data} />}
    </Box>
  )
}

export default GroupColumn;
