import React from 'react';
import { Image, ImageProps } from '@chakra-ui/react'

type RocketIconCurrencyProps = ImageProps

const RocketIconCurrency = ({...rest}: RocketIconCurrencyProps) => {
  return (
    <Image
      src="/rocket/images/icon-dollar-coin.png"
      width="17.5px"
      minWidth="17.5px"
      aspectRatio="1/1"
      alignSelf="center"
      {...rest}
    />
  )
}

export default RocketIconCurrency;
