import useGameId from '@/hooks/useGameId.ts'
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Link,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from '@chakra-ui/react'
import { useAppSelector } from '@store'
import { selectLastGame } from '@store/modules/game.slice'
import React, { useState } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { MdHexagon } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { textGrayWhite } from '../../../const/color.ts'
import { GameUrlMap } from '../../../const/game-type.ts'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import { LotteryHashHBP } from '../../banker-player/LotteryHash.tsx'
import CopyAll from '../../common/CopyAll'
import { CopyBtn } from '../../common/CopyBtn.tsx'
import { LotteryHashHLUCKY } from '../../lucky/LotteryHash.tsx'
import { LotteryHashHNIUNIU } from '../../niuniu/LotteryHash.tsx'
import { LotteryHash } from '../LotteryHash.tsx'

const GamePlayAndVerification = () => {
  const { t, i18n } = useCustomTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const paramValue = queryParams.get('param')
  const [lastResult, setLastResult] = useState(useAppSelector(selectLastGame))
  const gameID = useGameId()
  const currentLanguage = i18n.language

  return (
    <Stack height="80vh" overflow="hidden" borderRadius="12px 12px 0 0">
      <Stack
        backgroundImage="/images/bg3.webp"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        py={2}
        px={5}
        pb={7}
        borderRadius="12px 12px 0 0"
      >
        <Flex color="white" pr={4} justifyContent="space-between">
          <Center>
            <Text align="left" color="black" fontWeight={700} fontSize={21} mt={2} lineHeight={1.2}>
              {t('hashLottery')}
            </Text>
          </Center>
          <Image src="/images/tau.webp" alt="" className="w-[74px] h-[68px] mt-[2px]" />
        </Flex>
      </Stack>
      <Stack></Stack>
      <Tabs defaultIndex={paramValue === 'Gameplay' ? 0 : 1}>
        <Center mt={-10}>
          <TabList
            backgroundColor="#4a4a4e"
            border="none"
            borderRadius="30px"
            className="w-full ml-5 mr-4 p-1 pb-[4px] h-[42px]"
          >
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0 !py-0"
            >
              <Text marginTop="4px">{t('gameplay')}</Text>
            </Tab>
            <Tab
              borderRadius={30}
              w="50%"
              fontWeight={600}
              color="gray.300"
              _selected={{ color: '#000', bg: 'white', width: '50%' }}
              className="!mb-0"
            >
              <Text marginTop="4px">{t('verify')}</Text>
            </Tab>
          </TabList>
        </Center>
        <TabPanels height="calc(80vh - 132px)" overflowY="auto">
          <TabPanel>
            <Stack>
              <Flex>
                <MdHexagon
                  color="c3fb04"
                  style={{
                    rotate: '20deg',
                    display: 'block',
                    float: 'left',
                    marginTop: '7px',
                    marginRight: 3,
                  }}
                />
                <Text color="#fff" fontSize={20} fontWeight={700} pl={1}>
                  {t('gamePlay.lotteryResultHashQuestion')}
                </Text>
              </Flex>

              <Stack mt={1}>
                <Image src={currentLanguage === 'zh' ? '/images/hash-mechanism-zh.webp' : '/images/hash-mechanism.webp'} alt="" w={'100%'} />
              </Stack>

              <Stack mt={2}>
                <Text color="#fff" fontSize={14}>
                  {t('gamePlay.description1')}
                  <Tag size="md" variant="outline" color="#c7fb15">
                    {t('gamePlay.lotteryResultHash')}
                  </Tag>
                  .<br />
                  {t('gamePlay.description2')} &nbsp;
                  <Tag size="md" variant="outline" color="#fff">
                    {t('gamePlay.prizeHash')}
                  </Tag>{' '}
                  +&nbsp;
                  <Tag size="md" variant="outline" color="#fff">
                    {t('gamePlay.serverSeed')}
                  </Tag>{' '}
                  +&nbsp;
                  <Tag size="md" variant="outline" color="#fff">
                    {t('gamePlay.description3')}
                  </Tag>{' '}
                  &nbsp; {t('gamePlay.description4')}
                  <br />
                </Text>
              </Stack>
              <Stack mt={4}>
                <Flex>
                  <MdHexagon
                    color="c3fb04"
                    style={{
                      rotate: '20deg',
                      display: 'block',
                      float: 'left',
                      marginTop: '7px',
                      marginRight: 3,
                    }}
                  />
                  <Text color="#fff" fontSize={20} fontWeight={700} pl={1}>
                    {t('gamePlay.fairness')}
                  </Text>
                </Flex>
                <Text color="#fff" fontSize={14}>
                  {t('gamePlay.sha256')}
                </Text>
                <Text color="#fff" fontSize={14}>
                  {t('gamePlay.sha-description')}
                </Text>
                <Text color="#fff" fontSize={14}>
                  {t('gamePlay.sha-description1')}
                </Text>

                <Flex mt={4}>
                  <MdHexagon
                    color="c3fb04"
                    style={{
                      rotate: '20deg',
                      display: 'block',
                      float: 'left',
                      marginTop: '7px',
                      marginRight: 3,
                    }}
                  />
                  <Text color="#fff" fontSize={20} fontWeight={700} pl={1}>
                    {t('gamePlay.fairness-how')}
                  </Text>
                </Flex>
                <Text color="#fff" fontSize={14}>
                  <b>{t('gamePlay.step1')}</b>
                  <br />
                  {t('common.click')} <Tag size="sm">{t('common.verify')}</Tag> {t('gamePlay.step1-1')}{' '}
                  <Tag size="sm">{t('common.copyAll')}</Tag> {t('gamePlay.step1-2')}
                  <br />
                  <Box p={4}>
                    <Image
                      src={currentLanguage === 'zh' ? `/images/gameplay${gameID}-zh.webp`: `/images/gameplay${gameID}.webp`}
                      borderRadius={10}
                      border={'0.5px solid #999'}
                      alt=""
                    />
                  </Box>
                </Text>
                <Text color="#fff" fontSize={14}>
                  <b>{t('gamePlay.step2')}</b>
                  <br />
                  {t('gamePlay.step2-1')} <span style={{ color: '#c3fb04' }}>{t('gamePlay.step2-2')}</span>
                  <span>{t('gamePlay.step2-5')}</span>
                  <br />
                  {t('gamePlay.step2-3')}
                  <br />
                  <a
                    href="https://emn178.github.io/online-tools/sha256.html"
                    target="_blank"
                    style={{ color: '#c3fb04' }}
                  >
                    https://emn178.github.io/online-tools/sha256.html
                  </a>
                  <br />
                  {t('gamePlay.step2-4')}
                </Text>
                <Text color="#fff" fontSize={14}>
                <b>{t('gamePlay.step3')}</b>
                  {/* <b> {t('gamePlay.step2-3')}</b> */}
                  <br />
                  {t('gamePlay.step3-1')}
                </Text>

                <Box p={4}>
                  <Image src="/images/verify-3-3rdsite.webp" borderRadius={10} border={'0.5px solid #999'} alt="" />
                </Box>
                <Box p={4}>
                  <Image src={currentLanguage === 'zh' ? '/images/verify-4-enter-site-zh.webp' : '/images/verify-4-enter-site.webp'} borderRadius={10} border={'0.5px solid #999'} alt="" />
                </Box>
                <Text color="#fff" fontSize={14} pb={10}>
                  {t('gamePlay.step3-2')} <b>{t('gamePlay.step3-3')}</b> {t('gamePlay.step3-4')}
                  <b>{t('gamePlay.step3-5')}</b> {t('gamePlay.step3-6')}
                </Text>
              </Stack>
            </Stack>
          </TabPanel>
          <TabPanel>
            <Stack gap={4}>
              <Box position="relative">
                <Image width="100%" src={'/images/Panel-light.svg'} alt="" />
                <Text
                  position="absolute"
                  top={'50%'}
                  left={4}
                  transform="translateY(-50%)"
                  // left="0"
                  // padding={'4px 14px 2px 14px'}
                  fontSize="14px"
                  color="#fff"
                >
                  {t('lastRoundHash')}
                </Text>
              </Box>

              <Stack backgroundColor="#252527" p={4} mt={-4} borderRadius={8} borderTopRadius={0} fontSize={13}>
                <Stack color="white" mt={2}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
                      {t('gamePlay.prizeHash')}
                    </Text>
                    <CopyBtn text={lastResult?.lotteryHash} />
                  </Flex>
                  {lastResult?.result && (
                    <GameResultDisplay gameID={gameID} lastResult={lastResult} textGrayWhite={textGrayWhite} t={t} />
                  )}
                </Stack>

                <Stack color="white" mt={3}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
                      {t('gamePlay.serverSeed')}
                    </Text>
                    <CopyBtn text={lastResult?.serverSeed} />
                  </Flex>
                  <Text>{lastResult?.serverSeed}</Text>
                </Stack>

                <Stack color="white" mt={3}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text p={'2px 8px'} background="#c7fb14" color="black" fontSize={13} fontWeight={500} h={'22px'}>
                      {t('gamePlay.publicChainHash')}
                    </Text>
                    <Link href={`https://etherscan.io/tx/${lastResult?.publicChainHash}`} isExternal>
                      <Stack direction={'row'} alignItems={'center'} color={'#ccc'}>
                        <Text>{t('gamePlay.etherscan')}</Text>
                        <FiExternalLink size={14} style={{ display: 'inline' }} />
                      </Stack>
                    </Link>
                    <CopyBtn text={lastResult?.publicChainHash} />
                  </Flex>
                  <Text>{lastResult?.publicChainHash}</Text>
                </Stack>
                <CopyAll
                  cpyTxt={`${lastResult?.lotteryHash}\n${lastResult?.serverSeed}\n${lastResult?.publicChainHash}`}
                />
              </Stack>
              <Stack color="white" backgroundColor="#252527" p={4} borderRadius={8} fontSize={13}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text color="#c7fb14" fontWeight={500}>
                    {t('hashResult')}
                  </Text>
                  <CopyBtn text={lastResult?.lotteryResultHash} />
                </Flex>
                <Text>{lastResult?.lotteryResultHash}</Text>
              </Stack>
              <Button
                aria-label="buttonVerifyWallet"
                aria-labelledby="buttonVerifyWallet"
                w="100%"
                backgroundColor="#c7fb14"
                borderRadius="8px"
              >
                <Stack direction="row">
                  <Link
                    href={`https://emn178.github.io/online-tools/sha256.html?input=${lastResult?.lotteryHash}%0A${lastResult?.serverSeed}%0A${lastResult?.publicChainHash}&input_type=utf-8&output_type=hex&hmac_input_type=utf-8`}
                    isExternal
                  >
                    {t('buttonVerifyWallet')} <FiExternalLink style={{ display: 'inline' }} />
                  </Link>
                </Stack>
              </Button>
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

function GameResultDisplay({ gameID, lastResult, textGrayWhite, t }) {
  if (gameID === GameUrlMap.H_LUCKY) {
    return (
      <LotteryHashHLUCKY text={lastResult?.lotteryHash}>
        &nbsp; <span style={{ color: '#c7fb14' }}>{`(${lastResult?.result})`}</span>
      </LotteryHashHLUCKY>
    )
  }

  if (gameID === GameUrlMap.H_BP) {
    return (
      <Flex color={textGrayWhite}>
        <LotteryHashHBP
          hash={lastResult?.lotteryHash}
          showEllipsis={true}
          result={
            <span style={{ color: '#c7fb14' }}>
              &nbsp;&nbsp;&nbsp;&nbsp; ({lastResult?.result ? t(`${lastResult?.result}`) : '--'})
            </span>
          }
        />
      </Flex>
    )
  }

  if (gameID === GameUrlMap.H_NIUNIU) {
    return (
      <Flex color={textGrayWhite}>
        <LotteryHashHNIUNIU
          hash={lastResult?.lotteryHash}
          showEllipsis={true}
          result={
            <span style={{ color: '#c7fb14' }}>
              &nbsp;&nbsp;&nbsp;&nbsp; ({lastResult?.result ? t(`${lastResult?.result}`) : '--'})
            </span>
          }
        />
      </Flex>
    )
  }

  return (
    <Text>
      <LotteryHash text={lastResult?.lotteryHash}>
        &nbsp; <span style={{ color: '#c7fb14' }}>{`(${lastResult?.result})`}</span>
      </LotteryHash>
    </Text>
  )
}

export default GamePlayAndVerification
