import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppSelector } from '@/redux/store'
import { selectConfigRound } from '@/redux/store/modules/rocketTshow'
import { Flex, Stack, Text } from '@chakra-ui/react'
import { f } from 'fintech-number'
import React from 'react'

export default function RoundConfig() {
  const roundConfig = useAppSelector(selectConfigRound)
  const { t } = useCustomTranslation()

  return (
    <Flex width="100%" gap={1} justifyContent="space-between" background="#17111133" py={2} px={2} mt={-3}>
      <Stack gap={1.5}>
        <Text fontSize={14} lineHeight={1}>
          {t('betLimit')}:
        </Text>
        <Text fontSize={14} lineHeight={1}>
          {f(+roundConfig?.minBetAmount, {
            decimal: 2,
          })}
          -{' '}
          {f(+roundConfig?.maxBetAmount, {
            decimal: 2,
          })}
        </Text>
      </Stack>
      <Stack gap={1.5}>
        <Text fontSize={14} lineHeight={1}>
          {t('maxRewardAltitude')}:
        </Text>
        <Text fontSize={14} lineHeight={1}>
          {f(+roundConfig?.maxMultiple, {
            decimal: 2,
          })}
          x
        </Text>
      </Stack>
      <Stack gap={1.5}>
        <Text fontSize={14} lineHeight={1}>
          {t('maxProfit')}:
        </Text>
        <Text fontSize={14} lineHeight={1}>
          {f(+roundConfig?.maxBetAmount, {
            decimal: 2,
          })}
        </Text>
      </Stack>
    </Flex>
  )
}
