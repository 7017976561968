import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

type RoundIdProps = BoxProps & {
  value: string | number,
}

const RoundId = ({value, ...rest}: RoundIdProps) => {
  return (
    <Box
      position="absolute"
      top="5.5px"
      left="50%"
      transform="translateX(-50%)"
      backgroundColor="#dd5243"
      color="#fff0d3"
      fontSize="16px"
      fontWeight="600"
      textAlign="center"
      lineHeight="1"
      padding="3px 7px"
      borderBottomRadius="5px"
      {...rest}
    >
      {value}
    </Box>
  )
}

export default RoundId;
