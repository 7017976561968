import { BetType } from '@/@generated/gql/graphql-hash'

// export const LIST_SUIT_HILO = ['Tiles', 'Clubs', 'Hearts', 'Pikes']
export const LIST_SUIT_HILO = ['D', 'C', 'H', 'S']
export const LIST_VALUE_HILO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13']
export const LIST_TYPE_BET_HILO = ['SKIP', BetType.GreaterThanEqual, BetType.LessThanEqual, BetType.LessThan, BetType.GreaterThan, BetType.Equal]

export function getRandomValue<T>(arr: T[]): T {
  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}

export const getTypeBettingFromCard = (card: HiloCardType) => {
  if (!card) return null
  const idx = LIST_VALUE_HILO.indexOf(card.value)
  if (idx < 1)
    return {
      big: BetType.GreaterThan,
      small: BetType.Equal,
    }
  if (idx > 11)
    return {
      big: BetType.Equal,
      small: BetType.LessThan,
    }
  return {
    big: BetType.GreaterThanEqual,
    small: BetType.LessThanEqual,
  }
}

export const getPositionCard = (card: HiloCardType, bgSize: number) => {
  // if (!card) return null
  const w = bgSize / 13 // bgSize = backgroundSize
  const h = (w * 164) / 125 // w/h = 125/164
  return {
    x: w * LIST_VALUE_HILO.indexOf(card.value), // px
    y: h * LIST_SUIT_HILO.indexOf(card.suit), // px
  }
}
