import { Flex, Stack, Text, Image } from '@chakra-ui/react'
import React, { useEffect, useMemo } from 'react'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import BettingTable from '../UI/Table.tsx'
import useGameId from '@/hooks/useGameId.ts'
import { Topics } from '@/redux/store/common/topic.ts'
import useSubscription from '@/hooks/useSubscription.tsx'
import { useAppDispatch, useAppSelector } from '@/redux/store/index.ts'
import {
  rocketTshowActions,
  getListCurrentLives,
  ROUND_STATE,
  selectGameRound,
  selectListCurrentLives,
} from '@/redux/store/modules/rocketTshow.ts'
import { getSymbolFromCurrency } from '@/const/currency.tsx'
import { f } from 'fintech-number'
import { GameID } from '@/const/game-type.ts'

export default function ListCurrentLive() {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  const _message = useSubscription(Topics.getPlayerBettingInRound(gameId), {
    qos: 1
  })
  const message = _message?.message?.message
  const gameRound = useAppSelector(selectGameRound)
  const listPlayers = useAppSelector(selectListCurrentLives)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      getListCurrentLives({
        game_id: gameId,
      }),
    )
  }, [])
  useEffect(() => {
    if (gameRound.state === ROUND_STATE.WAITING) dispatch(rocketTshowActions.updateListCurrentLives([]))
    if (gameRound.state === ROUND_STATE.END) {
      const listUpdated = listPlayers.map((item) => {
        if (item?.esc_multiplier === '0') {
          return {
            ...item,
            esc_multiplier: gameId === GameID.H_ROCKET ?  "Did'n escape" : 'No win',
            reward: '0.00',
          }
        }
        return item
      })
      dispatch(rocketTshowActions.updateListCurrentLives(listUpdated))
    }
  }, [gameRound.state])

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      const itemToUpdate = listPlayers.find((item) => item.id === data?.id)
      if (!itemToUpdate) {
        dispatch(rocketTshowActions.updateListCurrentLives([data, ...listPlayers]))
      } else {
        const updatedItems = listPlayers.map((item) => (item?.id === data?.id ? { ...item, ...data } : item))
        dispatch(rocketTshowActions.updateListCurrentLives(updatedItems))
      }
    } catch (error) {
      console.warn('GetPlayerBettingInRoundSubcription error: ', error)
    }
  }, [message])

  const columns = useMemo(
    () => [
      {
        title: t('player'),
        dataIndex: 'player_name',
        key: 'player_name',
        render: (row: any) => <>{row.player_name}</>,
      },
      {
        title: t('betAmount'),
        dataIndex: 'amount',
        key: 'amount',
        render: (row: any) => (
          <Stack alignItems="center" flexDirection="row" justifyContent="center" gap={1}>
            <Text>
              {f(row.amount, {
                decimal: 2,
              })}
            </Text>
            <Image src={getSymbolFromCurrency(row.currency)} className={`w-3 h-3 rounded-lg`} />
          </Stack>
        ),
      },
      {
        title: gameId === GameID.H_ROCKET ? t('escapeAltitude') : t('cashoutRatio'),
        dataIndex: 'esc_multiplier',
        key: 'esc_multiplier',
        align: 'center',
        render: (row: any) => (
          <Flex gap={1} alignItems="center" justifyContent={'center'}>
            {row.esc_multiplier !== '0' ? (
              <>
                <Text position="relative" bottom="-1px">
                  {+row?.esc_multiplier > 0
                    ? `${f(row?.esc_multiplier, {
                        decimal: 2,
                      })}x`
                    : row?.esc_multiplier}
                </Text>
              </>
            ) : (
              <>--</>
            )}
          </Flex>
        ),
      },
      {
        title: t('reward'),
        dataIndex: 'reward',
        key: 'reward',
        align: 'center',
        render: (row: any) => (
          <Stack alignItems="center" flexDirection="row" justifyContent="center" gap={1}>
            {row.reward !== '0' ? (
              <>
                <Text>
                  {f(row.reward, {
                    decimal: 2,
                  })}
                </Text>
                <Image src={getSymbolFromCurrency(row.currency)} className={`w-3 h-3 rounded-lg`} />
              </>
            ) : (
              <>--</>
            )}
          </Stack>
        ),
      },
    ],
    [t],
  )

  return (
    <Stack w="100%" overflow="hidden" gap={0}>
      <BettingTable columns={columns} datasource={listPlayers} />
    </Stack>
  )
}
