import {
  FilterInput,
  PlinkoBetInput,
  PlinkoMultiplier,
  PlinkoOrder,
  PrePublicPlinkoRound,
} from '@/@generated/gql/graphql-hash'
import { Configs } from '@/const/configs'
import { RiskLevel, riskLevelToNumber } from '@/pages/plinko/config'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { get, isEmpty } from 'lodash'
import { RootState } from '..'
import { gqlClient } from '../services/apollo-client'
import {
  createPlinkoOrderQuery,
  CreatePlinkoOrderQueryResp,
  createPlinkoRoundQuery,
  CreatePlinkoRoundQueryResp,
  getPlinkoMultiplierQuery,
  GetPlinkoMultiplierQueryResp,
  getPlinkoOrderHistoryQuery,
  GetPlinkoOrderHistoryQueryResp,
} from '../services/queries'
import { selectAgencyId, selectUserId, selectUserName } from './auth.slice'
import { createThunk } from './common'
import { plinkoPreferenceActions } from './plinkoPreference.slice'
import walletSlice, { selectActiveWallet } from './wallet.slice'

export interface PlinkoBetResult {
  result: string // LRLRLLLRRLR
  id: string
  rate: number
  amount: string
  currency: string
  createdAt: string
  lotteryHash: string
}

export interface PlinkoState {
  newBalls: PlinkoBetResult[]
  multipliers: PlinkoMultiplier[]
  newRound?: PrePublicPlinkoRound
  round?: PrePublicPlinkoRound
  orderResult?: PlinkoOrder
  orderHistory: PlinkoOrder[]
  totalOrder: number
  lastOrder?: PlinkoOrder
  lastOrders: PlinkoOrder[]
  isPreventing: boolean
  isConguration: boolean
}

const initialState: PlinkoState = {
  newBalls: [],
  multipliers: [],
  orderHistory: [],
  lastOrders: [],
  totalOrder: 0,
  isPreventing: false,
  isConguration: false,
}

export const getPlinkoMultipliers = createThunk('plinko/getPlinkoMultipliers', async (_: void, __) => {
  const resp = await gqlClient.query<GetPlinkoMultiplierQueryResp>({
    query: getPlinkoMultiplierQuery,
  })
  return resp.data.plinkoMultiplier
})

const PAGE_SIZE = Configs.pageSize
export const getPlinkoOrderHistory = createThunk('plinko/getPlinkoOrderHistory', async (page: number, __) => {
  const input: FilterInput = {
    game_id: import.meta.env.VITE_GAME_ID_PLINKO,
    page: page,
    limit: PAGE_SIZE,
  }
  const resp = await gqlClient.query<GetPlinkoOrderHistoryQueryResp>({
    query: getPlinkoOrderHistoryQuery,
    variables: { input },
  })
  return resp.data.plinkoOrderHistory
})

export const getPlinkoLastOrder = createThunk('plinko/getPlinkoLastOrder', async (_: void, __) => {
  const input: FilterInput = {
    game_id: import.meta.env.VITE_GAME_ID_PLINKO,
    page: 1,
    limit: 1,
  }
  const resp = await gqlClient.query<GetPlinkoOrderHistoryQueryResp>({
    query: getPlinkoOrderHistoryQuery,
    variables: { input },
  })
  return get(resp, 'data.plinkoOrderHistory.data.0')
})

export const createPlinkoRound = createThunk('plinko/createPlinkoRound', async (_: void, __) => {
  const resp = await gqlClient.mutate<CreatePlinkoRoundQueryResp>({ mutation: createPlinkoRoundQuery })
  return resp.data!.createPlinkoRound
})
export const createPlinkoOrder = createThunk('plinko/createPlinkoOrder', async (_: void, thunkApi) => {
  const { getState, dispatch } = thunkApi
  const state = getState() as RootState
  const lines = selectPlinkoRows(state)
  const risk = selectPlinkoRiskLevel(state)
  const ballCount = selectPlinkoBallCount(state)
  const amount = selectPlinkoBetAmount(state)
  const round = selectPlinkoRound(state)
  const wallet = selectActiveWallet(state) || {}

  const orderRequest: PlinkoBetInput = {
    id: round?.id || '',
    agency_id: selectAgencyId(state) || '',
    amount,
    balls: ballCount,
    currency: wallet.currency,
    player_id: selectUserId(state) || '',
    player_name: selectUserName(state) || '',
    risk_level: riskLevelToNumber(risk),
    rows: lines,
    wallet_id: wallet.id,
  }

  const resp = await gqlClient.mutate<CreatePlinkoOrderQueryResp>({
    mutation: createPlinkoOrderQuery,
    variables: { input: orderRequest },
  })
  const order = resp.data!.createPlinkoOrder

  dispatch(createPlinkoRound())

  const totalAmount = Number(amount) * ballCount
  dispatch(
    walletSlice.actions.walletUpdated({
      id: wallet.id,
      currency: wallet.currency,
      balance: (wallet.amount - totalAmount).toString(),
    }),
  )
  return order
})

export function mapPlinkoOrderToBetResult(order: PlinkoOrder): PlinkoBetResult[] {
  if (isEmpty(order?.ball_paths)) return []
  const rates = order.multipliers
  return order.ball_paths
    .map((path) => path.slice(0, order.rows))
    .map((path, i) => ({
      result: path,
      id: order.id + path + Math.random(),
      rate: +rates[i],
      amount: order.amount,
      currency: order.currency,
      createdAt: order.created_at,
      lotteryHash: order.server_seed,
    }))
}

export const plinkoSlice = createSlice({
  name: 'plinko',
  initialState,
  reducers: {
    congurateSuccess: (state, action: PayloadAction) => {
      state.isConguration = false
    },
    preventingUpdated: (state, action: PayloadAction<boolean>) => {
      if (state.isPreventing == action.payload) return
      state.isPreventing = action.payload
      if (!state.isPreventing && state.lastOrder) {
        state.lastOrders = [state.lastOrder, ...state.lastOrders]
      }
      if (!state.isPreventing) {
        const { reward, balls, amount } = state.lastOrder || {}
        const isWin = +reward > (balls || 0) * +amount
        if (isWin) {
          state.isConguration = true
        }
        state.newBalls = []
        if (state.newRound) {
          state.round = state.newRound
          state.newRound = undefined
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPlinkoOrder.pending, (state, _) => {
        state.isConguration = false
      })
      .addCase(createPlinkoOrder.fulfilled, (state, action) => {
        state.orderResult = action.payload
        state.lastOrder = action.payload
        state.newBalls = mapPlinkoOrderToBetResult(action.payload)
      })
      .addCase(createPlinkoRound.fulfilled, (state, action) => {
        if (state.isPreventing) {
          state.newRound = action.payload
        } else {
          state.newRound = undefined
          state.round = action.payload
        }
      })
      .addCase(getPlinkoMultipliers.fulfilled, (state, action) => {
        state.multipliers = action.payload
      })
      .addCase(getPlinkoOrderHistory.fulfilled, (state, action) => {
        state.orderHistory = action.payload.data
        state.totalOrder = action.payload.total
        if (action.meta.arg <= 1) {
          state.lastOrders = action.payload.data
          state.lastOrder = get(action, 'payload.data.0')
        }
      })
      .addCase(getPlinkoLastOrder.fulfilled, (state, action) => {
        state.lastOrder = action.payload
      })
  },
})

export const selectPlinkoRiskLevel = (state: RootState) => state.plinkoPreference.riskLevel
export const selectPlinkoBetAmount = (state: RootState) => state.plinkoPreference.betAmount
export const selectPlinkoRows = (state: RootState) => state.plinkoPreference.rows
export const selectPlinkoBallCount = (state: RootState) => state.plinkoPreference.numOfBalls
export const selectPlinkoMuted = (state: RootState) => !state.sound.isMuted
export const selectPlinkoNewBalls = (state: RootState) => state.plinko.newBalls
export const selectPlinkoTotalOrder = (state: RootState) => state.plinko.totalOrder
export const selectPlinkoIsPreventing = (state: RootState) => state.plinko.isPreventing
export const selectPlinkoIsConguration = (state: RootState) => state.plinko.isConguration
export const selectPlinkoRound = (state: RootState) => state.plinko.round
export const selectPlinkoMultipliers = (state: RootState) => state.plinko.multipliers
export const selectPlinkoWinRates = (rows: number, risk: RiskLevel) =>
  createSelector([selectPlinkoMultipliers], (multipliers) => {
    const riskNumber = riskLevelToNumber(risk)
    return multipliers.find((mul) => mul.rows == rows && mul.risk == riskNumber)
  })
export const selectPlinkoOrderHistory = (state: RootState) => state.plinko.orderHistory
export const selectPlinkoLastOrders = (state: RootState) => state.plinko.lastOrders
export const selectPlinkoLastOrder = (state: RootState) => state.plinko.lastOrder

export const plinkoActions = {
  getPlinkoMultipliers,
  getPlinkoOrderHistory,
  createPlinkoRound,
  createPlinkoOrder,
  getPlinkoLastOrder,
  ...plinkoPreferenceActions,
  ...plinkoSlice.actions,
}

export default plinkoSlice
