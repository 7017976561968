import React, { useState } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import Chart from 'react-apexcharts'

type TrendChartProps = {
  crashPoints: number[]
}

const crashPointsFilter = [10, 20, 30, 50, 100]

const TrendChart = ({crashPoints}: TrendChartProps) => {
  const [maxCrashPoints, setMaxCrashPoints] = useState<number>(50)
  const crashPointsFiltered = crashPoints.filter((_, index) => index < maxCrashPoints).reverse()
  const lastGame = crashPoints[0]

  const chartOptions = {
    chart: {
      type: "line",
      background: "#1b243266", // Match the dark background color
      borderRadius: "20px",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false // Remove the toolbar
      }
    },
    stroke: {
      curve: "smooth", // Smooth lines
      width: 2 // Thickness of the line
    },
    grid: {
      borderColor: "#444", // Subtle grid color
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        color: "#444"
      },
      axisBorder: {
        color: "#444"
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: "#AAA" // Y-axis label color
        },
      },
      logarithmic: true, // For exponential-looking data
      min: 1 // Ensure y-axis starts at 1
    },
    tooltip: {
      theme: "dark", // Tooltip theme
      y: {
        formatter: (value: string) => {
          return `Crash Point: ${value}`
        },
      },
    },
    colors: ["#FFD700"], // Line color (gold-like)
    markers: {
      size: 0 // Hide markers
    },
  }

  const chartSeries = [
    {
      name: "",
      data: [...crashPointsFiltered] // Example data points
    }
  ]

  return (
    <Box>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height="180px"
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Text
            color="#98acd0"
            marginRight="5px"
          >
            Last game
          </Text>
          <Text
            color="#f5b638"
          >
            {lastGame}
          </Text>
        </Flex>
        <Flex>
          {crashPointsFilter.map((value) => {
            return (
              <Button
                onClick={() => setMaxCrashPoints(value)}
                backgroundColor={maxCrashPoints === value ? '#272f3f' : 'transparent'}
                fontSize="14px"
                padding="5px 7px"
                color="#98acd0"
                lineHeight="1"
                fontWeight="400"
                minWidth="auto"
                minHeight="auto"
                height="auto"
                _hover={{}}
                _active={{}}
              >
                {value}
              </Button>
            )
          })}
        </Flex>
      </Flex>
    </Box>
  )
}

export default TrendChart;
