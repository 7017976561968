import React from 'react';
import { Box } from '@chakra-ui/react';

type PatternMultiplierProps = {
  value: string | number,
}

const PatternMultiplier = ({value}: PatternMultiplierProps) => {
  return (
    <Box
      color="#fff8eb"
      fontSize="7.5px"
      fontWeight="600"
      lineHeight="1"
      textAlign="center"
      userSelect="none"
    >
      x{value}
    </Box>
  )
}

export default PatternMultiplier;
