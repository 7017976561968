import { Stack } from '@chakra-ui/react'
import React from 'react'
import FlipList from './FlipList'
import CardTable from './CardTable'

export default function GamePlayHilo() {
  return (
    <Stack className="bg-[#25242B]">
      <FlipList />
      <CardTable />
    </Stack>
  )
}
