import { PlinkoOrder } from '@/@generated/gql/graphql-hash'
import { AppDrawer } from '@/components/common/AppDrawer'
import Chess from '@/components/common/Chess'
import { CopyBtn } from '@/components/common/CopyBtn'
import { itemHash1, textGrayDrawer, textGrayWhite } from '@/const/color'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice'
import {
  plinkoActions,
  selectPlinkoLastOrders,
  selectPlinkoOrderHistory,
  selectPlinkoTotalOrder,
} from '@/redux/store/modules/plinko.slice'
import { prettyPrintWallet } from '@/utils/number'
import { Box, Flex, Image, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import dayjs from 'dayjs'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import React, { useEffect, useState } from 'react'
import { BetDetail } from './BettingDetail'

interface BettingHistoryProps {
  noPaging?: boolean
  className?: string
}

export function BettingHistory({ noPaging = false, className = '' }: BettingHistoryProps) {
  const { t } = useCustomTranslation()
  const dispatch = useAppDispatch()
  const limit = 10
  const [page, setPage] = useState(1)
  const orderHistory = useAppSelector(selectPlinkoOrderHistory)
  const lastOrders = useAppSelector(selectPlinkoLastOrders)
  const history = noPaging ? lastOrders : orderHistory
  const total = useAppSelector(selectPlinkoTotalOrder)
  const isLoading = useAppSelector(selectIsLoadingAction(plinkoActions.getPlinkoOrderHistory.pending))

  useEffect(() => {
    dispatch(plinkoActions.getPlinkoOrderHistory(page))
  }, [page])

  const changePage = (page: number) => {
    setPage(page)
  }

  return (
    <Box className={className ? className : 'p-4'}>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Spinner />
        </Box>
      )}
      <Stack
        sx={{
          opacity: isLoading ? '0.3' : '1',
        }}
      >
        {history?.length > 0 ? (
          history.map((item) => <BetItem key={item.id} order={item} />)
        ) : (
          <Flex
            w="100%"
            height="200px"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            color="#3d3d43"
            gap={'11px'}
          >
            <Box>
              <Image w={'40px'} src="/images/norecord.webp" />
            </Box>
            <Box color={'#4b4b4d'} fontSize={'13px'}>
              {t('notification.youHavenorecordsYet')}
            </Box>
          </Flex>
        )}
        {noPaging === false && (
          <Stack alignItems="center">
            {history?.length > 0 && (
              <Flex direction="row" align="center" justify="center" mt={4}>
                <Pagination className="paging" onChange={changePage} current={page} total={total} pageSize={limit} />
              </Flex>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  )
}

interface BetItemProps {
  order: PlinkoOrder
}

function RenderIconBet({ bet }: { bet: string }) {
  return <Chess type={bet} width="28px" height="28px" />
}

function BetItem({ order }: BetItemProps) {
  const { t } = useCustomTranslation()
  const { amount, balls } = order
  const totalAmount = Number(amount) * balls
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box
        onClick={() => {
          onOpen()
        }}
        padding="10px 6px 3px 6px"
        backgroundColor="#232327"
        borderRadius={12}
      >
        <Stack>
          <Flex justifyContent="space-between" alignItems="center" px="5px">
            <Flex alignItems="center" gap={3}>
              <RenderIconBet bet={'PLAYER'} />

              <Stack>
                <Text letterSpacing={1} fontSize={12} textColor={textGrayWhite} fontWeight={500}>
                  {t(import.meta.env.VITE_GAME_ID_PLINKO)}
                </Text>
                <Flex alignItems="flex-end" mt={-2} fontSize={12}>
                  <Flex gap={1} alignItems="flex-end" lineHeight={1}>
                    <Text fontWeight={300} color={textGrayDrawer}>
                      {t('betting')}:
                    </Text>
                    <Text color="white" fontSize="14px" mb="-1px">
                      {totalAmount}
                    </Text>
                    <Text fontSize={10} color={textGrayWhite} lineHeight={1}>
                      ({order.currency})
                    </Text>
                  </Flex>
                </Flex>
              </Stack>
            </Flex>
            <RenderAmountItem order={order} />
          </Flex>
          <hr style={{ borderColor: '#2d2d32' }} />
          <Flex fontSize="0.9rem" justifyContent="space-between" alignItems="center" mb="3px" px="5px">
            <Text fontSize={12} color={'#75757f'} fontWeight={400}>
              {dayjs(order.created_at).format('YYYY-MM-DD hh:mm:ss')}
            </Text>
            <RenderHashShort lotteryHash={order.proof} />
          </Flex>
        </Stack>
      </Box>
      <AppDrawer title={t('titleDetailOrder')} isOpen={isOpen} onClose={onClose} overlay={true}>
        <BetDetail order={order} />
      </AppDrawer>
    </>
  )
}

interface RenderHashShortProps {
  lotteryHash: string
}

function RenderHashShort({ lotteryHash }: RenderHashShortProps) {
  return (
    <Flex color={textGrayWhite} fontSize={12} letterSpacing="1px">
      <Text>...{lotteryHash.slice(-16)}</Text>
      <CopyBtn text={lotteryHash} />
    </Flex>
  )
}

interface RenderAmountItemProps {
  order: PlinkoOrder
}

function RenderAmountItem({ order }: RenderAmountItemProps) {
  const { currency, amount, balls, reward } = order
  const totalAmount = +amount * balls
  const winAmount = +reward - totalAmount
  const sign = winAmount > 0 ? '+' : winAmount < 0 ? '-' : ''

  return (
    <Stack alignItems="center">
      <Text>
        <Flex color={itemHash1} fontWeight={600} alignItems="center" gap={1}>
          <Text color={winAmount >= 0 ? '#B0FC00' : '#ff5959'} fontSize="18px">
            {sign}
            {prettyPrintWallet(Math.abs(winAmount))}
          </Text>
          <Text color={textGrayWhite} fontSize={12} mt="1px" fontWeight={300}>
            {`(${currency})`}
          </Text>
        </Flex>
      </Text>
      <Text mt={-3} fontSize={12} color={textGrayDrawer}>
        {balls} x {prettyPrintWallet(amount)}
      </Text>
    </Stack>
  )
}
