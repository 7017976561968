import RuleGame from '@/components/hash-game/RuleGame'
import SoundSetting from '@/components/hash-game/settings/SoundSetting'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Flex, Stack } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { TfiMenuAlt } from 'react-icons/tfi'
import SelectMenuPopupRocket from './SelectMenuPopup'

interface Option {
  value: string
  label: string
  headingPopup?: string
  content: ReactNode
  image: string
}

export default function ListMenu() {
  const { t } = useCustomTranslation()
  const options1: Option[] = [
    // {
    //   value: 'BettingRecord',
    //   label: 'bettingRecord',
    //   headingPopup: 'bettingListing',
    //   content: (
    //     <Box p={4}>
    //       <ListResult noPaging={false} />
    //     </Box>
    //   ),
    // },
    {
      value: 'RuleGame',
      label: 'ruleGame',
      content: <RuleGame />,
      image: '/images/rockets/ic-note.png',
    },
    {
      value: 'GamePlay',
      label: 'gamePlay',
      content: <RuleGame />,
      image: '/images/rockets/ic-info.png',
    },
    { value: 'SoundSetting', label: 'soundSetting', content: <SoundSetting />, image: '/images/rockets/ic-sound.png' },
  ]

  return (
    <Flex gap={1} alignItems="center" justifyContent="end">
      <SelectMenuPopupRocket options={options1} icon={<TfiMenuAlt color="#c3fb04" fontSize={18} />} />
    </Flex>
  )
}
