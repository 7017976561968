import { Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react';
import FormContainer from '@/bingo/components/FormSection/FormContainer.tsx';
import SubTitle from '@/bingo/components/FormSection/SubTitle.tsx'
import FormFooter from '@/bingo/components/FormSection/FormFooter.tsx'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'
import TicketList from '@/bingo/components/FormSection/OrderConfirmation/TicketList.tsx'
import BingoDrawer from '@/bingo/components/Common/BingoDrawer.tsx'
import EditTicket from '@/bingo/components/FormSection/EditTicket'
import FormGoBack from '@/bingo/components/FormSection/FormGoBack.tsx'
import BingoInput from '@/bingo/components/Common/BingoInput.tsx'
import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import { useAppDispatch, useAppSelector } from '@store'
import { bingoActions, selectBingoTicketsGenerated } from '@/bingo/redux/bingo.slice.ts'

const OrderConfirmation = () => {
  const generatedTickets = useAppSelector(selectBingoTicketsGenerated)
  const editTicketDrawer = useDisclosure()
  const dispatch = useAppDispatch()

  const handleGoBack = () => {
    dispatch(bingoActions.updateCurrentStep('randomTicket'))
  }

  const handleBetOn = () => {
    dispatch(bingoActions.updateCurrentStep('betSuccess'))
  }

  return (
    <>
      <FormContainer
        title="Order Confirmation"
      >
        <Stack
          padding="15px 15px 0"
          position="relative"
          flex="1"
          gap="0"
          overflow="hidden"
        >
          <SubTitle
            marginBottom="10px"
          >
            <Text>
              The maximum bonus: 500,000/Ticket, Selected:5 tickets
            </Text>
          </SubTitle>
          <TicketList
            onEdit={editTicketDrawer.onOpen}
            data={generatedTickets}
          />
        </Stack>
        <FormFooter
          justifyContent="space-between"
        >
          <FormGoBack
            onClick={handleGoBack}
          />
          <BingoInput
            inputProps={{
              defaultValue: 10,
            }}
          />
          <FormButtonBig
            width="130px"
            minHeight="70px"
            height="auto"
            padding="7px 10px"
            display="flex"
            flexDirection="column"
            gap="5px"
            fontSize="18px"
            onClick={handleBetOn}
          >
            Bet on the next period
            <Flex
              background="rgba(255,255,255,0.8)"
              borderRadius="50px"
              padding="3px 6px"
              alignItems="center"
              justifyContent="center"
              gap="5px"
            >
              <BingoCurrencyIcon
                width="15px"
                fontSize="12px"
              />
              <Text
                color="#ff4218"
                fontSize="16px"
              >
                100
              </Text>
            </Flex>
          </FormButtonBig>
        </FormFooter>
      </FormContainer>
      <BingoDrawer
        isOpen={editTicketDrawer.isOpen}
        onClose={editTicketDrawer.onClose}
        title="Edit Ticket"
      >
        <EditTicket />
      </BingoDrawer>
    </>
  )
}

export default OrderConfirmation;
