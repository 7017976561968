import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react';

type InnerBallProps = BoxProps & {
  bg: string,
}

const InnerBall = ({bg}: InnerBallProps) => {
  return (
    <Box
      borderRadius="50px"
      background={bg}
      filter="drop-shadow(0px 0.078rem 0.117rem rgba(0,0,0,0.25))"
      width="100%"
      height="100%"
    />
  )
}

export default InnerBall;
