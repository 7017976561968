import React, { useEffect, useRef, useState } from 'react'
import { useRocketGamePlay } from './hooks/useRocketGamePlay'
import ListCurrentBet from '../ListCurrentBet'
import TopPlayers from './TopPlayers'
import './styles/rocket.scss'
import { CountdownCircle, MultiplierDisplay } from './StageComponent'
import RocketBackground from '../BgAnimation/RocketBackground'
import ListMenu from '../Menu'
import ListPastsOnScreen from './on-screen/ListPastsOnScreen'
import WinningAnimation from './WinningAnimation'
import ListPlayerBettingInRound from './on-screen/ListPlayerBettingInRound'
import { Box, Image, Stack, useDisclosure } from '@chakra-ui/react'
import Stats from '@components/rocket/GamePlay/Stats'
import { useRocketSound } from './hooks/useRocketSound'
import useGameId from '@/hooks/useGameId'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getCrashResultHistory, selectConfigRound } from '@/redux/store/modules/rocketTshow'
import { RocketCountdown } from './CountDown'
import BreadcrumbRocket from '../Breadcrumb'

const Rocket = () => {
  const container = useRef<HTMLDivElement>(null)
  const rocketRef = useRef<HTMLDivElement>(null)
  const rocketElId = 'rocket-box'
  const statsDrawer = useDisclosure()

  const {
    pathRef,
    containerSize,
    gameState,
    gameRound,
    countdown,
    roundMultiplier,
    topCashoutOrder,
    listActiveOrders,
    rewardInfo,
    showWinAnim,
  } = useRocketGamePlay({
    rocketRef,
    containerRef: container,
  })
  useRocketSound(gameState, countdown)

  const gameId = useGameId()
  const dispatch = useAppDispatch()
  const roundConfig = useAppSelector(selectConfigRound)

  useEffect(() => {
    if (gameState === 'ENDED') {
      dispatch(
        getCrashResultHistory({
          game_id: gameId,
        }),
      )
    }
  }, [gameId, gameState, dispatch])

  const multiplier = roundMultiplier.m ?? gameRound.multiplier
  if (gameState !== 'WAITING' && !multiplier) {
    console.log('multiplier', multiplier)
    console.log('gameRound', gameRound)
  }

  return (
    <div ref={container} id="rocket-container" className="relative h-[500px] overflow-hidden">
      <RocketBackground animationDuration={3.5} isAnimating={gameState === 'PLAYING'} />
      <svg
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        viewBox={`0 0 ${containerSize.width} ${containerSize.height}`}
        preserveAspectRatio="none"
      >
        <path
          ref={pathRef}
          stroke="#fff000"
          strokeWidth="3"
          fill="none"
          strokeDasharray="3,5"
          style={{ vectorEffect: 'non-scaling-stroke' }}
        />
      </svg>
      {/* <RocketCountdown countdown={10} duration={15} /> */}
      {gameState === 'WAITING' ? (
        <RocketCountdown countdown={countdown} duration={(roundConfig?.waitingTime ?? 9) - 2} />
      ) : undefined}
      {gameState !== 'WAITING' && <MultiplierDisplay multiplier={multiplier} gameState={gameState} />}
      <div id={rocketElId} ref={rocketRef} className="rocket-sprite">
        <div className="relative w-[70px] h-[150px]">
          {gameState === 'PLAYING' ? (
            <>
              <Image
                src="/images/rockets/img_rocket.png"
                alt="rocket"
                w="70px"
                h="100px"
                position="relative"
                zIndex={1}
              />
              <Box
                sx={{
                  width: '65px',
                  height: '115px',
                  background: 'url(/images/rockets/rocket-firing-sprite.png) no-repeat center',
                  backgroundSize: '565px',
                  backgroundPosition: '-13px 0px',
                  position: 'absolute',
                  top: '95px',
                  left: '0px',
                  zIndex: 0,
                  transform: 'translateY(-30%)',
                  animation: 'firing 0.5s infinite 0s step-end',
                }}
              />
            </>
          ) : undefined}

          {gameState === 'ENDED' ? (
            <Box
              sx={{
                width: '150px',
                height: '150px',
                background: 'url(/images/rockets/img_sprites_explode.png) no-repeat',
                backgroundSize: 'cover',
                transform: multiplier > 1 ? 'translate(-40px, -40px)' : 'translate(-10px, -40px)',
                animation: 'rocket-explode 1.2s steps(10) forwards',
              }}
            />
          ) : undefined}
        </div>
      </div>
      {gameState === 'REWARD' && <TopPlayers players={topCashoutOrder} />}
      {listActiveOrders?.length > 0 && <ListCurrentBet />}
      {gameState === 'ENDED' && showWinAnim && rewardInfo && <WinningAnimation reward={rewardInfo} />}
      <Stack className="absolute right-0 left-0 z-[3]" mt="6px">
        <BreadcrumbRocket />
      </Stack>
      <ListPlayerBettingInRound />
      <ListPastsOnScreen openStats={statsDrawer.onOpen} />
      <Stats isOpen={statsDrawer.isOpen} closeStats={statsDrawer.onClose} />
    </div>
  )
}

export default Rocket
