import React from 'react'
import AuthGameWrapper from '@/components/common/AuthGameWrapper'
import GamePlayHilo from './gamePlay'
import './index.scss'
import QuickFormHilo from './quickForm'
import MyBetsHiLo from './myBets'
import ConfigHiloSubscription from './subcription/ConfigSubscription'
import TreasureSubscription from './subcription/TreasureSubscription'
import AutoCashOutSubscription from './subcription/AutoCashOutSubscription'
export default function HiloGame() {
  return (
    <AuthGameWrapper>
      <GamePlayHilo />
      <QuickFormHilo />
      <MyBetsHiLo />
      <ConfigHiloSubscription />
      <TreasureSubscription />
      <AutoCashOutSubscription />
    </AuthGameWrapper>
  )
}
