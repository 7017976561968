import { Box } from '@chakra-ui/react'
import React from 'react';

type BallsCounterProps = {
  currentNormalBalls: number,
  totalNormalBalls: number,
  currentExtraBalls: number,
  totalExtraBalls: number,
}

const BallsCounter = ({currentNormalBalls, totalNormalBalls, currentExtraBalls, totalExtraBalls}: BallsCounterProps) => {
  const isNormalBallsFull = currentNormalBalls === totalNormalBalls;
  const isExtraBallsFull = currentExtraBalls === totalExtraBalls;

  return (
    <Box
      background="url('/bingo/images/bg-balls-counter.png') no-repeat top center"
      backgroundSize="cover"
      width="100px"
      aspectRatio="162/36"
      fontSize="12px"
      lineHeight="1"
      color="#e175c0"
      position="absolute"
      bottom="0"
      left="50%"
      transform="translateX(-50%)"
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="5px"
      sx={{
        'span': {
          display: 'inline-block',
        },
      }}
    >
      <Box
        display="inline-block"
      >
        <Box as="span" color={!isNormalBallsFull ? 'white' : ''}>{currentNormalBalls}</Box>/<Box as="span">{totalNormalBalls}</Box>
      </Box>
      <Box
        display="inline-block"
      >
        <Box as="span" color={!isExtraBallsFull ? 'white' : ''}>{currentExtraBalls}</Box>/<Box as="span">{totalExtraBalls}</Box>
      </Box>
    </Box>
  )
}

export default BallsCounter;
