import React from 'react';
import ButtonCircle, { ButtonCircleProps } from '@/bingo/components/Common/ButtonCircle.tsx'

const BingoCurrencyIcon = ({...rest}: ButtonCircleProps) => {
  return (
    <ButtonCircle
      width="25px"
      fontSize="20px"
      aspectRatio="1/1"
      height="auto"
      minHeight="auto"
      {...rest}
    >
      T
    </ButtonCircle>
  )
}

export default BingoCurrencyIcon;
