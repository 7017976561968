import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

type FormButtonBigProps = ButtonProps

const FormButtonBig = ({children, ...rest}: FormButtonBigProps) => {
  return (
    <Button
      background="linear-gradient(180deg, #fe543c 0%, #e84731 100%)"
      padding="25px 40px"
      textAlign="center"
      lineHeight="1"
      color="white"
      fontWeight="500"
      fontSize="22px"
      borderRadius="10px"
      height="50px"
      minHeight="50px"
      whiteSpace="normal"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default FormButtonBig;
