import React, { useEffect, useState } from 'react'
import { selectBingoGameRound, selectBingoRoundConfig } from '@/bingo/redux/bingo.slice'
import { useAppSelector } from '@/redux/store'
import { Text } from '@chakra-ui/react'

export function BingoCountDown() {
  const roundConfig = useAppSelector(selectBingoRoundConfig)
  const round = useAppSelector(selectBingoGameRound)
  const startTime = round?.start_time
  const status = round?.status
  const [countdown, setCountdown] = useState(0)

  useEffect(() => {
    if (!startTime || !status || !roundConfig?.waitingTime) return

    const interval = setInterval(() => {
      if (status === 'WaitForBet') {
        // countdown to start time
        const now = new Date().getTime()
        const _startTime = new Date(startTime).getTime()

        const seconds = Math.floor((_startTime - now) / 1000)
        setCountdown(seconds)
      }
      if (status === 'Running') {
        // countdown to end time = start time = roundConfig.waitingTime
        const now = new Date().getTime()
        const _startTime = new Date(startTime).getTime()
        const _endTime = new Date(_startTime + roundConfig.waitingTime * 1000).getTime()

        const seconds = Math.floor((_endTime - now) / 1000)
        setCountdown(seconds)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [startTime, status, roundConfig?.waitingTime])

  if (round.status === 'WaitForBet') {
    return (
      <Text textAlign="center" position="absolute" top="27px" left="0" right="0" color="#ff4218">
        Draw the lottery in {countdown.toFixed(2)} s.
      </Text>
    )
  }

  if (round.status === 'Running') {
    return (
      <Text textAlign="center" position="absolute" top="27px" left="0" right="0" color="#ff4218">
        Draw the lottery in {countdown.toFixed(2)} s.
      </Text>
    )
  }

  return null
}
