import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { hiloActions, selectGameRoundHilo } from '@/redux/store/modules/hilo'
import React, { useEffect } from 'react'

export default function TreasureSubscription() {
  const dispatch = useAppDispatch()
  const game_id = useGameId()
  const gameRound = useAppSelector(selectGameRoundHilo)
  const _message = useSubscription(Topics.treasure(game_id, gameRound?.id))
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '')
      dispatch(hiloActions.updateTreasure(data?.value))
    } catch (error) {
      console.warn('ConfigHiloSubscription error: ', error)
    }
  }, [message])

  return <></>
}
