import parse from 'html-react-parser'
import React from 'react'

interface HtmlRenderProps {
  html: string
  className?: string
}

export const HtmlRender: React.FC<HtmlRenderProps> = ({ html, className }) => {
  return <div className={`html-viewer ${className}`}>{parse(html)}</div>
}

export default HtmlRender
