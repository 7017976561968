import { Box, BoxProps, Flex, SystemStyleObject } from '@chakra-ui/react'
import React from 'react';

type PointChipProps = BoxProps & {
  point: number,
  isNew?: boolean,
}

export const colorByPoint = (point: number): string => {
  switch (true) {
    case point >= 10:
      return '#f5b638'
    case point >= 6 && point < 10:
      return '#9685ff'
    case point >= 2 && point < 6:
      return '#30dca8'
    default:
      return '#319fdd'
  }
}

const PointChip = ({point, isNew, ...rest}: PointChipProps) => {
  return (
    <Box
      display="inline-flex"
      border={`1px solid ${colorByPoint(point)}`}
      borderRadius="4px"
      alignItems="center"
      justifyContent="center"
      padding="0.5px 2px"
      lineHeight="1"
      color={colorByPoint(point)}
      minWidth="40px"
      width="40px"
      position="relative"
      {...rest}
    >
      {point}
      {isNew && (
        <Flex
          width="18px"
          height="18px"
          position="absolute"
          top="-10px"
          right="-10px"
          borderRadius="50px"
          color="white"
          backgroundColor="red"
          alignItems="center"
          justifyContent="center"
          lineHeight="1"
          fontSize="10px"
        >
          New
        </Flex>
      )}
    </Box>
  )
}

export default PointChip;
