import React from 'react';
import { Flex } from '@chakra-ui/react';
import LotteryPattern from '@/bingo/components/GameplayStage/LotteryPatternsArea/LotteryPattern';

const TopRow = () => {
  return (
    <Flex
      flex="1"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        justifyContent="space-between"
        gap="20.5px"
      >
        <LotteryPattern />
        <LotteryPattern />
      </Flex>
      <Flex
        justifyContent="space-between"
        gap="20px"
      >
        <LotteryPattern />
        <LotteryPattern />
      </Flex>
    </Flex>
  )
}

export default TopRow;
