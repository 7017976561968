import React from 'react'

interface LastResultProps {
  lastRates: number[]
}
export function LastResult({ lastRates }: LastResultProps) {
  return (
    <div className="flex flex-col">
      {lastRates.map((rate, id) => {
        return (
          <div className="bg-[#333] px-4 my-1 rounded-md" key={id}>
            {rate}x
          </div>
        )
      })}
    </div>
  )
}
