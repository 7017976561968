import useCustomToast from '@/hooks/useCustomToast'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppSelector } from '@/redux/store'
import { selectGameConfigs } from '@/redux/store/modules/game.slice'
import { selectCurrency } from '@/redux/store/modules/wallet.slice'
import { extractGameId } from '@/utils/helper'
import { keyBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorOptions, PopupErrorHandler, setPopupErrorHander } from './app'
import eventBus from '@/utils/eventBus'

const errorCodes = keyBy([
  'ErrAccessTokenInvalid',
  'ErrPlayerInactive',
  'ErrPlayerNotFound',
  'ErrSessionInvalid',
  'ErrSessionTokenInvalid',
  'ErrWalletNotFound',
  'ErrCurrencyNotSupported',
])
export const EVENT_MESSAGE_ERROR = 'EVENT_MESSAGE_ERROR'

export function ErrorHandlerWrapper() {
  const { t } = useCustomTranslation()
  const [messageObj, setMessage] = useState<ErrorOptions>()
  const config = useAppSelector(selectGameConfigs)
  const currency = useAppSelector(selectCurrency)

  function getErrorMessage(messageObj?: ErrorOptions) {
    const code = messageObj?.code
    const meta = {
      ruleBet: config.amountStep,
      ruleBetResult: `${config.amountStep}, ${parseFloat((config.amountStep * 2).toFixed(2))}, ${parseFloat(
        (config.amountStep * 3).toFixed(2),
      )}`,
      currency,
      ...(messageObj?.meta || {}),
    }
    const message = code ? t(`home.error.${code}`, meta) : ''
    return message
  }

  const message = getErrorMessage(messageObj)
  const { showToastThrottle } = useCustomToast()
  const currentPath = window.location.pathname
  const gameId = extractGameId(currentPath)

  const navigate = useNavigate()

  useEffect(() => {
    const handler: PopupErrorHandler = (payload) => {
      if (errorCodes[payload.code || '']) {
        const message = getErrorMessage(payload)
        navigate(`/${gameId}/error`, {
          replace: true,
          state: {
            message,
          },
        })
        return
      }
      setMessage(payload)
    }
    setPopupErrorHander(handler)
  }, [])

  useEffect(() => {
    eventBus.on(EVENT_MESSAGE_ERROR, (data: any) => {
      if (data?.data) {
        setMessage(data?.data)
      }
    })
    return () => {
      eventBus.remove(EVENT_MESSAGE_ERROR)
    }
  }, [])

  useEffect(() => {
    if (!message || messageObj?.mute) return

    showToastThrottle({
      title: message,
      status: 'error',
      isClosable: false,
      duration: 3000,
      containerStyle: {
        width: '60px',
        paddingBlock: '0px',
        pointerEvents: 'none',
      },
    })
  }, [messageObj])

  return <></>
}
