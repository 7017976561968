import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

type RocketInputTitleProps = TextProps

const RocketInputTitle = ({children, ...rest}: RocketInputTitleProps) => {
  return (
    <Text
      color="#9cb4d9"
      fontSize="16px"
      lineHeight="1"
      {...rest}
    >
      {children}
    </Text>
  )
}

export default RocketInputTitle;
