import { useAppSelector } from '@/redux/store'
import {
  selectPlinkoIsPreventing,
  selectPlinkoRiskLevel,
  selectPlinkoRows,
  selectPlinkoWinRates,
} from '@/redux/store/modules/plinko.slice'
import { forEach, isEmpty, map } from 'lodash'
import {
  Body,
  Bounds,
  Engine,
  Events,
  IEventCollision,
  IMouseEvent,
  Mouse,
  MouseConstraint,
  Query,
  Render,
  Runner,
  Vector,
  World,
} from 'matter-js'
import { MutableRefObject, useEffect, useState } from 'react'
import { plinkoGameConfigs } from '../../../config'
import { getWinRateSound } from '../../../config/multipliers'
import { usePinGap } from './usePinGap'

interface UseEventHandlerProps {
  worldHeight: number
  worldWidth: number
  engine: Engine
  runner: Runner
  render?: Render
  addBoom: (item: { x: number; y: number; rate: number }) => void
  playAudio: (audio: string) => void
  pinsRef: MutableRefObject<Body[]>
  multipliersRef: MutableRefObject<Body[]>
  ballsRef: MutableRefObject<Record<string, Body>>
}

export function useEventHandler({
  multipliersRef,
  ballsRef,
  addBoom,
  playAudio,
  engine,
  render,
  runner,
  pinsRef,
  worldWidth,
  worldHeight,
}: UseEventHandlerProps) {
  const [highlightLabel, setHighlightLabel] = useState('')

  useEffect(() => {
    if (!render) return
    const _onMouseMove = onMouseMove(render)
    document.addEventListener('mousemove', _onMouseMove)
    return () => {
      document.removeEventListener('mousemove', _onMouseMove)
    }
  }, [render, highlightLabel, multipliersRef])

  const engineConfig = plinkoGameConfigs.engine
  useEffect(() => {
    if (!render) return

    engine.gravity = engineConfig.gravity
    const mouse = Mouse.create(render.canvas)
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    })
    World.add(engine.world, mouseConstraint)

    const _onMouseClick = onMouseClick(mouse)
    Events.on(mouseConstraint, 'mousedown', _onMouseClick)

    return () => {
      Events.off(mouseConstraint, 'mousedown', _onMouseClick)
    }
  }, [engine, ballsRef])

  const isPreventing = useAppSelector(selectPlinkoIsPreventing)
  useEffect(() => {
    Events.on(engine, 'beforeUpdate', applyBallForces)
    if (!isPreventing) {
      const timeout = setTimeout(() => {
        Events.off(engine, 'beforeUpdate', applyBallForces)
      }, plinkoGameConfigs.pins.collisionTime)
      return () => {
        clearTimeout(timeout)
        Events.off(engine, 'beforeUpdate', applyBallForces)
      }
    }
    return () => {
      Events.off(engine, 'beforeUpdate', applyBallForces)
    }
  }, [engine, ballsRef, multipliersRef, isPreventing])

  useEffect(() => {
    if (!render) return
    const _runner = runner as any
    const isRunning = _runner._running
    if (isRunning === undefined) return

    if (isPreventing && !isRunning) {
      _runner._running = true
      Runner.run(runner, engine)
      Render.run(render)
    } else {
      const timeout = setTimeout(() => {
        const _runner = runner as any
        const isRunning = _runner._running
        if (isPreventing || !isRunning) return
        _runner._running = false
        Runner.stop(runner)
        Render.stop(render)
      }, plinkoGameConfigs.pins.collisionTime)
      return () => clearTimeout(timeout)
    }
  }, [engine, render, runner, isPreventing])

  const lines = useAppSelector(selectPlinkoRows)
  const risk = useAppSelector(selectPlinkoRiskLevel)

  useEffect(() => {
    if (!render) return
    const _runner = runner as any
    const isRunning = _runner._running
    if (isRunning === undefined) return

    if (!isRunning) {
      _runner._running = true
      Runner.run(runner, engine)
      Render.run(render)

      const timeout = setTimeout(() => {
        const _runner = runner as any
        const isRunning = _runner._running
        if (!isRunning) return
        _runner._running = false
        Runner.stop(runner)
        Render.stop(render)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [engine, render, runner, lines, risk])

  const collisionHeight = plinkoGameConfigs.multipliers.collisionHeight
  const collisionTime = plinkoGameConfigs.multipliers.collisionTime
  const pinCollisionTime = plinkoGameConfigs.pins.collisionTime
  function applyBallForces() {
    forEach(ballsRef.current, (ball) => {
      const ballData = ball as any
      const pointA = ball.position
      const pointB: Vector = ballData.points[0]
      const dir = pointB ? Vector.sub(pointB, pointA) : ballData.lastDir === 'L' ? { x: -20, y: 50 } : { x: 20, y: 50 }
      const _ball = ball as any
      const _f = Vector.mult(dir, 0.01)
      const force = _f
      _ball.lastF = force
      Body.applyForce(ball, ball.position, force)
    })

    forEach(multipliersRef.current, (rate) => {
      const _body = rate as any
      const startTime = _body._t
      if (!startTime) return
      if (startTime + collisionTime < Date.now()) {
        Body.setPosition(rate, { ..._body._staticPosition })
        delete _body._t
        delete _body._staticPosition
        return
      }
      const t = Date.now() - startTime
      const x = (t * Math.PI) / collisionTime
      const p = rate.position
      const y0 = _body?._staticPosition?.y || p.y

      const y = y0 + Math.sin(x) * collisionHeight

      Body.setPosition(rate, { x: p.x, y })
    })

    forEach(pinsRef.current, (pin) => {
      const _pin = pin as any
      const startTime = _pin._t
      if (!startTime) return
      const now = Date.now()
      const t = now - startTime
      if (t > pinCollisionTime) {
        pin.render.fillStyle = pinsConfig.color
        delete _pin._t
        return
      }
      pin.render.fillStyle = getColorAtTime(pinsConfig.collisionColor, pinsConfig.color, t, pinCollisionTime)
    })
  }

  const multiplierHeight = plinkoGameConfigs.multipliers.height

  const multiplier = useAppSelector(selectPlinkoWinRates(lines, risk))
  const rates = multiplier?.multipliers || []

  useEffect(() => {
    Events.on(engine, 'collisionStart', onBodyCollisionWithBlock)
    Events.on(engine, 'collisionStart', onBodyCollisionWithPin)
    return () => {
      Events.off(engine, 'collisionStart', onBodyCollisionWithBlock)
      Events.off(engine, 'collisionStart', onBodyCollisionWithPin)
    }
  })

  const pinsConfig = plinkoGameConfigs.pins
  const { ballSize, pinSize } = usePinGap({
    worldWidth,
    worldHeight,
  })
  async function onBodyCollisionWithPin(event: IEventCollision<Engine>) {
    const pairs = event.pairs
    for (const pair of pairs) {
      const { bodyA, bodyB } = pair
      if (bodyA.label.startsWith('pin')) {
        const pin = bodyA as any
        pin._t = Date.now()
        bodyA.render.fillStyle = pinsConfig.collisionColor

        if (bodyB.label.startsWith('ball')) {
          const ball = bodyB as any
          if (!ball.points[0]) continue
          const d = Vector.magnitude(Vector.sub(ball.points[0], bodyB.position))
          if (d <= (ballSize + pinSize) * 1.2) {
            Body.setVelocity(bodyB, { x: 0, y: 0 })
            ball.points.shift()
          }
        }
      }
    }
  }
  async function onBodyCollisionWithBlock(event: IEventCollision<Engine>) {
    const pairs = event.pairs
    for (const pair of pairs) {
      const { bodyA, bodyB } = pair
      const aIsBlock = bodyA.label.startsWith('rates')
      const bIsBall = bodyB.label.startsWith('ball')
      if (aIsBlock && bIsBall) {
        await onCollideWithMultiplier(bodyB, bodyA, rates)
      }
    }
  }
  async function onCollideWithMultiplier(ball: Body, multiplier: Body, rates: number[]) {
    delete ballsRef.current[ball.id]

    const rate = +multiplier.label.split('-')[1]

    ball.collisionFilter.group = 2
    World.remove(engine.world, ball)
    const ballValue = ball.label.split('-')[1]
    const _body = multiplier as any

    if (!_body._t) {
      _body._staticPosition = { ...multiplier.position }
      _body._t = Date.now()
    }
    addBoom({
      x: ball.position.x,
      y: multiplier.position.y - multiplierHeight / 2,
      rate,
    })
    playAudio(getWinRateSound(rate, rates))
    if (+ballValue <= 0) return
  }

  const onMouseClick = (mouse: Mouse) => (event: IMouseEvent<MouseConstraint>) => {
    if (!plinkoGameConfigs.debug) return

    console.log('onClick: ', event)
    const mousePosition = mouse.position

    const clickedBodies = Query.point(map(ballsRef.current), mousePosition)

    if (clickedBodies.length > 0) {
      clickedBodies.forEach((body) => {
        console.log('Clicked on:', body.label)
        body.render.fillStyle = 'red'
      })
    }
  }

  const onMouseMove = (render: Render) => (event: MouseEvent) => {
    const multipliers = multipliersRef.current

    if (isEmpty(multipliers)) return

    const rect = render.canvas.getBoundingClientRect()
    const mouseX = event.clientX - rect.left
    const mouseY = event.clientY - rect.top
    const box1 = multipliers[0]
    const bound1 = box1.bounds

    if (mouseX < 0 || mouseX > worldWidth || mouseY > bound1.max.y || mouseY < bound1.min.y) {
      if (highlightLabel) {
        setHighlightLabel('')
      }
      return
    }

    for (let i = 0; i < multipliers.length; i++) {
      const box = multipliers[i]
      if (Bounds.contains(box.bounds, { x: mouseX, y: mouseY })) {
        if (highlightLabel != box.label) {
          setHighlightLabel(box.label)
        }
        return
      }
    }
  }

  return { highlightLabel }
}

function hexToRgb(hex: string) {
  hex = hex.replace(/^#/, '') // Bỏ dấu #
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((x) => x + x)
      .join('') // Chuyển #RGB sang #RRGGBB
  }
  const bigint = parseInt(hex, 16)
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  }
}
function rgbToHex(r: number, g: number, b: number) {
  return `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`
}

export function getColorAtTime(hexA: string, hexB: string, t: number, totalTime: number) {
  const rgbA = hexToRgb(hexA)
  const rgbB = hexToRgb(hexB)

  const r = Math.round(rgbA.r + ((rgbB.r - rgbA.r) * t) / totalTime)
  const g = Math.round(rgbA.g + ((rgbB.g - rgbA.g) * t) / totalTime)
  const b = Math.round(rgbA.b + ((rgbB.b - rgbA.b) * t) / totalTime)

  return rgbToHex(r, g, b)
}
