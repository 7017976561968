import React from 'react';
import ButtonCircle from '@/bingo/components/Common/ButtonCircle.tsx';
import { BoxProps } from '@chakra-ui/react';

type FooterButtonCircleProps = BoxProps

const FooterButtonCircle = ({children, ...rest}: FooterButtonCircleProps) => {
  return (
    <ButtonCircle
      width="32px"
      height="32px"
      fontSize="20px"
      boxShadow="0 2px 6px -1px rgba(0, 0, 0, 0.5)"
      {...rest}
    >
      {children}
    </ButtonCircle>
  )
}

export default FooterButtonCircle;
