import { Input, InputProps } from '@chakra-ui/react'
import React from 'react'

type RocketInputProps = InputProps

const RocketInput = ({...rest}: RocketInputProps) => {
  return (
    <Input
      height="31px"
      fontSize="16px"
      fontWeight="700"
      lineHeight="1"
      border="none"
      outline="none !important"
      boxShadow="none !important"
      backgroundColor="transparent !important"
      position="relative"
      padding="0"
      color="#ffd200"
      borderRadius="0"
      fontStyle="italic"
      paddingLeft="5.3px"
      _placeholder={{ fontWeight: 500, color: '#2e2e2e', fontSize: 12 }}
      {...rest}
    />
  )
}

export default RocketInput;
