import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react'
import TicketPatternCell from '@/bingo/components/Common/Ticket/TicketPatternCell.tsx'
import { convertToRowWiseCard } from '@/bingo/const/bingoHelpers.ts'

type TicketPatternBoardProps = FlexProps & {
  numbers?: number[],
}

const NumbersRenderer = ({numbers}: {numbers?: number[]}) => {
  if (!numbers || numbers.length === 0) {
    return (
      <>
        {new Array(15).fill(0).map((_, index) => (
          <TicketPatternCell key={index} />
        ))}
      </>
    )
  }

  return (
    <>
      {numbers?.map((number) => (
        <TicketPatternCell value={number} key={number} />
      ))}
    </>
  )
}

const TicketPatternBoard = ({numbers}: TicketPatternBoardProps) => {
  const convertedNumbers = convertToRowWiseCard(numbers)

  return (
    <Flex
      flexWrap="wrap"
      padding="2px"
      gap="2px"
    >
      <NumbersRenderer numbers={convertedNumbers} />
    </Flex>
  )
}

export default TicketPatternBoard;
