import React from 'react'
import TShowMainGame from '@components/tshow/GamePlay/TShowMainGame.tsx'
import RKQuickForm from '@/components/rocket/QuickForm/index'
import TabSection from '@/components/rocket/TabSection'
import MyBets from '@/components/rocket/MyBets'
import AuthGame from '@/components/rocket/AuthGame'
import GameRoundSubscription from '@/components/rocket/Subcription/GameRoundSubscription'
import ConfigRoundSubscription from '@/components/rocket/Subcription/ConfigRoundSubscription'
import MultiplierSubscription from '@/components/rocket/Subcription/MultiplierSubscription'
import OrdersSubscription from '@/components/rocket/Subcription/OrdersSubscription'
import RoundOrderSubscription from '@/components/rocket/Subcription/RoundOrderSubscription'
import RoundConfig from '@/components/rocket/RoundConfig'

export default function TShowPage() {
  return (
    <AuthGame>
      <GameRoundSubscription />
      <ConfigRoundSubscription />
      <MultiplierSubscription />
      <OrdersSubscription />
      <RoundOrderSubscription />
      <TShowMainGame />
      <RoundConfig />
      <RKQuickForm />
      <TabSection />
      <MyBets />
    </AuthGame>
  )
}
