import React, { useEffect, useRef } from 'react'
import { useTShowGamePlay } from './hooks/useTShowGamePlay'
import TopPlayers from './TopPlayers'
import './styles/tshow.scss'
import { TShowCountdown, MultiplierDisplay } from './TShowComponent'
import TShowBackground from './TShowBackground'
import WinningAnimation from './WinningAnimation'
import { useDisclosure } from '@chakra-ui/react'
import Stats from '@components/rocket/GamePlay/Stats'
import { useTShowSound } from './hooks/useTShowSound'
import useGameId from '@/hooks/useGameId'
import { useAppDispatch } from '@/redux/store'
import { getCrashResultHistory } from '@/redux/store/modules/rocketTshow'
import ListCurrentBet from '@/components/rocket/ListCurrentBet'
import ListMenu from '@/components/rocket/Menu'
import ListPlayerBettingInRound from '@/components/rocket/GamePlay/on-screen/ListPlayerBettingInRound'
import ListPastsOnScreen from '@/components/rocket/GamePlay/on-screen/ListPastsOnScreen'
import TShowResult from './TShowResult'

const TShowMainGame = () => {
  const container = useRef<HTMLDivElement>(null)
  const modelRef = useRef<HTMLDivElement>(null)
  const modelElId = 'model-el'
  const statsDrawer = useDisclosure()

  const {
    // containerSize,
    gameState,
    gameRound,
    countdown,
    roundMultiplier,
    topCashoutOrder,
    listActiveOrders,
    rewardInfo,
    showWinAnim,
  } = useTShowGamePlay({
    modelRef,
    containerRef: container,
  })
  useTShowSound(gameState, countdown)

  const gameId = useGameId()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (gameState === 'ENDED') {
      dispatch(
        getCrashResultHistory({
          game_id: gameId,
        }),
      )
    }
  }, [gameId, gameState, dispatch])

  const multiplier = roundMultiplier.m ?? gameRound.multiplier
  if (gameState !== 'WAITING' && !multiplier) {
    console.log('multiplier', multiplier)
    console.log('gameRound', gameRound)
  }

  return (
    <div ref={container} id="tshow-container" className="relative h-[500px] overflow-hidden">
      <TShowBackground isAnimating={gameState === 'PLAYING'} gameState={gameState} />
      {gameState === 'WAITING' && <TShowCountdown countdown={countdown} />}
      {gameState === 'PLAYING' && <MultiplierDisplay multiplier={multiplier} gameState={gameState} />}
      <div id={modelElId} ref={modelRef} className={`model-walk left-[20%] sm:left-[25%]`} />
      {gameState === 'REWARD' && <TopPlayers players={topCashoutOrder} />}
      {listActiveOrders?.length > 0 && <ListCurrentBet />}
      {((gameState === 'ENDED' && showWinAnim && rewardInfo) || gameState === 'REWARD') && (
        <TShowResult multiplier={multiplier} />
      )}
      {gameState === 'ENDED' && showWinAnim && rewardInfo && <WinningAnimation reward={rewardInfo} />}
      <ListMenu />
      <ListPlayerBettingInRound />
      <ListPastsOnScreen openStats={statsDrawer.onOpen} />
      <Stats isOpen={statsDrawer.isOpen} closeStats={statsDrawer.onClose} />
    </div>
  )
}

export default TShowMainGame
