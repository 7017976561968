import React from 'react';
import { Box } from '@chakra-ui/react';
import Ball from '@/bingo/components/GameplayStage/MiningArea/Ball'

const ExploitedBalls = () => {
  return (
    <Box
      background="url('/bingo/images/bg-balls-list.png') no-repeat left center"
      backgroundSize="cover"
      backgroundPosition="4px"
      width="288px"
      height="82px"
      position="relative"
      padding="11.5px 2px 0 24px"
      marginRight="5.5px"
      marginTop="15px"
    >
      <Box
        overflowX="auto"
        borderLeftRadius="50px"
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Box
          display="flex"
          gap="5.5px"
        >
          <Ball value={1} />
          <Ball value={12} />
          <Ball value={13} />
          <Ball value={24} />
          <Ball value={25} />
          <Ball value={36} />
          <Ball value={37} />
          <Ball value={48} />
          <Ball value={49} />
          <Ball value={60} />
        </Box>
      </Box>
    </Box>
  )
}

export default ExploitedBalls;
