import { BetType } from '@/redux/store/entities/order.entity'
import keyBy from 'lodash/keyBy'
export interface GameType {
  id: string
  name: string
  imageLogo: string
  type: BetType[]
  choices?: BetType[]
  symbol: string[]
  color: string[]
}

export enum GameID {
  GAME_HASH = 'GAME_HASH',
  H_BP = 'H_BP',
  H_OE = 'H_OE',
  H_NIUNIU = 'H_NIUNIU',
  H_LUCKY = 'H_LUCKY',
  C_FUTURE = 'C_FUTURE',
  H_BS = 'H_BS',
  H_PLINKO = 'H_PLINKO',
  H_ROCKET = 'H_ROCKET',
  H_BINGO = 'H_BINGO',
  H_HILO = 'H_HILO',
  H_TSHOW = 'H_TSHOW',
}
export const GameUrlMap = {
  [GameID.H_BP]: import.meta.env.VITE_GAME_ID_BP || '',
  [GameID.H_OE]: import.meta.env.VITE_GAME_ID_OE || '',
  [GameID.H_NIUNIU]: import.meta.env.VITE_GAME_ID_NIUNIU || '',
  [GameID.H_LUCKY]: import.meta.env.VITE_GAME_ID_LUCKY || '',
  [GameID.H_BS]: import.meta.env.VITE_GAME_ID_BS || '',
  [GameID.H_PLINKO]: import.meta.env.VITE_GAME_ID_PLINKO || '',
  [GameID.H_ROCKET]: import.meta.env.VITE_GAME_ID_ROCKET || '',
  [GameID.H_BINGO]: import.meta.env.VITE_GAME_ID_BINGO || '',
  [GameID.H_HILO]: import.meta.env.VITE_GAME_ID_HILO || '',
  [GameID.H_TSHOW]: import.meta.env.VITE_GAME_ID_TSHOW || '',
}

export const betTypeColors: { [key: string]: string } = {
  BIG: '#c5265a',
  BANKER: '#c5265a',
  ODD: '#c5265a',
  SMALL: '#1961e5',
  PLAYER: '#1961e5',
  EVEN: '#1961e5',
  TIE: '#57cc99',
  WIN: '#1961e5',
  LOSE: '#c5265a',
}

export const gameType: Array<GameType> = [
  {
    id: 'H_BS',
    name: 'Hash Big/Small',
    imageLogo: 'images/logo/image.webp',
    type: ['BIG', 'SMALL'],
    symbol: ['B', 'S'],
    color: ['#c5265a', '#1961e5'],
  },
  {
    id: 'H_BP',
    name: 'Banker & Player',
    imageLogo: 'images/logo/image.webp',
    type: ['BANKER', 'TIE', 'PLAYER'],
    symbol: ['B', 'T', 'P'],
    color: ['#c5265a', '#57cc99', '#1961e5'],
  },
  {
    id: 'H_OE',
    name: 'Hash Odd/Even',
    imageLogo: 'images/logo/image.webp',
    type: ['ODD', 'EVEN'],
    symbol: ['O', 'E'],
    color: ['#c5265a', '#1961e5'],
  },
  {
    id: 'H_NIUNIU',
    name: 'Hash Niu Niu',
    imageLogo: 'images/logo/image.webp',
    type: ['BANKER', 'PLAYER', 'TIE'],
    choices: ['BANKER', 'PLAYER'],
    symbol: ['B', 'T', 'P'],
    color: ['#c5265a', '#1961e5', '#57cc99'],
  },
  {
    id: 'H_LUCKY',
    name: 'Lucky Hash',
    imageLogo: 'images/logo/image.webp',
    type: ['WIN', 'LOSE'],
    symbol: ['W', 'L'],
    color: ['#1961e5', '#c5265a'],
  },
]

export const gameConfigs = keyBy(gameType, 'id')
