import { colors } from '../styles/colors'

export const lineConfigs = {
  minLines: 8,
  maxLines: 16,
  options: [8, 9, 10, 11, 12, 13, 14, 15, 16],
}

export declare type RiskLevel = 'low' | 'mid' | 'high'
const _risks = {
  low: 1,
  mid: 2,
  high: 3,
}
export function riskLevelToNumber(risk: RiskLevel) {
  return _risks[risk]
}

const pins = {
  startPins: 1,
  pinSize: 6,
  pinGap: 20,
  pinPadding: 8,
  headerHeight: 0,
  footerHeight: 20,
  color: '#F5DCFF',
  pinOpacity: 0.3,
  borderColor: 'transparent',
  borderWidth: 2,
  collisionColor: '#f2a500',
  collisionTime: 3000,
}

const ball = {
  ballSize: 8,
  density: 10,
  restitution: 1,
  friction: 0.1,
  frictionAir: 0.05,
}

const engine = {
  gravity: {
    y: 0.001,
    x: 0,
    scale: 0.001,
  },
}

const world = {
  width: 480,
  height: 480,
}

export const plinkoGameConfigs = {
  debug: true,
  timing: {
    timeScale: 6,
  },
  balls: {
    min: 1,
    max: 100,
    ...ball,
  },
  pins,
  multipliers: {
    height: 25,
    collisionTime: 500,
    collisionHeight: 15,
    boomTime: 500,
  },
  engine,
}

export const config = {
  pins,
  ball,
  engine,
  world,
  colors,
}
