import { CrashRound } from '@/@generated/gql/graphql-hash'
import { useAppSelector } from '@/redux/store'
import { selectListPasts } from '@/redux/store/modules/rocketTshow'
import { Flex, Text, Button, Box } from '@chakra-ui/react'
import { t } from 'i18next'
import React from 'react'
import PointChip from '@components/rocket/GamePlay/Stats/PointChip.tsx'

type ListPastsOnScreenProps = {
  openStats?: () => void
}

const ListPastsOnScreen = ({openStats}: ListPastsOnScreenProps) => {
  const listPasts = useAppSelector(selectListPasts)

  const handleOpenStats = () => {
    if (!openStats) return

    openStats()
  }

  return (
    <Box
      position="absolute"
      bottom="0"
      left="0"
      right="0"
      zIndex="4"
      _before={{
        content: '""',
        position: 'absolute',
        left: '0',
        right: '0',
        bottom: '0',
        top: '-80px',
        background: 'linear-gradient(transparent, rgba(28, 27, 30, 0.2), rgba(28, 27, 30, 0.5), #1c1b1e)',
        zIndex: '1',
        pointerEvents: 'none',
      }}
    >
      <Flex
        gap={1}
        justifyContent="space-between"
        alignItems="center"
        px={2.5}
        py={1}
        position="relative"
        zIndex="2"
        width="100%"
      >
        <Text fontSize={14} mr="auto">{t('past')}: </Text>
        <Flex gap={1} px={2} py={1} borderRadius={4} justifyContent="space-between">
          {listPasts.slice(0, 6).map((item: CrashRound, index: number) => {
            return (
              <PointChip point={item.multiplier} key={index} />
            )
          })}
          {/* <Text fontSize={14} lineHeight={1}>
          'abc1231'
        </Text>
        <Text fontSize={14} lineHeight={1}>
          1.23
        </Text> */}
        </Flex>
        <Button
          w="50px"
          h="20px"
          fontSize={13}
          onClick={handleOpenStats}
          background="linear-gradient(180deg, #285b69 0%, #29697b 100%)"
          border="1px solid #dde4ff4c"
          color="#7ac0c2"
          _hover={{}}
          _active={{}}
        >
          {t('stats')}
        </Button>
      </Flex>
    </Box>
  )
}

export default React.memo(ListPastsOnScreen)
