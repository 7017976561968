import React from 'react';
import { TiArrowBack } from "react-icons/ti";
import FooterButtonCircle from '@/bingo/components/FormSection/FooterButtonCircle.tsx'
import { BoxProps } from '@chakra-ui/react'

type FormGoBackProps = BoxProps

const FormGoBack = ({...rest}: FormGoBackProps) => {
  return (
    <FooterButtonCircle
      {...rest}
    >
      <TiArrowBack />
    </FooterButtonCircle>
  )
}

export default FormGoBack;
