import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { GiSoundOn } from 'react-icons/gi';

type SoundControllerProps = ButtonProps;

const SoundController = ({...rest}: SoundControllerProps) => {
  return (
    <Button
      variant="link"
      position="absolute"
      top="8px"
      right="5px"
      fontSize="25px"
      {...rest}
    >
      <Icon
        as={GiSoundOn}
        color="#f97768"
      />
    </Button>
  )
}

export default SoundController;
