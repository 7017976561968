import useGameId from '@/hooks/useGameId.ts'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice.ts'
import { Flex, Stack, Text, Image } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { getWalletById, selectWallet } from '@store/modules/wallet.slice.ts'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FaChevronLeft } from 'react-icons/fa'
import { currencyMap } from '../../const/currency'
import {
  ArabicIcon,
  ChineseIcon,
  EnglishIcon,
  EspanolIcon,
  HindiIcon,
  KoreanIcon,
  PortugueseIcon,
} from '../../const/icons'
import { getInitialLang } from '../../i18n'
import { IframeCommunicationEvent, iframeCon, isLoadedByIframe } from '../../utils/iframe.ts'
import SelectLanguage from '../UI/SelectLanguage'
import SelectWallet from '../UI/SelectWallet.tsx'
import { NumberAnim } from '../common/NumberAnim.tsx'
import ListMenu from './Menu/index.tsx'

const BreadcrumbRocket = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const languageDefault = getInitialLang()
  const gameID = useGameId()
  const wallet = useAppSelector(selectWallet)
  const isRefetchWallet = useAppSelector(selectIsLoadingAction(getWalletById.pending))
  const languageOptions = [
    { value: 'en', label: 'English', icon: <EnglishIcon /> },
    { value: 'zh', label: 'Chinese', icon: <ChineseIcon /> },
    { value: 'hi', label: 'Hindi', icon: <HindiIcon /> },
    { value: 'pt', label: 'Portuguese', icon: <PortugueseIcon /> },
    { value: 'ko', label: 'Korean', icon: <KoreanIcon /> },
    { value: 'ar', label: 'Arabic', icon: <ArabicIcon /> },
    { value: 'es', label: 'Espanol', icon: <EspanolIcon /> },
  ]

  const handleWalletUpdate = () => {
    if (wallet.id) {
      dispatch(getWalletById(wallet.id))
    }
  }

  const handleSelectLanguage = (option: any) => {
    i18n.changeLanguage(option.value)
  }

  const handleReturn = () => {
    if (isLoadedByIframe()) {
      iframeCon.sendToParent({ event: IframeCommunicationEvent.GO_BACK, params: {} })
      return
    }

    window.close()
  }
  const currency = currencyMap[wallet.currency]

  useEffect(() => {
    const endTime = new Date();
    const startTime = window.startTime ?? new Date();
    const timeRendered = endTime.getTime() - startTime.getTime();
    //disable loading when rendered component LCP
    //timeout = timeRendered + bufffer (renderd canvas)
    const id = setTimeout(() => {
      const pageLoadingElement = document.getElementById('pageLoading');
      if (pageLoadingElement) {
        pageLoadingElement.remove();
      }
      document.body.removeAttribute('style');
    }, timeRendered / 8 + 500);

    return () => {
      clearTimeout(id);
    };
  }, []);
  
  return (
    <Stack w="100%" px="10px">
      <Flex gap={4}>
        <Flex alignItems="center" gap={2} cursor="pointer" onClick={handleReturn}>
          <FaChevronLeft />
          <Text textStyle="semibold" fontSize={'1.1rem'}>
            {t(gameID)}
          </Text>
        </Flex>
        <Flex ml="auto" gap={2} alignItems="center" position="relative" zIndex="11">
          <SelectLanguage defaultValue={languageDefault} options={languageOptions} onSelect={handleSelectLanguage} />
          <Flex p="4px" backgroundColor="#323b45" gap={2} alignItems="center" borderRadius="100px">
            <SelectWallet />
            <NumberAnim to={wallet.amount ?? 0} decimal={currency?.decimal ?? 0} dataId={"user-wallet-balance"} />
            <Image
            src="/images/rockets/ic-reload.png"
            width="16px"
            height="auto"
            onClick={handleWalletUpdate}
            cursor="pointer"
            alt=""
            className={isRefetchWallet ?'animate-spin' : ''}
          />
          </Flex>
          
        </Flex>
        <ListMenu />
      </Flex>
    </Stack>
  )
}

export default React.memo(BreadcrumbRocket)
