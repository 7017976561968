import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanelProps, TabPanels, TabProps, Tabs } from '@chakra-ui/react'
import RocketTable, { RocketTableColumns, RocketTableRow } from '@components/rocket/NewUI/RocketTables/RocketTable.tsx'

type RocketTablesTabProps = TabProps
type RocketTableTabPanelProps = TabPanelProps
type RocketTableData = {
  columns: RocketTableColumns,
  rows: RocketTableRow[],
}

const RocketTablesTab = ({children}: RocketTablesTabProps) => {
  return (
    <Tab
      background="none"
      borderRadius="10px"
      padding="11px"
      color="#546175"
      width="calc(100% / 3)"
      lineHeight="1"
      _selected={{
        background: 'rgba(38, 48, 62, 0.7)',
        color: '#eef4fc',
      }}
    >
      {children}
    </Tab>
  )
}

const RocketTableTabPanel = ({children, ...rest}: RocketTableTabPanelProps) => {
  return (
    <TabPanel
      padding="0"
      {...rest}
    >
      {children}
    </TabPanel>
  )
}

const currentLiveData: RocketTableData = {
  columns: ['玩家', '投注金额', '逃生高度', '返奖金额'],
  rows: [
    [
      {value: 'uey789i'},
      {value: '¥89.99'},
      {value: '--'},
      {value: '--'},
    ],
    [
      {value: 'uey789i'},
      {value: '¥89.99'},
      {value: '2.56x', className: 'isGreen'},
      {value: '¥189.99', className: 'isGreen'},
    ],
    [
      {value: 'uey789i'},
      {value: '¥89.99'},
      {value: '未逃生', className: 'isRed'},
      {value: '0.00'},
    ],
  ]
}

const myBetsData: RocketTableData = {
  columns: ['时间', '投注金额', '逃生高度', '中奖金额'],
  rows: [
    [
      {value: '12/23 08:27'},
      {value: '¥8,765.00'},
      {value: '--'},
      {value: '--'},
    ],
    [
      {value: '12/23 08:26'},
      {value: '¥8,239.99'},
      {value: '未逃生', className: 'isRed'},
      {value: '0.00'},
    ],
    [
      {value: '12/23 08:23'},
      {value: '¥367.22'},
      {value: '3.12x', className: 'isGreen'},
      {value: '¥367.33', className: 'isGreen'},
    ],
  ]
}

const breakingPointData: RocketTableData = {
  columns: ['爆炸高度', '逃生玩家', '逃生高度', '爆点哈希'],
  rows: [
    [
      {value: '2.32'},
      {value: 'uey789i'},
      {value: '2.56x', className: 'isGreen'},
      {value: '…87yt787'},
    ],
    [
      {value: '2.13'},
      {value: 'uey789i'},
      {value: '3.28x', className: 'isRed'},
      {value: '…87yt787'},
    ],
    [
      {value: '近20局平均逃生高度：', colSpan: 2, textAlign: 'left'},
      {value: '3.79x'},
      {value: ''},
    ],
  ]
}

const RocketTables = () => {
  return (
    <Box
      background="rgba(26, 33, 42, 0.7)"
      borderRadius="13.3px"
      marginTop="8.3px"
    >
      <Tabs
        variant="unstyled"
      >
        <Box
          padding="11.3px 6.3px 15.3px"
        >
          <TabList
            background="rgba(17, 21, 27, 0.7)"
            borderRadius="10px"
          >
            <RocketTablesTab>当前实况</RocketTablesTab>
            <RocketTablesTab>我的注单</RocketTablesTab>
            <RocketTablesTab>爆点记录</RocketTablesTab>
          </TabList>
        </Box>
        <TabPanels>
          <RocketTableTabPanel>
            <RocketTable
              columns={currentLiveData.columns}
              rows={currentLiveData.rows}
            />
          </RocketTableTabPanel>
          <RocketTableTabPanel>
            <RocketTable
              columns={myBetsData.columns}
              rows={myBetsData.rows}
              tableCaption={
                (<Box>查看更多投注记录 {'>'}</Box>)
              }
            />
          </RocketTableTabPanel>
          <RocketTableTabPanel>
            <RocketTable
              columns={breakingPointData.columns}
              rows={breakingPointData.rows}
            />
          </RocketTableTabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default RocketTables;
