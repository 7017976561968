import { useAppSelector } from '@/redux/store'
import { selectPlinkoRows } from '@/redux/store/modules/plinko.slice'
import { plinkoGameConfigs } from '../../../config'

interface UsePinGapProps {
  worldWidth: number
  worldHeight: number
}

export function usePinGap({ worldWidth, worldHeight }: UsePinGapProps) {
  const rows = useAppSelector(selectPlinkoRows)
  const pinsConfig = plinkoGameConfigs.pins
  const pinColumns = rows + pinsConfig.startPins
  const pinXGap = worldWidth / pinColumns
  const pinRows = rows + pinsConfig.startPins - 1
  const multiplierHeight = plinkoGameConfigs.multipliers.height
  const ratio = window.innerWidth > 550 ? 1 : window.innerWidth / 550
  const pinSize = ((pinsConfig.pinSize * 10) / rows) * ratio
  const footerHeight = (pinsConfig.footerHeight * 10) / rows
  const pinYGap = (worldHeight - pinsConfig.headerHeight - multiplierHeight - pinSize - footerHeight) / pinRows
  const ballConfig = plinkoGameConfigs.balls
  const _ballSize = (ballConfig.ballSize * 10) / rows
  const space = pinXGap < pinYGap ? pinXGap : pinYGap
  const maxBallSize = space / 3.2 - pinSize
  const ballSize = _ballSize < maxBallSize ? _ballSize : maxBallSize

  return { pinXGap, pinYGap, pinSize, ballSize }
}
