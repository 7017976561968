import { useCallback, useEffect, useRef, useState } from 'react'

declare type LoadImageCallback = (err: any, img?: HTMLImageElement) => void
declare type OnError = (err: any) => void

const noop = () => {}
export const loadImage = (url: string, callback: LoadImageCallback = noop) => {
  const img = new Image()
  img.onload = () => {
    callback(null, img)
  }
  img.onerror = (err) => {
    callback(err)
  }
  img.src = url
}

interface UseSpriteOptions {
  startFrame?: number
  sprite: string
  width: number
  height: number
  direction?: 'horizontal' | 'vertical'
  onError?: OnError
  onLoad?: () => void
  onEnd?: () => void
  frameCount?: number
  scale?: number
  fps?: number
  shouldAnimate?: boolean
  stopLastFrame?: boolean
  reset?: boolean
  wrapAfter?: number
  frame?: number
}

export const useSprite = ({
  startFrame = 0,
  sprite,
  width,
  height,
  direction = 'horizontal',
  onError = noop,
  onLoad = noop,
  onEnd = noop,
  frameCount,
  fps = 60,
  shouldAnimate = true,
  stopLastFrame,
  reset,
  scale = 1,
  wrapAfter,
  frame,
}: UseSpriteOptions) => {
  const prevTime = useRef<number>()
  const [currentFrame, setCurrentFrame] = useState(startFrame)
  const [spriteWidth, setSpriteWidth] = useState(0)
  const [spriteHeight, setSpriteHeight] = useState(0)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasErrored, setHasErrored] = useState(false)
  const [maxFrames, setMaxFrames] = useState(0)
  const interval = 1000 / fps

  const loadSprite = useCallback(
    (url: string) => {
      let unmounted = false
      if (!isLoading && (!isLoaded || !hasErrored)) {
        setIsLoading(true)
        loadImage(url, (err, image) => {
          if (unmounted) {
            return
          }
          if (err) {
            onError(err)
            setHasErrored(true)
            return
          }
          onLoad()
          setIsLoaded(true)
          setIsLoading(false)
          setMaxFrames(
            frameCount || Math.floor(direction === 'horizontal' ? image!.width / width : image!.height / height),
          )
          setSpriteWidth(image!.width)
          setSpriteHeight(image!.height)
        })
      }
      return () => (unmounted = true)
    },
    [sprite, isLoaded, hasErrored],
  )

  const animate = useCallback(
    (nextFrame: number, time: number) => {
      if (!prevTime.current) {
        prevTime.current = time
      }

      if (shouldAnimate) {
        const delta = time - prevTime.current
        if (delta < interval) {
          return requestAnimationFrame((time) => animate(nextFrame, time))
        }

        prevTime.current = time - (delta % interval)
        setCurrentFrame(nextFrame)
      } else {
        prevTime.current = 0
      }
    },
    [shouldAnimate],
  )

  const getSpritePosition = useCallback(
    (frame = 0) => {
      const isHorizontal = direction === 'horizontal'

      let row, col
      if (typeof wrapAfter === 'undefined') {
        row = isHorizontal ? 0 : frame
        col = isHorizontal ? frame : 0
      } else {
        row = isHorizontal ? Math.floor(frame / wrapAfter) : frame % wrapAfter
        col = isHorizontal ? frame % wrapAfter : Math.floor(frame / wrapAfter)
      }
      const _width = (-width * col) / scale
      const _height = (-height * row) / scale
      return `${_width}px ${_height}px`
    },
    [direction, width, height, wrapAfter, scale],
  )

  useEffect(() => {
    setIsLoaded(false)
    setHasErrored(false)
    loadSprite(sprite)
  }, [sprite])

  useEffect(() => {
    if (shouldAnimate) {
      const nextFrame = currentFrame + 1 >= maxFrames ? startFrame : currentFrame + 1

      if (!shouldAnimate) {
        return
      }
      if (currentFrame === maxFrames - 1 && stopLastFrame) {
        return onEnd()
      }

      let id = requestAnimationFrame((time) => {
        id = animate(nextFrame, time) || 0
      })
      return () => {
        cancelAnimationFrame(id)
      }
    }
  }, [shouldAnimate, maxFrames, currentFrame, startFrame])

  useEffect(() => {
    setCurrentFrame(startFrame)
  }, [reset])

  useEffect(() => {
    if (typeof frame === 'number' && frame !== currentFrame) {
      setCurrentFrame(frame)
    }
  }, [frame])

  return {
    backgroundImage: isLoaded ? `url(${sprite})` : '',
    backgroundPosition: isLoaded ? getSpritePosition(currentFrame) : '',
    backgroundSize: `${spriteWidth / scale}px ${spriteHeight / scale}px`,
    width: `${width / scale}px`,
    height: `${height / scale}px`,
  }
}
