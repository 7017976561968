import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import { GameRound } from '@/redux/store/entities/game.entity'
import { rocketTshowActions } from '@/redux/store/modules/rocketTshow'
import React, { useEffect } from 'react'

export default function GameRoundSubscription() {
  const dispatch = useAppDispatch()
  const game_id = useGameId()

  const _message = useSubscription(Topics.gameRound(game_id), {
    qos: 1,
  })
  const message = _message?.message?.message

  useEffect(() => {
    if (!message) return
    try {
      const data = JSON.parse(message.toString() || '') as GameRound
      dispatch(rocketTshowActions.updateGameRound(data))
      //Clear round
      if (data?.state === 'End') {
        dispatch(rocketTshowActions.updateMultiplier({}))
        dispatch(rocketTshowActions.clearActiveOrders())
      }
      if (data?.state === 'Waiting') {
        dispatch(rocketTshowActions.clearMyRoundOrders())
      }
    } catch (error) {
      console.warn('GameRoundSubscription error: ', error)
    }
  }, [message])

  return <></>
}
