import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

type ButtonSquareProps = ButtonProps

const ButtonSquare = ({children, ...rest}: ButtonSquareProps) => {
  return (
    <Button
      minWidth="20px"
      width="20px"
      height="20px"
      borderRadius="5px"
      backgroundColor="#dc7347"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      padding="3px"
      color="white"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default ButtonSquare;
