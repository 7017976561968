import React from 'react';
import { Box } from '@chakra-ui/react'
import InnerBall from '@/bingo/components/GameplayStage/MiningArea/Ball/InnerBall.tsx'
import BallNumber from '@/bingo/components/GameplayStage/MiningArea/Ball/BallNumber.tsx'
import { getBingoGroupByNumber } from '@/bingo/const/bingoHelpers.ts'

type BallProps = {
  value: number,
}

const colorsByVariant: Record<string, Record<string, string>> = {
  B: {
    bgBall: 'linear-gradient(161deg, #69a5ff 9.32%, #448bf7 50.52%, #448bf7 86.17%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #f9fbff 0%, #bad5ff 100%)',
    bgText: 'linear-gradient(180deg, #448bf7 0%, #2173ef 100%)',
  },
  I: {
    bgBall: 'linear-gradient(180deg, #ff4a5f 0%, #f13d52 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff5f6 0%, #ffd2d7 100%)',
    bgText: 'linear-gradient(180deg, #e25565 0%, #d23547 100%)',
  },
  N: {
    bgBall: 'linear-gradient(180deg, #7e34e4 0%, #4518a8 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff 0%, #beb1d7 100%)',
    bgText: 'linear-gradient(180deg, #9658cc 0%, #7541a2 100%)',
  },
  G: {
    bgBall: 'linear-gradient(180deg, #8ada76 0%, #3d822d 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff 0%, #bedaba 100%)',
    bgText: 'linear-gradient(180deg, #5eae47 0%, #4a8b37 100%)',
  },
  O: {
    bgBall: 'linear-gradient(180deg, #c9983e 0%, #7c5d20 100%)',
    bgInner: 'radial-gradient(96.88% 96.88% at 50% 3.12%, #fff 0%, #efe3c2 100%)',
    bgText: 'linear-gradient(180deg, #d19d41 0%, #a87e34 100%)',
  },
}

const Ball = ({value}: BallProps) => {
  const variant = getBingoGroupByNumber(value)

  return (
    <Box
      padding="3.5px"
      borderRadius="50px"
      background={colorsByVariant[variant].bgBall}
      boxShadow="0 -1px 0.078rem 0 rgba(255,255,255,0.2) inset"
      minWidth="47.5px"
      width="47.5px"
      height="47.5px"
      position="relative"
    >
      <InnerBall bg={colorsByVariant[variant].bgInner} />
      <BallNumber value={value} bg={colorsByVariant[variant].bgText} />
    </Box>
  )
}

export default Ball;
