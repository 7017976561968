import { CrashCurrentRoundOrder } from '@/@generated/gql/graphql-hash'
import useGameId from '@/hooks/useGameId'
import useSubscription from '@/hooks/useSubscription'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { Topics } from '@/redux/store/common/topic'
import {
  rocketTshowActions,
  getCurrentLiveQuery,
  ROUND_STATE,
  selectGameRound,
} from '@/redux/store/modules/rocketTshow'
import { useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useRef } from 'react'

export default function RoundOrderSubscription() {
  const dispatch = useAppDispatch()
  const gameId = useGameId()

  const _message = useSubscription(Topics.getPlayerBettingInRound(gameId))
  const message = _message?.message?.message

  const gameRound = useAppSelector(selectGameRound)

  const roundOrdersRef = useRef<CrashCurrentRoundOrder[]>([])
  const orderMapRef = useRef<Map<string, number>>(new Map())
  const cashoutOrdersRef = useRef<CrashCurrentRoundOrder[]>([])

  const { data } = useQuery(getCurrentLiveQuery, {
    variables: {
      input: { game_id: gameId },
    },
  })

  const onNewOrder = useCallback(
    (order: CrashCurrentRoundOrder) => {
      const existingIndex = orderMapRef.current.get(order.id)
      if (existingIndex !== undefined) {
        // Update existing order while maintaining position
        roundOrdersRef.current[existingIndex] = order
      } else {
        // Add new order and store its index
        orderMapRef.current.set(order.id, roundOrdersRef.current.length)
        roundOrdersRef.current.push(order)
      }
      dispatch(rocketTshowActions.updateRoundOrders([...roundOrdersRef.current]))
      if (Number(order.esc_multiplier)) {
        cashoutOrdersRef.current = [...cashoutOrdersRef.current, order]
        dispatch(rocketTshowActions.updateCashoutOrders(cashoutOrdersRef.current))
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (data && data['crashCurrentRoundOrder']) {
      roundOrdersRef.current = data['crashCurrentRoundOrder']
      // Rebuild the index map
      orderMapRef.current.clear()
      // Reset cashout orders for new round
      cashoutOrdersRef.current = []
      roundOrdersRef.current.forEach((order, index) => {
        orderMapRef.current.set(order.id, index)
        // Add any existing cashout orders
        if (Number(order.esc_multiplier)) {
          cashoutOrdersRef.current.push(order)
        }
      })
      dispatch(rocketTshowActions.updateRoundOrders(data['crashCurrentRoundOrder']))
      dispatch(rocketTshowActions.updateCashoutOrders(cashoutOrdersRef.current))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (!message) return

    try {
      const data = JSON.parse(message.toString() || '')
      onNewOrder(data)
    } catch (error) {
      console.error('OrdersSubscription error: ', error)
    }
  }, [message, onNewOrder])

  useEffect(() => {
    if (gameRound.state === ROUND_STATE.WAITING) {
      roundOrdersRef.current = []
      orderMapRef.current.clear()
      cashoutOrdersRef.current = []

      dispatch(rocketTshowActions.updateRoundOrders([]))
      dispatch(rocketTshowActions.updateCashoutOrders([]))
    }
  }, [gameRound.state, dispatch])

  return <></>
}
