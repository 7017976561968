import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export type ButtonCircleProps = BoxProps & {
  children?: React.ReactNode,
}

const ButtonCircle = ({children, ...rest}: ButtonCircleProps) => {
  return (
    <Box
      borderRadius="50%"
      backgroundColor="rgb(246, 80, 57)"
      minWidth="18px"
      width="18px"
      height="18px"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      lineHeight="1"
      color="white"
      userSelect="none"
      cursor="pointer"
      pointerEvents="all"
      {...rest}
    >
      {children}
    </Box>
  )
}

export default ButtonCircle;
