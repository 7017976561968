import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react'
import { BingoGroup } from '@/bingo/types/bingo.ts'

type GroupNameProps = FlexProps & {
  group: BingoGroup,
}

const backgroundByGroup: Record<string, string> = {
  B: '#448bf7',
  I: '#e25565',
  N: '#a12aff',
  G: '#65c440',
  O: '#fbad00',
}

const GroupName = ({group, ...rest}: GroupNameProps) => {
  return (
    <Flex
      width="100%"
      aspectRatio="1/1"
      backgroundColor={backgroundByGroup[group]}
      alignItems="center"
      justifyContent="center"
      color="#fff"
      fontSize="25px"
      lineHeight="1"
      fontWeight="500"
      {...rest}
    >
      {group}
    </Flex>
  )
}

export default GroupName;
