import React, { useEffect, useState } from 'react'
import { Box, Image } from '@chakra-ui/react'

type BackgroundProps = {
  animationDuration?: number
  isAnimating: boolean
  currentMultiplier?: number
}

const RocketBackground = ({ animationDuration = 3.5, isAnimating }: BackgroundProps) => {
  const [currentDuration, setCurrentDuration] = useState(animationDuration)

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 0,
      }}
    >
      <Image src="/images/rockets/img_bg.jpg" width="100%" height="100%" />
      <Image
        src="/images/rockets/img_earth.png"
        width="100%"
        height="100%"
        sx={{
          position: 'absolute',
          bottom: '-110px',
          left: '0',
          width: '100%',
          // height: '100%',
          zIndex: 0,
        }}
      />
    </Box>
  )
}

export default RocketBackground
