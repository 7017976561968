import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

type TicketCellProps = FlexProps & {
  value?: number,
}

const TicketCell = ({value, ...rest}: TicketCellProps) => {
  return (
    <Flex
      width="100%"
      aspectRatio="1/1"
      background="linear-gradient(180deg, #ffefdc 0%, #fff5ef 100%)"
      alignItems="center"
      justifyContent="center"
      color="#ff4218"
      fontSize="25px"
      lineHeight="1"
      fontWeight="500"
      {...rest}
    >
      {value}
    </Flex>
  )
}

export default TicketCell;
