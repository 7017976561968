import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Decimal: { input: any; output: any; }
  JSON: { input: any; output: any; }
  PqInt64Array: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export enum BetType {
  Equal = 'Equal',
  GreaterThan = 'GreaterThan',
  GreaterThanEqual = 'GreaterThanEqual',
  LessThan = 'LessThan',
  LessThanEqual = 'LessThanEqual'
}

export type BingoBetTickets = {
  __typename?: 'BingoBetTickets';
  bingo_bet_id: Scalars['String']['output'];
  multiplier: Scalars['String']['output'];
  numbers: Scalars['PqInt64Array']['output'];
  star: Scalars['Int']['output'];
};

export type BingoExtraBallInput = {
  amount: Scalars['Decimal']['input'];
  bet_id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
  round_id: Scalars['ID']['input'];
};

export type BingoOrder = {
  __typename?: 'BingoOrder';
  agency_id: Scalars['ID']['output'];
  amount: Scalars['Decimal']['output'];
  bingo_bet_tickets: Array<BingoBetTickets>;
  bingo_round: BingoRound;
  created_at: Scalars['Time']['output'];
  currency: Scalars['String']['output'];
  extra_numbers: Array<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  player_id: Scalars['ID']['output'];
  player_name: Scalars['String']['output'];
  reward: Scalars['Decimal']['output'];
  round_id: Scalars['ID']['output'];
  wallet_id: Scalars['String']['output'];
};

export type BingoOrderHistory = {
  __typename?: 'BingoOrderHistory';
  data: Array<BingoOrder>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BingoOrderInput = {
  amount: Scalars['Decimal']['input'];
  currency: Scalars['String']['input'];
  game_id: Scalars['String']['input'];
  player_name: Scalars['String']['input'];
  round_id: Scalars['ID']['input'];
  tickets: Array<BingoTicketInput>;
  wallet_id: Scalars['String']['input'];
};

export type BingoRound = {
  __typename?: 'BingoRound';
  client_seed: Scalars['String']['output'];
  game_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  proof: Scalars['String']['output'];
  random_value: Scalars['String']['output'];
  result: Scalars['PqInt64Array']['output'];
  server_seed: Scalars['String']['output'];
  start_time: Scalars['String']['output'];
};

export type BingoTicketInput = {
  numbers: Array<Scalars['Int']['input']>;
  star: Scalars['Int']['input'];
};

export type CrashCurrentRoundOrder = {
  __typename?: 'CrashCurrentRoundOrder';
  amount: Scalars['Decimal']['output'];
  currency: Scalars['String']['output'];
  esc_multiplier: Scalars['Decimal']['output'];
  id: Scalars['ID']['output'];
  player_name: Scalars['String']['output'];
  reward: Scalars['Decimal']['output'];
  timestamp: Scalars['Int']['output'];
};

export type CrashOrder = {
  __typename?: 'CrashOrder';
  agency_id: Scalars['String']['output'];
  amount: Scalars['Decimal']['output'];
  auto_cash_out: Scalars['Decimal']['output'];
  cash_out: Scalars['Decimal']['output'];
  client_seed: Scalars['String']['output'];
  created_at: Scalars['Time']['output'];
  currency: Scalars['String']['output'];
  game_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  multiplier: Scalars['Decimal']['output'];
  player_id: Scalars['ID']['output'];
  proof: Scalars['String']['output'];
  random_value: Scalars['String']['output'];
  reward: Scalars['Decimal']['output'];
  round_id: Scalars['String']['output'];
  server_seed: Scalars['String']['output'];
  wallet_id: Scalars['String']['output'];
};

export type CrashOrderCashOutInput = {
  bet_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  game_id: Scalars['String']['input'];
  round_id: Scalars['ID']['input'];
};

export type CrashOrderHistory = {
  __typename?: 'CrashOrderHistory';
  data?: Maybe<Array<CrashOrder>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CrashResultHistory = {
  __typename?: 'CrashResultHistory';
  last10?: Maybe<Array<Scalars['Int']['output']>>;
  last20?: Maybe<Array<Scalars['Int']['output']>>;
  last30?: Maybe<Array<Scalars['Int']['output']>>;
  last50?: Maybe<Array<Scalars['Int']['output']>>;
  last100?: Maybe<Array<Scalars['Int']['output']>>;
  result?: Maybe<Array<Scalars['Decimal']['output']>>;
};

export type CrashResultHistoryInput = {
  game_id: Scalars['String']['input'];
};

export type CrashRound = {
  __typename?: 'CrashRound';
  client_seed: Scalars['String']['output'];
  end_time: Scalars['Time']['output'];
  extreme_multiplier: Scalars['Decimal']['output'];
  game_id: Scalars['String']['output'];
  multiplier: Scalars['Decimal']['output'];
  proof: Scalars['String']['output'];
  random_value: Scalars['String']['output'];
  round_id: Scalars['String']['output'];
  server_seed: Scalars['String']['output'];
  start_time: Scalars['Time']['output'];
  ultimate_player: Scalars['String']['output'];
};

export type CrashRoundHistory = {
  __typename?: 'CrashRoundHistory';
  data?: Maybe<Array<CrashRound>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CreateCrashOrderInput = {
  agency_id: Scalars['String']['input'];
  amount: Scalars['Decimal']['input'];
  auto_cash_out?: InputMaybe<Scalars['Decimal']['input']>;
  currency: Scalars['String']['input'];
  game_id: Scalars['String']['input'];
  player_name: Scalars['String']['input'];
  round_id: Scalars['String']['input'];
  wallet_id: Scalars['String']['input'];
};

export type FilterInput = {
  game_id: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type HiloBet = {
  __typename?: 'HiloBet';
  bet: BetType;
  created_at: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  multiplier: Scalars['Decimal']['output'];
  round_id: Scalars['ID']['output'];
};

export type HiloCashOutInput = {
  game_id: Scalars['String']['input'];
  round_id: Scalars['String']['input'];
};

export type HiloOrderInput = {
  bet: BetType;
  game_id: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  round_id: Scalars['String']['input'];
};

export type HiloRound = {
  __typename?: 'HiloRound';
  agency_id: Scalars['ID']['output'];
  amount: Scalars['Decimal']['output'];
  client_seed: Scalars['String']['output'];
  created_at: Scalars['Time']['output'];
  currency: Scalars['String']['output'];
  game_id: Scalars['String']['output'];
  hilo_bets: Array<HiloBet>;
  id: Scalars['ID']['output'];
  last_time: Scalars['Time']['output'];
  max_index: Scalars['Int']['output'];
  odds: Scalars['Decimal']['output'];
  pay_for_switch: Scalars['Decimal']['output'];
  player_id: Scalars['ID']['output'];
  player_name: Scalars['String']['output'];
  proof: Scalars['String']['output'];
  random_value: Scalars['String']['output'];
  result: Array<Scalars['String']['output']>;
  reward: Scalars['Decimal']['output'];
  round_status: RoundStatus;
  server_seed: Scalars['String']['output'];
  switch_time: Scalars['Int']['output'];
  treasures?: Maybe<Array<Scalars['Float']['output']>>;
  wallet_id: Scalars['String']['output'];
  win_streak: Scalars['Int']['output'];
};

export type HiloRoundHistory = {
  __typename?: 'HiloRoundHistory';
  data?: Maybe<Array<HiloRound>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HiloRoundInput = {
  agency_id: Scalars['String']['input'];
  amount: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  first_card: Scalars['String']['input'];
  game_id: Scalars['String']['input'];
  player_name: Scalars['String']['input'];
  wallet_id: Scalars['String']['input'];
};

export type HiloSwitchInput = {
  game_id: Scalars['String']['input'];
  index: Scalars['Int']['input'];
  round_id: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  bingoBuyExtraBall: BingoOrder;
  cashOutHiloRound: HiloRound;
  cashOutOrder?: Maybe<Array<CrashOrder>>;
  createBingoOrder: BingoOrder;
  createCrashOrder: CrashOrder;
  createHiloOrder: HiloRound;
  createHiloRound: HiloRound;
  createPlinkoOrder: PlinkoOrder;
  createPlinkoRound: PrePublicPlinkoRound;
  switchHiloOrder: HiloRound;
};


export type MutationBingoBuyExtraBallArgs = {
  input: BingoExtraBallInput;
};


export type MutationCashOutHiloRoundArgs = {
  input: HiloCashOutInput;
};


export type MutationCashOutOrderArgs = {
  input: CrashOrderCashOutInput;
};


export type MutationCreateBingoOrderArgs = {
  input: BingoOrderInput;
};


export type MutationCreateCrashOrderArgs = {
  input: CreateCrashOrderInput;
};


export type MutationCreateHiloOrderArgs = {
  input: HiloOrderInput;
};


export type MutationCreateHiloRoundArgs = {
  input: HiloRoundInput;
};


export type MutationCreatePlinkoOrderArgs = {
  input: PlinkoBetInput;
};


export type MutationSwitchHiloOrderArgs = {
  input: HiloSwitchInput;
};

export type PlinkoBetInput = {
  agency_id: Scalars['String']['input'];
  amount: Scalars['String']['input'];
  balls: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  player_id: Scalars['String']['input'];
  player_name: Scalars['String']['input'];
  risk_level: Scalars['Int']['input'];
  rows: Scalars['Int']['input'];
  wallet_id: Scalars['String']['input'];
};

export type PlinkoMultiplier = {
  __typename?: 'PlinkoMultiplier';
  multipliers: Array<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  risk: Scalars['Int']['output'];
  rows: Scalars['Int']['output'];
};

export type PlinkoOrder = {
  __typename?: 'PlinkoOrder';
  agency_id: Scalars['String']['output'];
  amount: Scalars['Decimal']['output'];
  ball_paths: Array<Scalars['String']['output']>;
  balls: Scalars['Int']['output'];
  client_seed: Scalars['String']['output'];
  created_at: Scalars['Time']['output'];
  currency: Scalars['String']['output'];
  game_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  multipliers: Array<Scalars['String']['output']>;
  player_id: Scalars['String']['output'];
  player_name: Scalars['String']['output'];
  proof: Scalars['String']['output'];
  random_value: Scalars['String']['output'];
  reward: Scalars['Decimal']['output'];
  risk_level: Scalars['Int']['output'];
  rows: Scalars['Int']['output'];
  server_seed: Scalars['String']['output'];
  wallet_id: Scalars['String']['output'];
};

export type PlinkoOrderHistory = {
  __typename?: 'PlinkoOrderHistory';
  data: Array<PlinkoOrder>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PlinkoOrderInput = {
  agency_id: Scalars['String']['input'];
  amount: Scalars['Decimal']['input'];
  ball: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  player_name: Scalars['String']['input'];
  risk_level: Scalars['Int']['input'];
  row: Scalars['Int']['input'];
  wallet_id: Scalars['String']['input'];
};

export type PrePublicPlinkoRound = {
  __typename?: 'PrePublicPlinkoRound';
  game_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  player_id?: Maybe<Scalars['ID']['output']>;
  proof: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  bingoOrderHistory: BingoOrderHistory;
  crashCurrentRoundOrder: Array<CrashCurrentRoundOrder>;
  crashOrdersHistory: CrashOrderHistory;
  crashResultHistory: CrashResultHistory;
  crashRoundHistory: CrashRoundHistory;
  hiloLastRound: HiloRound;
  hiloRoundHistory: HiloRoundHistory;
  plinkoMultiplier: Array<PlinkoMultiplier>;
  plinkoOrderHistory: PlinkoOrderHistory;
  serverTime: ServerTime;
};


export type QueryBingoOrderHistoryArgs = {
  input: FilterInput;
};


export type QueryCrashCurrentRoundOrderArgs = {
  input: FilterInput;
};


export type QueryCrashOrdersHistoryArgs = {
  input: FilterInput;
};


export type QueryCrashResultHistoryArgs = {
  input: CrashResultHistoryInput;
};


export type QueryCrashRoundHistoryArgs = {
  input: FilterInput;
};


export type QueryHiloRoundHistoryArgs = {
  input: FilterInput;
};


export type QueryPlinkoOrderHistoryArgs = {
  input?: InputMaybe<FilterInput>;
};

export type Round = {
  __typename?: 'Round';
  client_seed: Scalars['String']['output'];
  end_time: Scalars['Time']['output'];
  game_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  proof: Scalars['String']['output'];
  random_value: Scalars['String']['output'];
  result: Scalars['String']['output'];
  server_seed: Scalars['String']['output'];
  start_time: Scalars['Time']['output'];
  state: Scalars['String']['output'];
  user?: Maybe<Scalars['ID']['output']>;
};

export type RoundConfig = {
  __typename?: 'RoundConfig';
  game_id: Scalars['String']['output'];
  max_bet_amount: Scalars['Decimal']['output'];
  max_multiple: Scalars['Decimal']['output'];
  max_profit: Scalars['Decimal']['output'];
  min_bet_amount: Scalars['Decimal']['output'];
  round_time?: Maybe<Scalars['Int']['output']>;
  waiting_time?: Maybe<Scalars['Int']['output']>;
};

export enum RoundStatus {
  Lose = 'Lose',
  Running = 'Running',
  Win = 'Win'
}

export type ServerTime = {
  __typename?: 'ServerTime';
  now: Scalars['Time']['output'];
};
