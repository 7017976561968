import { Stack } from '@chakra-ui/react'
import React from 'react';
import FormContainer from '@/bingo/components/FormSection/FormContainer.tsx';
import FormFooter from '@/bingo/components/FormSection/FormFooter.tsx'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'
import TicketList from '@/bingo/components/FormSection/OrderConfirmation/TicketList.tsx'
import { useAppDispatch, useAppSelector } from '@store'
import { bingoActions, selectBingoTicketsGenerated } from '@/bingo/redux/bingo.slice.ts'
import { PiClipboardTextFill } from 'react-icons/pi'
import FooterButtonCircle from '@/bingo/components/FormSection/FooterButtonCircle.tsx'

const YourBet = () => {
  const generatedTickets = useAppSelector(selectBingoTicketsGenerated)
  const dispatch = useAppDispatch()

  const handleBuyMore = () => {
    dispatch(bingoActions.updateCurrentStep('orderConfirmation'))
  }

  return (
    <FormContainer
      title="Your Bet"
    >
      <Stack
        padding="15px 15px 0"
        position="relative"
        flex="1"
        gap="0"
        overflow="hidden"
      >
        <TicketList
          data={generatedTickets}
        />
      </Stack>
      <FormFooter>
        <FooterButtonCircle
          position="absolute"
          top="50%"
          left="20px"
          transform="translateY(-50%)"
        >
          <PiClipboardTextFill />
        </FooterButtonCircle>
        <FormButtonBig
          onClick={handleBuyMore}
        >
          Buy more
        </FormButtonBig>
      </FormFooter>
    </FormContainer>
  )
}

export default YourBet;
