import useCustomToast from '@/hooks/useCustomToast'
import { useAppDispatch } from '@/redux/store'
import { hiloActions } from '@/redux/store/modules/hilo'
import { Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'

export default function TimerHilo({ seconds }: { seconds: number }) {
  const dispatch = useAppDispatch()
  const [timeLeft, setTimeLeft] = useState(seconds)
  const { showToastThrottle } = useCustomToast()
  useEffect(() => {
    const s = Math.floor((seconds % (1000 * 60)) / 1000)
    setTimeLeft(s)
  }, [seconds])

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1)
      }, 1000)
      return () => clearInterval(intervalId)
    }
    if (timeLeft === 0) {
      showToastThrottle({
        title: 'The current round of the game has ended, and the current bets have been canceled.',
        status: 'warning',
        isClosable: true,
        duration: 2000,
        icon: <MdErrorOutline fontSize={40}/>,
        containerStyle: {
          width: '60px',
          paddingBlock: '0px',
          pointerEvents: 'none',
        },
      })
      dispatch(hiloActions.updateIsRoundEnd(true))
      // dispatch(hiloActions.updateMyOrders(gameRound))
    }
  }, [timeLeft])

  return (
    <Stack className="flex items-center justify-center" textAlign="center">
      <Text
        // w="33px"
        fontSize={20}
        lineHeight={1}
        fontWeight={600}
        py={1}
        px={2}
        border="1px solid #fff"
        borderRadius="50%"
      >
        {timeLeft}
      </Text>
    </Stack>
  )
}
