import React from 'react'
import { Stack, Text } from '@chakra-ui/react'
import FormContainer from '@/bingo/components/FormSection/FormContainer.tsx';
import SubTitle from '@/bingo/components/FormSection/SubTitle.tsx'
import TicketSlider from '@/bingo/components/FormSection/RandomTicket/TicketSlider.tsx'
import BingoCurrencyIcon from '@/bingo/components/Common/BingoCurrencyIcon.tsx'
import SubTotal from '@/bingo/components/FormSection/RandomTicket/SubTotal.tsx'
import FormFooter from '@/bingo/components/FormSection/FormFooter.tsx'
import { PiClipboardTextFill } from 'react-icons/pi';
import FooterButtonCircle from '@/bingo/components/FormSection/FooterButtonCircle.tsx'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'
import { useAppDispatch, useAppSelector } from '@store'
import {
  bingoActions,
  selectBingoNumbersOfTickets,
  selectBingoTicketUnitPrice,
} from '@/bingo/redux/bingo.slice.ts'
import { LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { generateBingoCard } from '@/bingo/const/bingoHelpers.ts'
import { defaultMultiplier } from '@/bingo/const/bingoConfigs.ts'

const RandomTicket = () => {
  const numberOfTickets = useAppSelector(selectBingoNumbersOfTickets)
  const ticketUnitPrice = useAppSelector(selectBingoTicketUnitPrice)
  const dispatch = useAppDispatch()

  const handleGenerateTicket = () => {
    const generatedTickets: LocalBingoTicket[] = []

    for (let i = 0; i < numberOfTickets; i++) {
      const bingoTicket: LocalBingoTicket = {
        id: 'local-ticket-' + i,
        multiplier: defaultMultiplier,
        numbers: generateBingoCard(),
        isCollected: false,
      }
      generatedTickets.push(bingoTicket)
    }

    dispatch(bingoActions.updateTicketsGenerated(generatedTickets))
    dispatch(bingoActions.updateCurrentStep('orderConfirmation'))
  }

  return (
    <FormContainer
      title="Random Ticket"
    >
      <Stack
        padding="15px"
        position="relative"
        flex="1"
        gap="0"
      >
        <SubTitle
          marginBottom="10px"
        >
          <Text>Ticket unit price</Text>
          <BingoCurrencyIcon />
          <Text>{ticketUnitPrice}</Text>
        </SubTitle>
        <TicketSlider />
        <SubTotal />
      </Stack>
      <FormFooter>
        <FooterButtonCircle
          position="absolute"
          top="50%"
          left="20px"
          transform="translateY(-50%)"
        >
          <PiClipboardTextFill />
        </FooterButtonCircle>
        <FormButtonBig
          onClick={handleGenerateTicket}
        >
          Generate Ticket
        </FormButtonBig>
      </FormFooter>
    </FormContainer>
  )
}

export default RandomTicket;
