import { Text } from '@chakra-ui/react'
import React from 'react';

type BallNumberProps = {
  value: string | number,
  bg: string,
}

const BallNumber = ({value, bg}: BallNumberProps) => {
  return (
    <Text
      fontSize="20px"
      lineHeight="1"
      fontWeight="600"
      textAlign="center"
      margin="0"
      background={bg}
      backgroundClip="text"
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      userSelect="none"
      sx={{
        '-webkit-text-fill-color': 'transparent',
      }}
    >
      {value}
    </Text>
  )
}

export default BallNumber;
