import React from 'react';
import { Flex, FlexProps, Image, ImageProps } from '@chakra-ui/react'

type AgencyLogoProps = FlexProps & {
  imageProps?: ImageProps,
}

const RocketAgencyLogo = ({imageProps, ...rest}: AgencyLogoProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      marginTop="28px"
      {...rest}
    >
      <Image {...imageProps} />
    </Flex>
  )
}

export default RocketAgencyLogo;
