import React from 'react'
import './styles/result.css'

interface ResultProps {
  multiplier: number
}

const TShowResult: React.FC<ResultProps> = ({ multiplier }) => {
  return (
    <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
      <div className="absolute w-[190px] aspect-square halo-spin">
        <img src="/images/tshows/halo.png" alt="Halo" className="w-full h-full object-contain opacity-50" />
      </div>

      <div className="relative w-[200px] aspect-[440/128] flex flex-col items-center justify-center">
        <img
          src="/images/tshows/img-bg-result.webp"
          alt="Result Background"
          className="absolute w-full h-full object-contain"
        />
        <div className="relative z-10 text-center">
          <div className="text-[28px] mt-3 font-bold leading-tight tracking-wider text-[#fc67ae] [text-shadow:-1px_-1px_0_#fff,1px_-1px_0_#fff,-1px_1px_0_#fff,1px_1px_0_#fff]">
            {multiplier}x
          </div>
          <div className="text-[16px] mt-0.5 text-[#fc67ae] [text-shadow:-1px_-1px_0_#fff,1px_-1px_0_#fff,-1px_1px_0_#fff,1px_1px_0_#fff]">
            Distance
          </div>
        </div>
      </div>
    </div>
  )
}

export default TShowResult
