import { AppDrawer } from '@/components/common/AppDrawer'
import { CopyBtn } from '@/components/common/CopyBtn'
import SoundSetting from '@/components/hash-game/settings/SoundSetting'
import SelectMenuPopup from '@/components/UI/SelectMenuPopup'
import { bgColorApp } from '@/const/color'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { selectPlinkoRound } from '@/redux/store/modules/plinko.slice'
import { Box, Button, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useAppSelector } from '@store/index.ts'
import React, { ReactNode } from 'react'
import { LuFileQuestion } from 'react-icons/lu'
import { TfiMenuAlt } from 'react-icons/tfi'
import { BettingHistory } from './BettingHistory'
import { FairnessPopup } from './FairnessPopup'

interface Option {
  value: string
  label: string
  headingPopup?: string
  content: ReactNode
}

export default function ControlSection() {
  const { t } = useCustomTranslation()
  const { proof } = useAppSelector(selectPlinkoRound) || {}
  const sortLotteryResultHash = getShortHash(proof || '')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const options1: Option[] = [
    {
      value: 'BettingRecord',
      label: 'bettingRecord',
      headingPopup: 'bettingListing',
      content: (
        <Box p={4}>
          <BettingHistory />
        </Box>
      ),
    },
    { value: 'SoundSetting', label: 'soundSetting', content: <SoundSetting /> },
    {
      value: 'Gameplay',
      label: 'gamePlay',
      content: <FairnessPopup />,
    },
    {
      value: 'Verification',
      label: 'verification',
      content: <FairnessPopup />,
    },
    // {
    //   value: 'RuleGame',
    //   label: 'ruleGame',
    //   content: <RuleGame />,
    // },
  ]

  return (
    <Stack
      p={2}
      borderRadius={8}
      background={bgColorApp}
      backgroundSize="cover"
      backgroundPosition="top center"
      backgroundImage="/images/bgBeehive.png"
      textAlign="center"
    >
      <Flex gap={1} mt={-1} alignItems="center" justifyContent="space-between">
        <Button
          aria-label="AppDrawer"
          aria-labelledby="AppDrawer"
          m={0}
          p={0}
          background="none"
          _hover={{ background: 'none' }}
          onClick={() => {
            const searchParams = new URLSearchParams(location.search)
            searchParams.set('param', 'Gameplay')
            onOpen()
          }}
        >
          <LuFileQuestion color="#891333" />
        </Button>
        <Flex gap={2} justifyContent="center" alignItems="center" textAlign="center">
          <Text fontWeight={700} fontSize={'16px'}>
            {sortLotteryResultHash}
          </Text>
          <CopyBtn text={proof ?? ''} colorClassName="text-[#891333]" />
        </Flex>
        <SelectMenuPopup options={options1} icon={<TfiMenuAlt color="#891333" fontSize={22} />} />
      </Flex>
      <Text fontSize={'11px'} mt={-4} fontWeight={400} color="#D83467">
        {t('ourBureauPrizeHash')}
      </Text>
      <AppDrawer title={t('menu.bettingListing')} isOpen={isOpen} onClose={onClose} overlay>
        <FairnessPopup popup="Gameplay" />
      </AppDrawer>
    </Stack>
  )
}
function getShortHash(text: string) {
  if (!text) return ''
  const length = text.length
  if (length <= 20) return text
  return `${text.slice(0, 10)}...${text.slice(length - 8, length)}`
}
