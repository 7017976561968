import { ErrorHandlerWrapper } from '@/components/common/ErrorHandlerWrapper'
import { SyncBetweenTabs } from '@/components/common/SyncBetweenTabs'
import Breadcrumb from '@/components/hash-game/Breadcrumb'
import Notification from '@/components/hash-game/Notification'
import { bgColorApp } from '@/const/color.ts'
import Connector from '@/hooks/Connector.tsx'
import useCustomTranslation from '@/hooks/useCustomTranslation.tsx'
import useGameId from '@/hooks/useGameId.ts'
import { Stack } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { login, selectIsLogged } from '@store/modules/auth.slice'
import gameSlice from '@store/modules/game.slice'
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function AuthGame({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch()
  const isLogged = useAppSelector(selectIsLogged)
  const game_id = useGameId()

  const navigate = useNavigate()

  const [params] = useSearchParams()
  const agency_id = params.get('agency_id') || ''
  const session_token = params.get('session_token') || ''
  const access_token = params.get('access_token') || ''
  const isRelogin = !!(agency_id && session_token) || !!access_token
  const [isLogging, setLogging] = useState(isRelogin)
  const { t } = useCustomTranslation()

  useEffect(() => {
    if (!isLogged && !isLogging) {
      const message = t('home.error.ErrSessionInvalid')
      navigate(`/${game_id}/error`, {
        replace: true,
        state: {
          message,
        },
      })
    }
  }, [isLogged, isLogging])

  useEffect(() => {
    dispatch(gameSlice.actions.updateGameId(game_id))
  }, [game_id])

  useEffect(() => {
    if (!isRelogin) return
    setLogging(true)
    dispatch(login({ agency_id, session_token, access_token }))
      .then((resp) => {
        if (resp.meta.requestStatus === 'fulfilled') {
          const newParams = new URLSearchParams(params)
          newParams.delete('agency_id')
          newParams.delete('session_token')
          newParams.delete('access_token')
          newParams.delete('currency')
          navigate(`?${newParams.toString()}`, { replace: true })
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLogging(false)
        }, 500)
      })
  }, [agency_id, session_token])

  return (
    <Connector>
      <Stack w="100%" color="white" backgroundColor={bgColorApp} gap={3} position="relative" overflowX="hidden">
        <ErrorHandlerWrapper />
        <SyncBetweenTabs />
        <Notification />

        <Breadcrumb />
        {isLogged && !isLogging && <>{children}</>}
      </Stack>
    </Connector>
  )
}
