import React from 'react';
import {
  Table,
  TableBodyProps,
  TableCaption, TableCaptionProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

export type RocketTableColumns = string[]
export type RocketTableCell = TableCellProps & {
  value: string | React.ReactNode,
}
export type RocketTableRow = RocketTableCell[]

type RocketThProps = TableColumnHeaderProps
type RocketTbodyTrProps = TableRowProps
type RocketTdProps = TableCellProps
type RocketTableProps = {
  tableContainerProps?: TableContainerProps,
  tableCaptionProps?: TableCaptionProps,
  tableProps?: TableProps,
  theadProps?: TableHeadProps,
  headTrProps?: TableRowProps,
  thProps?: TableColumnHeaderProps,
  tbodyProps?: TableBodyProps,
  bodyTrProps?: TableRowProps,
  tdProps?: TableCellProps,
  columns?: RocketTableColumns,
  rows?: RocketTableRow[],
  tableCaption?: string | React.ReactNode,
}

const RocketTh = ({children, ...rest}: RocketThProps) => {
  return (
    <Th
      background="#212834"
      color="#50637f"
      lineHeight="1"
      padding="6.6px"
      textAlign="center"
      {...rest}
    >
      {children}
    </Th>
  )
}

const RocketTbodyTr = ({children, ...rest}: RocketTbodyTrProps) => {
  return (
    <Tr
      _even={{
        background: '#1b222c',
      }}
      {...rest}
    >
      {children}
    </Tr>
  )
}

const RocketTd = ({children, ...rest}: RocketTdProps) => {
  return (
    <Td
      padding="10.6px 16px"
      textAlign="center"
      color="#647c9f"
      lineHeight="1"
      sx={{
        '&.isGreen': {
          color: '#0df323',
        },
        '&.isRed': {
          color: '#b0414e',
        },
      }}
      {...rest}
    >
      {children}
    </Td>
  )
}

const RocketTable = ({
  tableContainerProps,
  tableCaptionProps,
  tableProps,
  theadProps,
  headTrProps,
  thProps,
  tbodyProps,
  bodyTrProps,
  tdProps,
  columns,
  rows,
  tableCaption,
}: RocketTableProps) => {
  return (
    <TableContainer {...tableContainerProps}>
      <Table variant="unstyled" {...tableProps}>
        {tableCaption && (
          <TableCaption
            color="#45556d"
            margin="0"
            padding="21.3px"
            {...tableCaptionProps}
          >
            {tableCaption}
          </TableCaption>
        )}
        {columns && (
          <Thead {...theadProps}>
            <Tr {...headTrProps}>
              {columns?.length && columns?.map((column) => (
                <RocketTh {...thProps} key={column}>{column}</RocketTh>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody {...tbodyProps}>
          {rows?.length && rows?.map((row, rowIndex) => (
            <RocketTbodyTr {...bodyTrProps} key={rowIndex}>
              {row?.map((cell, cellIndex) => {
                const cellClassNames = `${tdProps?.className} ${cell?.className}`
                return (
                  <RocketTd {...tdProps} className={cellClassNames} {...cell} key={cellIndex}>{cell.value}</RocketTd>
                )
              })}
            </RocketTbodyTr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default RocketTable;
