import useGameId from '@/hooks/useGameId.ts'
import { selectBetAmountByGame } from '@/redux/store/modules/preference.slice.ts'
import { Box, Button, Container, Flex, Input, InputGroup, InputLeftElement, Stack, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import { setCoinPosition } from '@store/modules/animation.slice'
import { selectGameConfigs } from '@store/modules/game.slice'
import orderSlice, { selectCreateOrderStatus, selectOrderAmount, selectOrderBetType } from '@store/modules/order.slice'
import { selectWallet } from '@store/modules/wallet.slice.ts'
import { gsap } from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { bgColorApp, bgInput, textGray, textGrayWhite } from '../../const/color'
import { currency } from '../../const/currency'
import { formatNumberInput } from '../../const/format.ts'
import { gameType } from '../../const/game-type.ts'
import useCustomTranslation from '../../hooks/useCustomTranslation.tsx'
import { delay } from '../../utils/time.ts'
import ButtonBetting from '../UI/ButtonBetting'

function Denominations({ valueBet }) {
  const [inputValue, setInputValue] = valueBet
  const amount = useAppSelector(selectOrderAmount)
  const config = useAppSelector(selectGameConfigs)
  const amountButtons = config.betAmounts
    ? config.betAmounts.split(',').map(Number)
    : [10, 50, 100, 200, 500, 1000, 10000]
  // const balance = useAppSelector((state: RootState) => state.balance)
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const handleIncrement = (increment: any) => {
    if (audioRef.current) {
      audioRef.current.play()
    }
    setInputValue((prev: any) => {
      const newValue = Number(prev) + increment
      return newValue <= config.maxAmount ? newValue : prev
    })
  }

  return (
    <Flex
      flexWrap="nowrap"
      overflowX="auto"
      gap={2}
      marginBottom={4}
      marginTop="13px"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '-ms-overflow-style': 'none', // IE and Edge
        'scrollbar-width': 'none', // Firefox
      }}
    >
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      {amountButtons.map((_amount) => (
        <Button
          aria-label="Amount"
          aria-labelledby="Amount"
          key={_amount}
          height={'30px'}
          minWidth="70px"
          fontSize="1rem"
          backgroundColor={bgColorApp}
          borderRadius={8}
          color={textGray}
          transition="0.2s all"
          fontWeight={500}
          isActive={amount === _amount}
          _hover={{ backgroundColor: '#29292f', color: '#fff' }}
          _active={{
            backgroundColor: '#4e4f50',
            color: '#b2fc00',
            textShadow: '0px 0px 10px #b2fc00',
            fontWeight: 600,
          }}
          // onClick={() => dispatch(orderSlice.actions.amountUpdated(_amount))}
          onClick={() => handleIncrement(_amount)}
          lineHeight={1}
        >
          <Box
            as="span"
            // position="relative"
            // bottom="-1px"
          >
            {_amount}
          </Box>
        </Button>
      ))}
    </Flex>
  )
}

const createParticleEffect = (typeBet: string, typeEffect: string, currency: string = 'USDT') => {
  const type = document.querySelector(`.type_${typeBet}`)

  if (!type) {
    console.warn('No element with the class ".type" found.')
    return
  }
  const particleContainer = document.createElement('div')
  particleContainer.style.position = 'absolute'
  particleContainer.style.top = '0'
  particleContainer.style.left = '50%'
  particleContainer.style.transform = 'translate(-50%, -50%)'
  particleContainer.style.pointerEvents = 'none'
  particleContainer.style.zIndex = '1000'
  type.appendChild(particleContainer)
  for (let i = 0; i < 30; i++) {
    const particle = document.createElement('div')
    const currencyIcon = document.createElement('img')
    currencyIcon.src = `/images/currencies/${currency}.svg`
    currencyIcon.style.width = '20px'
    currencyIcon.style.height = '20px'
    particle.style.width = '20px'
    particle.style.height = '20px'
    // particle.innerHTML = `${currency}`
    particle.append(currencyIcon)
    particle.style.position = 'absolute'
    particle.style.fontSize = '12px'
    particleContainer.appendChild(particle)
    gsap.to(particle, {
      x: (Math.random() - 0.5) * 80,
      y: -(Math.random() - 0.4) * 60,
      opacity: 0,
      scale: 0.5,
      duration: 1,
      ease: 'expoScale(0.2,2,none)',
      onComplete: () => particle.remove(),
    })
  }

  setTimeout(() => particleContainer.remove(), 600)
}

export default function Betting() {
  const { t } = useCustomTranslation()
  const amount = useAppSelector(selectOrderAmount)
  const configRule = useAppSelector(selectGameConfigs)
  const totalCoin = 5
  //input nhập tiền để betting
  const [inputValue, setInputValue] = useState(amount + '')
  const dispatch = useAppDispatch()
  const wallet = useAppSelector(selectWallet)
  const gameID = useGameId()
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const createOrderStatus = useAppSelector(selectCreateOrderStatus)

  const [particleTrigger, setParticleTrigger] = useState(false)

  const betType = useAppSelector(selectOrderBetType)
  const coins = useRef([])

  const betAmount = useAppSelector(selectBetAmountByGame(gameID))

  useEffect(() => {
    if (betAmount) {
      setInputValue(betAmount + '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '')
    const value = parseInt(inputValue) || 0
    let betAmountValue = 0
    if (value >= configRule.maxAmount) {
      betAmountValue = configRule.maxAmount
    } else {
      betAmountValue = value
    }
    setInputValue(betAmountValue.toString())
  }
  useEffect(() => {
    if (amount != (parseInt(inputValue) || 0)) {
      setInputValue(amount + '')
    }
  }, [amount])

  useEffect(() => {
    if (!configRule.minAmount) return

    setInputValue(configRule.minAmount + '')
  }, [configRule])

  useEffect(() => {
    dispatch(orderSlice.actions.amountUpdated(inputValue))
  }, [inputValue])

  useEffect(() => {
    if (coins.current[0]) {
      const rect = coins.current[0].getBoundingClientRect()
      dispatch(setCoinPosition({ x: rect.left, y: rect.top }))
    }
  }, [dispatch])

  useEffect(() => {
    if (createOrderStatus === 'succeeded') {
      setParticleTrigger(true)
      setTimeout(() => setParticleTrigger(false), 2000)
    }
  }, [createOrderStatus])

  useEffect(() => {
    if (particleTrigger) {
      const coinElement = coins.current[0]

      if (coinElement) {
        const flyCoin = (coinElement) => {
          // gsap.set(coinElement, {
          //   x: 10,
          //   y: 15,
          // })

          const type = gameType.find((item) => item.id === gameID)?.type[0]
          gsap.to(coinElement, {
            x: betType === type ? (window.innerWidth < 768 ? -140 : -160) : window.innerWidth < 768 ? 50 : 90,
            y: betType === type ? (window.innerWidth < 768 ? -100 : -120) : window.innerWidth < 768 ? -100 : -140,
            duration: 1.2,
            ease: 'power2.out',
            opacity: 0.4,
            rotate: '360deg',
            scale: 1.4,
          })
        }

        ;(async () => {
          for (let i = 0; i < totalCoin; i++) {
            const coinElement = coins.current[i]
            flyCoin(coinElement)
            await delay(300)
          }
        })()

        const type = gameType.find((item) => item.id === gameID)?.type[0] ?? 'BIG'

        setTimeout(async () => {
          for (let i = 0; i < totalCoin; i++) {
            createParticleEffect(betType, type, wallet.currency)
            await delay(300)
          }
        }, 300)
      }
    }
  }, [particleTrigger])

  return (
    <Container>
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      <Flex position="relative" alignItems="center" justifyContent="space-between">
        <Stack w="45%" gap="10px" fontWeight={400}>
          <Flex alignItems="baseline">
            <Text color={textGray} fontSize={'12px'} letterSpacing="0.4px">
              {t('bettingLimits')}:
            </Text>
            <Text marginInline={2} color={textGrayWhite} fontWeight={500} fontSize="13px">
              {configRule.minAmount} ~ {formatNumberNoDecimal(configRule.maxAmount)}
            </Text>
          </Flex>
          <Flex alignItems="baseline" mt={'-12px'}>
            <Text color={textGray} fontSize={'12px'} letterSpacing="0.4px">
              {t('maximumWin')}:{' '}
            </Text>
            <Text marginInline={2} color={textGrayWhite} fontWeight={500} fontSize="13px">
              {formatNumber(amount * configRule.winRate)}
            </Text>
          </Flex>
        </Stack>

        <Stack w="50%">
          <Flex
            backgroundColor={bgInput}
            alignItems="center"
            justifyContent="space-between"
            p={1}
            border="1px solid #29292f"
            borderRadius={8}
            height="33px"
          >
            <button
              aria-label="Minus"
              aria-labelledby="Minus"
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.play()
                }
                setInputValue((prev) => {
                  const currentValue = Number(prev)

                  const digitCount = currentValue.toString().length

                  const smallestNumber = 10 ** (digitCount - 1)

                  const munis = 10 ** (digitCount - 1) - 10 ** (digitCount - 2)
                  const largestNumberWithOneDigitLess = munis < configRule.minAmount ? configRule.minAmount : munis

                  const newValue = Math.max(smallestNumber - 10 ** (digitCount - 1), currentValue - smallestNumber)

                  const resultValue =
                    newValue < largestNumberWithOneDigitLess ? largestNumberWithOneDigitLess : newValue
                  return resultValue
                })
              }}
              style={{ padding: '3px', background: '#29292f', color: '#68686f', borderRadius: 4 }}
            >
              <FaMinus />
            </button>

            <InputGroup position="relative">
              <InputLeftElement top="50%" transform="translateY(-50%)">
                {currency.find((i) => i.name === wallet.currency)?.icon}
              </InputLeftElement>
              {particleTrigger &&
                Array(totalCoin)
                  .fill(null)
                  .map((i, idx) => (
                    <Stack
                      key={idx}
                      pointerEvents="none"
                      ref={(el: any) => (coins.current[idx] = el)}
                      position="absolute"
                      top={3}
                      left={6}
                      zIndex={1000}
                    >
                      {currency.find((i) => i.name === wallet.currency)?.icon}
                    </Stack>
                  ))}
              <Input
                name={"bet-amount"}
                data-value={inputValue}
                max={configRule.maxAmount}
                value={formatNumberInput(inputValue)}
                onChange={handleChange}
                textAlign="center"
                placeholder="Bet Amount"
                _placeholder={{ fontWeight: 500, color: '#2e2e2e', fontSize: 12 }}
                type="text"
                fontSize={18}
                fontWeight={700}
                border="none"
                outline="none !important"
                boxShadow="none !important"
                position="relative"
                inputMode="decimal"
              />
            </InputGroup>
            <button
              aria-label="Add"
              aria-labelledby="Add"
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current.play()
                }
                setInputValue((prev) => {
                  const increment = smallestSameDigitCount(Number(prev))
                  let newValue = Number(prev) + increment
                  if (newValue < 10) {
                    newValue = 10
                  }
                  return newValue <= configRule.maxAmount ? newValue : configRule.maxAmount
                })
              }}
              style={{ padding: 3, background: '#29292f', color: '#68686f', borderRadius: 4 }}
            >
              <FaPlus />
            </button>
          </Flex>
        </Stack>
      </Flex>

      <Denominations valueBet={[inputValue, setInputValue]} />
      <ButtonBetting />
    </Container>
  )
}

function smallestSameDigitCountMinus(number: number) {
  // Convert the number to a string to get the digit count
  const numStr = number.toString()
  const digitCount = numStr.length

  // Return the smallest number with the same number of digits
  return Math.pow(10, digitCount - 1)
}

function smallestSameDigitCount(number: number) {
  // Chuyển số thành chuỗi để xác định số lượng chữ số
  const numStr = number.toString()

  // Lấy số lượng chữ số của số đã cho
  const digitCount = numStr.length

  // Tạo số nhỏ nhất có cùng số lượng chữ số
  const smallestNumber = Math.pow(10, digitCount - 1)
  return smallestNumber
}

export const formatNumberNoDecimal = (amount: number | undefined | string): string | 0 => {
  if (!amount) return 0
  const re = '\\d(?=(\\d{3})+$)'
  const rounding = Math.round(Number(amount))
  return `${rounding.toString().replace(new RegExp(re, 'g'), '$&,')}`
}
export const formatNumber = (amount: number | undefined | string): string | 0 => {
  if (!amount) return '0.00'

  const numberAmount = Number(amount)
  const roundedAmount = numberAmount.toFixed(2)

  const re = /(\d)(?=(\d{3})+\.)/g
  return roundedAmount.replace(re, '$1,')
}
