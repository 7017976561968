import React from 'react'
import GroupColumn from '@/bingo/components/FormSection/EditTicket/CurrentTicket/GroupColumn.tsx'
import { Box, BoxProps, Flex } from '@chakra-ui/react'
import { bingoGroups, bingoNumbers } from '@/bingo/const/bingoGroupsAndNumbers.ts'
import NumberCell from '@/bingo/components/FormSection/EditTicket/NumbersSelect/NumberCell.tsx'
import { BingoGroup } from '@/bingo/types/bingo.ts'

type NumbersSelectProps = BoxProps & {
  currentGroup: BingoGroup,
}

const NumbersSelect = ({currentGroup}: NumbersSelectProps) => {
  return (
    <Box
      borderRadius="12px"
      overflow="hidden"
      width="250px"
    >
      <Flex>
        {bingoGroups.map((group) => (
          <GroupColumn group={group} key={group} isActive={currentGroup === group} showData={false} />
        ))}
      </Flex>
      <Flex
        flexWrap="wrap"
        gap="2px"
      >
        {bingoNumbers[currentGroup].map((bingoNumber) => (<NumberCell value={bingoNumber} />))}
      </Flex>
    </Box>
  )
}

export default NumbersSelect;
