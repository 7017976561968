import { useEffect, useRef, useState, useCallback } from 'react'
import gsap from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { useAppSelector } from '@/redux/store'
import {
  selectCashoutOrders,
  selectGameRound,
  selectListActiveOrders,
  selectMultiplier,
  selectMyRoundOrders,
} from '@/redux/store/modules/rocketTshow'
import { selectDiffTime } from '@/redux/store/modules/game.slice'

gsap.registerPlugin(MotionPathPlugin)

interface UseRocketAnimationProps {
  modelRef: React.RefObject<HTMLDivElement>
  containerRef: React.RefObject<HTMLDivElement>
  onMultiplierUpdate?: (multiplier: number) => void
  onCrash?: () => void
}

export type GameStateEnum = 'WAITING' | 'PLAYING' | 'ENDED' | 'REWARD' | 'INITIAL'

export type RoundReward = {
  maxMultiplier?: string
  rewards?: { currency?: string; amount?: number }[]
}

// Map socket game state to internal state
const mapSocketStateToGameState = (socketState: string): GameStateEnum => {
  switch (socketState.toUpperCase()) {
    case 'RUNNING':
      return 'PLAYING'
    case 'END':
      return 'ENDED'
    case 'WAITING':
    default:
      return 'WAITING'
  }
}

const topCashoutInitState = [
  { id: 1, name: '', multiplier: 0 },
  { id: 2, name: '', multiplier: 0 },
  { id: 3, name: '', multiplier: 0 },
]

export const useTShowGamePlay = ({ modelRef, containerRef }: UseRocketAnimationProps) => {
  const [gameState, setGameState] = useState<GameStateEnum>('INITIAL')
  const gameStateRef = useRef<GameStateEnum>('WAITING')
  const startTimeRef = useRef<number>(new Date().getTime() + 7000)
  const multiplierRef = useRef(1)

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  const gameRound = useAppSelector(selectGameRound)
  const listActiveOrders = useAppSelector(selectListActiveOrders)

  const roundMultiplier = useAppSelector(selectMultiplier)
  const cashoutOrders = useAppSelector(selectCashoutOrders)
  const cashoutOrderHandled = useRef<{ [key: number | string]: boolean }>({})
  const [topCashoutOrder, setTopCashoutOrder] = useState(topCashoutInitState)

  const myRoundOrders = useAppSelector(selectMyRoundOrders)

  const [rewardInfo, setRewardInfo] = useState<RoundReward | null>()
  const isWinnerRef = useRef(false)
  const [countdown, setCountdown] = useState<number>(-1)
  const [showWinAnim, setShowWinAnim] = useState(false)
  const diffTime = useAppSelector(selectDiffTime)
  const diffTimeRef = useRef(diffTime)

  useEffect(() => {
    console.log('gameRound: ', gameRound)
  }, [gameRound])

  useEffect(() => {
    console.log('diffTime: ', diffTime)
    diffTimeRef.current = diffTime
  }, [diffTime])

  useEffect(() => {
    if (gameState === 'WAITING') {
      isWinnerRef.current = false
      setRewardInfo(null)
      setTopCashoutOrder(topCashoutInitState)
      return
    }

    const _rewardOrders = [...myRoundOrders]
      .filter((order) => Number(order.reward) > 0)
      .sort((a, b) => b.reward - a.reward)

    const rewardOrder = _rewardOrders?.length > 0 ? _rewardOrders[0] : null
    console.log('rewardOrder: ', rewardOrder)

    if (rewardOrder) {
      setRewardInfo({
        maxMultiplier: Number(rewardOrder.cash_out) ? rewardOrder.cash_out : rewardOrder.auto_cash_out,
        rewards: _rewardOrders.reduce((acc: { currency?: string; amount?: number }[], order) => {
          const currency = order.currency
          const amount = Number(order.reward)
          const existingOrder = acc.find((o) => o.currency === currency)
          if (existingOrder) {
            existingOrder.amount = (existingOrder.amount || 0) + amount
          } else {
            acc.push({ currency, amount })
          }
          return acc
        }, []),
      })
    }

    if (isWinnerRef.current) {
      return
    }

    if (rewardOrder) {
      isWinnerRef.current = true
    }
  }, [myRoundOrders, gameState, gameRound.round_id])

  // console.log('isWinner', isWinner)

  const triggerCashout = useCallback(
    (orderId: string, multiplier: number) => {
      if (!modelRef.current || !containerRef.current) {
        console.log('not exist rocket or container')
        return
      }

      if (cashoutOrderHandled.current[orderId]) {
        return
      }

      cashoutOrderHandled.current[orderId] = true

      // Create heart element
      const heart = document.createElement('img')
      heart.src = '/images/tshows/ic_heart.png'
      heart.style.position = 'absolute'
      heart.style.width = '32px'
      heart.style.height = '32px'
      heart.style.zIndex = '1000'
      containerRef.current.appendChild(heart)

      // Get start position from modelRef
      const modelRect = modelRef.current.getBoundingClientRect()
      const containerRect = containerRef.current.getBoundingClientRect()
      const startX = modelRect.left - containerRect.left + modelRect.width / 2
      const startY = modelRect.top - containerRect.top + modelRect.height / 3

      // Create zigzag path
      const endY = startY - 200 // Final position 200px above
      const path = `M ${startX},${startY} C ${startX - 100},${startY - 70} ${startX + 50},${
        startY - 140
      } ${startX},${endY}`

      // Animate heart
      gsap.set(heart, { x: startX, y: startY })
      gsap.to(heart, {
        duration: 3,
        ease: 'power1.out',
        motionPath: {
          path: path,
        },
        scale: 0.5,
        opacity: 0,
        onComplete: () => {
          heart.remove()
        },
      })
    },
    [containerRef, modelRef],
  )

  useEffect(() => {
    if (!cashoutOrders?.length) return

    // only triggerCashout only last 3 orders
    cashoutOrders.slice(-3).forEach((order) => {
      triggerCashout(order.id, Number(order.esc_multiplier))
    })
    const topOrders = [...cashoutOrders].sort((a, b) => Number(b.esc_multiplier) - Number(a.esc_multiplier))
    setTopCashoutOrder([
      { id: 1, name: topOrders[0]?.player_name, multiplier: Number(topOrders[0]?.esc_multiplier || '0') },
      { id: 2, name: topOrders[1]?.player_name, multiplier: Number(topOrders[1]?.esc_multiplier || '0') },
      { id: 3, name: topOrders[2]?.player_name, multiplier: Number(topOrders[2]?.esc_multiplier || '0') },
    ])
  }, [cashoutOrders, triggerCashout])

  // useEffect(() => {
  //   setInterval(() => {
  //     triggerCashout(new Date().getTime().toString(), 0)
  //   }, 500)
  // }, [triggerCashout])

  useEffect(() => {
    const containerRect = containerRef.current?.getBoundingClientRect()
    const _containerWidth = containerRect?.width
    const _containerHeight = containerRect?.height

    setContainerSize({
      width: _containerWidth ?? 0,
      height: _containerHeight ?? 0,
    })
  }, [containerRef, setContainerSize])

  // Animation walk for model (người mẫu)
  const createModelWalkAnimation = useCallback(() => {
    if (!modelRef.current) {
      console.log('modelRef not exist')
      return
    }

    // Reset model position and properties
    modelRef.current.style.opacity = '1'

    modelRef.current?.classList.add('model-walk')
  }, [modelRef])

  // Update game state when gameRound changes
  useEffect(() => {
    if (!gameRound?.state) return

    const currentState = mapSocketStateToGameState(gameRound.state)
    const startTime = new Date(gameRound.start_time).getTime()
    console.log('currentState: ', currentState)
    console.log('logTime: ', new Date().toISOString())

    gameStateRef.current = currentState
    startTimeRef.current = startTime
    setGameState(currentState)
  }, [gameRound])

  // Handle countdown timer
  useEffect(() => {
    if (gameState !== 'WAITING' || countdown <= 0) return

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 0) {
          clearInterval(timer)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [gameState, countdown])

  useEffect(() => {
    console.log('____gameState: ', gameState)

    const handleGameStateChange = () => {
      switch (gameState) {
        case 'WAITING':
          handleWating()
          break
        case 'PLAYING':
          handleGameStart()
          break
        case 'ENDED':
          handleGameEnd()
          break
        default:
          break
      }
    }

    const resetAllGameState = (includeRocket = true) => {
      if (includeRocket && modelRef.current) {
        modelRef.current.style.opacity = '0'
      }
      multiplierRef.current = 1
      modelRef.current?.classList.remove('model-walking')
      modelRef.current?.classList.remove('model-walkend')
      setCountdown(-1)
      setShowWinAnim(false)
    }

    const handleWating = () => {
      // reset info
      resetAllGameState()
      const countdownDuration = Math.floor((startTimeRef.current - (new Date().getTime() + diffTimeRef.current)) / 1000)
      setCountdown(Math.max(countdownDuration, 0))
    }

    const handleGameStart = () => {
      createModelWalkAnimation()
    }

    const handleGameEnd = () => {
      if (!modelRef.current) return

      resetAllGameState(false)

      modelRef.current?.classList.add('model-walkend')

      const handleRewardState = () => {
        modelRef.current?.removeEventListener('animationend', handleRewardState)

        setShowWinAnim(true)

        if (modelRef.current) {
          modelRef.current.style.opacity = '0'
        }

        // remove current animation, show stop animation

        const showRewardState = () => {
          if (gameStateRef.current !== 'ENDED') {
            return
          }

          setGameState('REWARD')
        }

        if (isWinnerRef.current) {
          // After 3 seconds, show top winner
          setTimeout(() => {
            showRewardState()
          }, 3000)
        } else {
          showRewardState()
        }
      }

      modelRef.current.addEventListener('animationend', handleRewardState)
    }

    handleGameStateChange()
  }, [gameState, createModelWalkAnimation, setCountdown, modelRef])

  return {
    gameState,
    gameRound,
    containerSize,
    countdown,
    roundMultiplier,
    topCashoutOrder,
    listActiveOrders,
    rewardInfo,
    showWinAnim,
    resetAnimation: () => {
      if (modelRef.current) {
        gsap.set(modelRef.current, {
          clearProps: 'all',
        })
      }
    },
  }
}
