import React from "react";

export const btnClickEffect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  if (!e) {
    return
  }

  const currentTarget = e.currentTarget
  const classList = currentTarget.classList

  const animationEndListener = () => {
    classList.remove('btn-click')
    currentTarget.removeEventListener('animationend', animationEndListener)
  }

  if (!classList.contains('btn-click')) {
    classList.add('btn-click')
  }

  currentTarget.addEventListener('animationend', animationEndListener)
}