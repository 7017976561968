import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React, { ReactNode, useState } from 'react'

interface Option {
  value: string
  label: string
  icon: ReactNode | string
}

export default function SelectLanguage({
  options,
  onSelect,
  defaultValue,
}: {
  options: any
  onSelect: any
  defaultValue: string
}) {
  const [selectedOption, setSelectedOption] = useState<Option>(
    options.find((option: Option) => option.value === defaultValue),
  )

  const updateUrlParameter = (key: string, value: string) => {
    const url = new URL(window.location.href)
    url.searchParams.set(key, value)
    window.history.pushState({}, '', url.toString())
  }

  const handleSelect = (option: Option) => {
    setSelectedOption(option)
    onSelect(option)
    updateUrlParameter('lang', option.value)
  }

  return (
    <Menu matchWidth={true} isLazy={true} lazyBehavior="unmount">
      <MenuButton
        role="button"
        aria-expanded="false"
        aria-haspopup="menu"
        aria-controls="menu-list-:rc:"
        backgroundColor="#18181b"
        paddingInline={0}
        m={0}
        width="26px"
        minWidth="26px"
        height="18px"
        _active={{ background: 'none' }}
        _hover={{ background: 'none' }}
        as={Button}
        defaultValue={defaultValue}
        alignItems="center"
      >
        <Flex alignItems="center" gap={2}>
          {selectedOption?.icon}
        </Flex>
      </MenuButton>
      <MenuList maxWidth="130px" minWidth="130px">
        {options.map((option: { value: string; icon: ReactNode | string; label: string }) => (
          <MenuItem
            bg={option.value === defaultValue ? '#FFFFD4' : 'transparent'}
            w="100%"
            color="white"
            key={option.value}
            onClick={() => handleSelect(option)}
          >
            <Flex alignItems="center" gap={2} color="#333">
              <Box width={6}>{option.icon}</Box>
              <Text>{option.label}</Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
