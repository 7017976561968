import { PlinkoOrder } from '@/@generated/gql/graphql-hash'
import CopyAll from '@/components/common/CopyAll'
import { CopyBtn } from '@/components/common/CopyBtn'
import { textGrayDrawer } from '@/const/color'
import { currencyMap, getSymbolFromCurrency } from '@/const/currency'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { prettyPrintWallet } from '@/utils/number'
import { Box, Button, Flex, Image, Link, Stack, Tag, TagLabel, Text, WrapItem } from '@chakra-ui/react'
import * as CryptoJS from 'crypto-js'
import dayjs from 'dayjs'
import { map } from 'lodash'
import 'rc-pagination/assets/index.css'
import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { convertHashToLR } from '../../utils/game'

function RenderAmountRound({ order }: { order: PlinkoOrder }) {
  const winAmount = getWinAmount(order)
  return (
    <Text fontSize={32} fontWeight={600} color={winAmount >= 0 ? '#B0FC00' : '#ff5959'}>
      {winAmount > 0 ? '+' : winAmount < 0 ? '-' : ''} {prettyPrintWallet(Math.abs(winAmount))}
    </Text>
  )
}

function getWinAmount(order: PlinkoOrder) {
  const { reward, amount, balls } = order
  return +reward - +amount * balls
}

interface BetDetailProps {
  order: PlinkoOrder
}

function countOrderRates(order: PlinkoOrder) {
  const rates: Record<string, number> = {}
  order?.multipliers?.forEach((mul) => {
    rates[mul] = rates[mul] || 0
    rates[mul]++
  })
  const balls = map(rates, (count, rate) => ({ count, rate }))
  balls.sort((a, b) => +b.rate - +a.rate)
  return balls
}

export function BetDetail({ order }: BetDetailProps) {
  const { t } = useCustomTranslation()
  const winAmount = getWinAmount(order)
  const gameId = 'H_PLINKO'
  const rates = countOrderRates(order)
  const { rows, balls, ball_paths, multipliers, random_value, server_seed, client_seed } = order || {}

  return (
    <Box backgroundColor="#2f2f31">
      <Flex justifyContent="space-between" py={1}>
        <Stack flex={1} p={4} backgroundColor="#2f2f31" color={'#fff'} gap={0}>
          <Text fontWeight={600} fontSize="18px" mt={2}>
            {t(gameId)}
          </Text>
          <Flex gap={2} alignItems="center">
            <RenderAmountRound order={order} />
            <Image src={getSymbolFromCurrency(order.currency)} className="w-6 h-6 rounded-lg" />
          </Flex>
        </Stack>
        <Flex
          className="p-2 pl-0"
          wrap="wrap"
          flex={1.5}
          justify="center"
          align="start"
          alignItems="center"
          justifyContent="end"
        >
          {rates.map(({ rate, count }, index) => (
            <WrapItem className="m-2" key={index} justifyContent="center" alignItems="center">
              <Text color="#fff" fontSize={12} minWidth={5}>
                {count} x
              </Text>
              <Tag
                minWidth={9}
                p={0}
                className="ml-1 bg-red-100"
                size="md"
                borderRadius="full"
                variant="solid"
                bg="#F2F7FF"
                color="black"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <TagLabel fontWeight={600}>{rate}x</TagLabel>
              </Tag>
            </WrapItem>
          ))}
        </Flex>
      </Flex>

      <Stack p={4} pb={0} color={'#fff'} borderRadius="14px 14px 0 0" backgroundColor="#363639" gap="10px">
        <Flex justifyContent="space-between" py={1}>
          <Text color={textGrayDrawer}>{t('game')}</Text>
          <Text fontWeight={400}>{t(gameId)}</Text>
        </Flex>

        <hr style={{ borderColor: '#434343' }} />
        <Flex justifyContent="space-between" py={1}>
          <Text color={textGrayDrawer}>{t('betAmount')}</Text>
          <Flex gap={1} alignItems="center">
            <Text fontWeight={400}>{prettyPrintWallet(+order.amount)} </Text>
            {currencyMap[order.currency]?.icon}
          </Flex>
        </Flex>
        <hr style={{ borderColor: '#434343' }} />
        <Flex justifyContent="space-between" py={1}>
          <Text color={textGrayDrawer}>{t('totalAmount')}</Text>
          <Flex gap={1} alignItems="center">
            <Text fontWeight={400}>{prettyPrintWallet(+order.amount * order.balls)} </Text>
            {currencyMap[order.currency]?.icon}
          </Flex>
        </Flex>

        <hr style={{ borderColor: '#434343' }} />
        <Flex justifyContent="space-between" py={1}>
          <Text color={textGrayDrawer}>{t('plinko.balls')}</Text>
          <Text fontWeight={400}>{prettyPrintWallet(order.balls)} </Text>
        </Flex>

        <hr style={{ borderColor: '#434343' }} />
        <Flex justifyContent="space-between" py={1}>
          <Text color={textGrayDrawer}>{t('plinko.rows')}</Text>
          <Text fontWeight={400}>{prettyPrintWallet(order.rows)} </Text>
        </Flex>

        <hr style={{ borderColor: '#434343' }} />
        <Flex justifyContent="space-between" py={1} alignItems="center">
          <Text color={textGrayDrawer}>{t('bonusAmount')}</Text>
          <Flex gap={1} alignItems="center">
            <Text fontWeight={400}>{prettyPrintWallet(+winAmount)}</Text>
            {currencyMap[order.currency]?.icon}
          </Flex>
        </Flex>

        <hr style={{ borderColor: '#434343' }} />
        <Flex justifyContent="space-between" py={1}>
          <Text color={textGrayDrawer}>{t('betTime')}</Text>
          <Text fontWeight={400}>
            {order.created_at ? dayjs(order.created_at).format('YYYY-MM-DD hh:mm:ss') : '__'}
          </Text>
        </Flex>

        <hr style={{ borderColor: '#434343' }} />
        <Flex justifyContent="space-between" py={1}>
          <Text color={textGrayDrawer}>{t('plinko.orderId')}</Text>
          <Flex>
            <Text fontWeight={400} className="mr-1">
              {order.id}
            </Text>
            <CopyBtn text={order.id} />
          </Flex>
        </Flex>

        <hr style={{ borderColor: '#434343' }} />
        <Stack gap="0">
          <Flex alignItems="center" justifyContent="space-between" py={1}>
            <Text flex={1} color={textGrayDrawer}>
              {t('randomness')}
            </Text>
            <Flex flex={2} overflow="hidden" ml={5}>
              <Text fontWeight={400} isTruncated>
                {order.random_value}
              </Text>
              <CopyBtn text={order.random_value || ''} />
            </Flex>
          </Flex>
        </Stack>

        <hr style={{ borderColor: '#434343' }} />
        <Stack gap="0">
          <Flex alignItems="center" justifyContent="space-between" py={1}>
            <Text flex={1} color={textGrayDrawer}>
              {t('serverSeed')}
            </Text>
            <Flex flex={2} overflow="hidden" ml={5}>
              <Text fontWeight={400} isTruncated>
                {order.server_seed}
              </Text>
              <CopyBtn text={order.server_seed || ''} />
            </Flex>
          </Flex>
        </Stack>

        <hr style={{ borderColor: '#434343' }} />
        <Stack gap="0">
          <Flex alignItems="center" justifyContent="space-between" py={1}>
            <Text flex={1} color={textGrayDrawer}>
              {t('clientSeed')}
            </Text>
            <Flex flex={2} overflow="hidden" ml={5}>
              <Text fontWeight={400} isTruncated>
                {order.client_seed}
              </Text>
              <CopyBtn text={order.client_seed || ''} />
            </Flex>
          </Flex>
        </Stack>

        <CopyAll cpyTxt={`${order.random_value}${order.server_seed}${order.client_seed}`} />

        <hr style={{ borderColor: '#434343' }} />
        <Stack gap="0">
          <Flex alignItems="center" justifyContent="space-between" py={1}>
            <Text flex={1} color={textGrayDrawer}>
              {t('gamePlay.lotteryResultHash')}
            </Text>
            <Flex flex={2} overflow="hidden" ml={5}>
              <Text fontWeight={400} isTruncated>
                {order.proof}
              </Text>
              <CopyBtn text={order.proof || ''} />
            </Flex>
          </Flex>
        </Stack>
        <Button
          aria-label="buttonVerifyWallet"
          aria-labelledby="buttonVerifyWallet"
          w="100%"
          size="xs"
          backgroundColor="#c7fb14"
          borderRadius="8px"
        >
          <Stack direction="row">
            <Link
              href={`https://emn178.github.io/online-tools/sha256.html?input=${random_value}${server_seed}${client_seed}&input_type=utf-8&output_type=hex&hmac_input_type=utf-8`}
              isExternal
            >
              {t('buttonVerifyWallet')} <FiExternalLink style={{ display: 'inline' }} />
            </Link>
          </Stack>
        </Button>

        <hr style={{ borderColor: '#434343' }} />
        <Stack gap="0">
          <Text color={textGrayDrawer}>{t('plinko.ballPaths')}</Text>
          <Flex mt={2} fontWeight="bold" textTransform="capitalize" fontSize={15}>
            <Flex flex={1.25}>
              <Text>{t('plinko.pathHash')}</Text>
            </Flex>
            <Text px={2} flex={1}>
              {t('plinko.pathBit')}
            </Text>
            <Text px={2} flex={1}>
              {t('plinko.ballPath')}
            </Text>
            <Text flex={0.3} align="right">
              {t('plinko.rate')}
            </Text>
          </Flex>
          <Stack
            className="scrollable"
            css={{
              '::after': {
                background: 'linear-gradient(to bottom, #36363900, #363639)',
              },
            }}
          >
            <Box overflow={balls > 6 ? 'auto' : undefined} maxHeight={170} fontSize={14}>
              {ball_paths?.map((path, i) => {
                const input = random_value + i
                const hash = CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex)
                const { bits } = convertHashToLR(hash)
                return (
                  <Flex py={0.5}>
                    <Flex flex={1.25} overflow="hidden">
                      <Text minWidth={5}>{i}.</Text>
                      <Text ml={2} isTruncated>
                        {hash}
                      </Text>
                      <Link
                        display="flex"
                        className="ml-1"
                        href={`https://emn178.github.io/online-tools/sha256.html?input=${input}&input_type=utf-8&output_type=hex&hmac_input_type=utf-8`}
                        isExternal
                      >
                        <FiExternalLink size={12} style={{ display: 'inline' }} />
                      </Link>
                    </Flex>
                    <Flex px={4} flex={1} overflow="hidden" alignItems="center">
                      <Text isTruncated>{bits}</Text>
                      <Link
                        className="h-full"
                        display="flex"
                        href={`https://www.rapidtables.com/convert/number/hex-to-binary.html?x=${hash}`}
                        isExternal
                      >
                        <FiExternalLink size={12} style={{ display: 'inline' }} />
                      </Link>
                    </Flex>
                    <Text px={2} flex={1} fontWeight={400} isTruncated overflow="hidden">
                      {path.substring(0, rows)}
                    </Text>
                    <Text flex={0.3} fontWeight={400} align="right">
                      {multipliers[i]}
                    </Text>
                  </Flex>
                )
              })}
              <div className="h-4" />
            </Box>
          </Stack>
        </Stack>
        <div className="h-2" />
      </Stack>
    </Box>
  )
}
