import React, { useEffect, useRef } from 'react'
import { Box, Flex, Image } from '@chakra-ui/react'
import gsap from 'gsap'
import './styles/topPlayers.scss'
import { getAvatar } from '@/utils/helper'

interface TopPlayersProps {
  players: Array<{
    id: string | number
    multiplier: number
    name: string
  }>
}

const TopPlayers: React.FC<TopPlayersProps> = ({ players }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      gsap.fromTo(
        containerRef.current,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
        },
      )
    }
  }, [])

  return (
    <>
      <Flex
        ref={containerRef}
        position="absolute"
        bottom="-20px"
        left="50%"
        transform="translate(-50%, 0)"
        alignItems="center"
      >
        {[2, 1, 3].map((position) => {
          const player = players[position - 1]

          return (
            <Box key={position} width="120px" zIndex={position === 1 ? 2 : 1} className="relative">
              <Image
                src={`/images/rockets/img-bg-rank${position}.png`}
                transform={position === 1 ? 'scale(1.1, 1.1)' : ''}
                marginBottom={position === 1 ? '40px' : '0'}
              />
              <Image
                src={getAvatar(player.id.toString())}
                alt={`Player avatar ${position}`}
                position="absolute"
                w="40px"
                h="40px"
                top={position === 1 ? '8px' : '16.5px'}
                left="50.5%"
                transform={`translate(-49%, 0)${position === 1 ? ' scale(1.08)' : ''}`}
                borderRadius="50%"
              />
              {/* // player name */}
              <Box
                position="absolute"
                top={position === 1 ? '55px' : '60px'}
                left="50%"
                transform="translate(-50%, 0)"
                fontSize="12px"
                fontWeight="bold"
                textAlign="center"
                color="black"
              >
                {player?.name}
              </Box>
              <Box
                position="absolute"
                top={position === 1 ? '70px' : '75px'}
                left="50%"
                transform="translate(-50%, 0)"
                fontSize="12px"
                fontWeight="bold"
                textAlign="center"
                color="black"
              >
                {player?.multiplier ? `${player.multiplier}x` : ''}
              </Box>
            </Box>
          )
        })}
      </Flex>
      <Box
        background="linear-gradient(transparent, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) ,#000000)"
        height="40px"
        position="absolute"
        left="0"
        right="0"
        bottom="0"
        opacity="0.7"
      />
    </>
  )
}

export default TopPlayers
