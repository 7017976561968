import React from 'react'
import { Box, Image } from '@chakra-ui/react'
import './styles/launching.scss'
import useCustomTranslation from '@/hooks/useCustomTranslation'

type CountdownProps = {
  countdown: number
  duration: number
}

export const RocketCountdown = ({ countdown, duration }: CountdownProps) => {
  const { t } = useCustomTranslation()

  //   if (countdown < 0) return null

  const countdownDisplay = countdown.toString().padStart(2, '0')
  const radius = 100 // Radius of the semi-circle
  const circumference = Math.PI * radius // Circumference of the semi-circle
  const progress = (duration - countdown) / duration
  const maxOffset = circumference - 80
  const animFrom = circumference - maxOffset
  const offset = maxOffset * progress
  const dashOffset = animFrom + offset

  return (
    <>
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" zIndex="3">
        <Box opacity={countdown < 0 ? '0' : '1'} className="relative w-[250px]">
          <Image src="/images/rockets/img_bg_countdown.png" alt="" />

          {/* semi circle progress */}
          <Box position="absolute" top="45px" left="50%" transform="translateX(-50%)">
            <svg width="200" height="133" viewBox="0 0 200 120">
              {/* semi-circle */}
              <path
                d="M 25 75 A 60 60 0 1 1 175 75"
                fill="none"
                stroke="#1F3A3A"
                strokeWidth="12"
                strokeLinecap="round"
              />
              {/* progress bar */}
              <path
                d="M 25 75 A 60 60 0 1 1 175 75"
                fill="none"
                stroke="#00FF47"
                strokeWidth="12"
                strokeLinecap="round"
                strokeDasharray={circumference}
                strokeDashoffset={dashOffset}
                style={{
                  transition: 'stroke-dashoffset 0.5s linear',
                }}
              />
            </svg>
          </Box>

          {/* countdown text */}
          <Box className="absolute top-[85px] w-[200px] left-1/2 transform -translate-x-1/2">
            <div className="text-center text-[39px] font-bold text-[#FFB800] drop-shadow-[0_1px_3px_rgba(0,0,0,0.8)] select-none">
              {countdownDisplay}s
            </div>
            <Box className="text-center text-[24px] font-bold">{t('rocket.countdownTitleTop')}</Box>
            <Box className="text-center text-[16px]">{t('rocket.countdownTitleBottom')}</Box>
          </Box>
        </Box>

        {/* Rocket positioned on the moon */}
        <CountdownRocket countdown={countdown} />
      </Box>
    </>
  )
}

const CountdownRocket = ({ countdown }: Pick<CountdownProps, 'countdown'>) => {
  return (
    <Box display={'flex'} justifyContent={'center'} marginTop={'-39px'} className={countdown < 0 ? 'rocket-up' : ''}>
      <Box className="relative">
        <Image src="/images/rockets/img_rocket.png" alt="rocket" className="w-[70px] aspect-[277/397] object-contain" />
        <Box
          sx={{
            width: '65px',
            height: '115px',
            background: 'url(/images/rockets/rocket-firing-sprite.png) no-repeat center',
            backgroundSize: '565px',
            backgroundPosition: '-13px 0px',
            transform: 'translateY(20%)',
            zIndex: -1,
            animation: 'firing 1s infinite 0s step-end',
          }}
          className="absolute bottom-[-50px] left-[2px]"
        />
      </Box>
    </Box>
  )
}
