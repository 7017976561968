import React from 'react'
import { Stack } from '@chakra-ui/react'
import ResultHash from '@/bingo/components/FormSection/ResultHash'
import FormBackground from '@/bingo/components/FormSection/FormBackground.tsx'
import RandomTicket from '@/bingo/components/FormSection/RandomTicket'
import OrderConfirmation from '@/bingo/components/FormSection/OrderConfirmation'
import { useAppSelector } from '@store'
import { selectBingoCurrentStep, selectBingoWinningTicket } from '@/bingo/redux/bingo.slice.ts'
import YourBet from '@/bingo/components/FormSection/YourBet'
import Mining from '@/bingo/components/FormSection/Mining'
import WinningTicket from '@/bingo/components/FormSection/Mining/WinningTicket.tsx'
import { BingoCountDown } from './BingoCountDown'

const FormSection = () => {
  const currentStep = useAppSelector(selectBingoCurrentStep)
  const winningTicket = useAppSelector(selectBingoWinningTicket)

  return (
    <Stack flex="1" gap="0" position="relative">
      <ResultHash />
      <FormBackground marginTop="69px" flex="1">
        <BingoCountDown />
        {currentStep === 'randomTicket' && <RandomTicket />}
        {currentStep === 'orderConfirmation' && <OrderConfirmation />}
        {currentStep === 'betSuccess' && <YourBet />}
        {currentStep === 'mining' && <Mining />}
      </FormBackground>
      {winningTicket && <WinningTicket />}
    </Stack>
  )
}

export default FormSection
