import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

type ButtonChangeGroupProps = ButtonProps & {
  variant: 'next' | 'prev',
}

const iconByVariant: Record<string, React.ReactNode> = {
  next: <IoIosArrowForward />,
  prev: <IoIosArrowBack />,
}

const ButtonChangeGroup = ({variant, ...rest}: ButtonChangeGroupProps) => {
  return (
    <Button
      width="30px"
      minWidth="auto"
      height="30px"
      minHeight="auto"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="50%"
      border="1px solid #ff4523"
      color="#ff4523"
      padding="0"
      background="none"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {iconByVariant[variant]}
    </Button>
  )
}

export default ButtonChangeGroup;
