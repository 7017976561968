import React from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react'

type RocketChipProps = FlexProps & {
  betAmount: number,
  isSelected?: boolean,
  isDisabled?: boolean,
}

const backgroundByBetAmount: Record<number, Record<string, string>> = {
  5: {
    default: 'url("/rocket/images/form/chip-green.png")',
    selected: 'url("/rocket/images/form/chip-green-selected.png")',
  },
  10: {
    default: 'url("/rocket/images/form/chip-blue.png")',
    selected: 'url("/rocket/images/form/chip-blue-selected.png")',
  },
  20: {
    default: 'url("/rocket/images/form/chip-orange.png")',
    selected: 'url("/rocket/images/form/chip-orange-selected.png")',
  },
  50: {
    default: 'url("/rocket/images/form/chip-purple.png")',
    selected: 'url("/rocket/images/form/chip-purple-selected.png")',
  },
  100: {
    default: 'url("/rocket/images/form/chip-red.png")',
    selected: 'url("/rocket/images/form/chip-red-selected.png")',
  },
  200: {
    default: 'url("/rocket/images/form/chip-orange.png")',
    selected: 'url("/rocket/images/form/chip-orange-selected.png")',
  },
  300: {
    default: 'url("/rocket/images/form/chip-blue.png")',
    selected: 'url("/rocket/images/form/chip-blue-selected.png")',
  },
}

// const chipTextShadows = '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff'

const RocketChip = ({betAmount, isSelected, isDisabled, ...rest}: RocketChipProps) => {
  return (
    <Box
      position="relative"
      width="41px"
      minWidth="41px"
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        lineHeight="1"
        width="100%"
        aspectRatio="158/161"
        background={isSelected ? backgroundByBetAmount[betAmount].selected : backgroundByBetAmount[betAmount].default}
        backgroundSize="contain"
        borderRadius="50%"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        userSelect="none"
        color="#000000"
        fontWeight="1000"
        transform="scale(1.28455285)"
        opacity={isDisabled ? 0.65 : isSelected ? 1 : 0.65}
        sx={{
          '-webkit-text-stroke': '1.2px #ffffff'
        }}
        _hover={{
          opacity: !isDisabled ? '1' : '',
        }}
        {...rest}
      >
        <Box
          marginTop="-4px"
        >
          {betAmount}
        </Box>
      </Flex>
    </Box>
  )
}

export default RocketChip;
