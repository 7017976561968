import { useAppSelector } from '@/redux/store'
import { selectPlinkoRiskLevel, selectPlinkoRows, selectPlinkoWinRates } from '@/redux/store/modules/plinko.slice'
import { Bodies, Body, Composite, Engine, Events, Render } from 'matter-js'
import { useEffect, useRef } from 'react'
import { plinkoGameConfigs } from '../../../config'
import { multiplierColors } from '../../../config/multipliers'
import { usePinGap } from './usePinGap'

interface UseMultipliersProps {
  worldHeight: number
  worldWidth: number
  render?: Render
  engine: Engine
}

export function useMultipliers({ engine, worldHeight, worldWidth, render }: UseMultipliersProps) {
  const lines = useAppSelector(selectPlinkoRows)
  const risk = useAppSelector(selectPlinkoRiskLevel)
  const multiplier = useAppSelector(selectPlinkoWinRates(lines, risk))
  const rates = multiplier?.multipliers || []
  const ref = useRef<Body[]>([])

  const multiplierHeight = plinkoGameConfigs.multipliers.height
  const { pinXGap } = usePinGap({ worldHeight, worldWidth })

  useEffect(() => {
    const multipliersBodies: Body[] = []
    const y = worldHeight - multiplierHeight / 2

    const colorRates: Record<number, number> = {}
    rates.forEach((rate, i) => {
      const indexColor = colorRates[rate] || i + 1
      colorRates[rate] = indexColor
      const x = (i + 0.5) * pinXGap
      const multiplierBody = Bodies.rectangle(x, y, pinXGap - 1, multiplierHeight, {
        label: `rates-${rate}-${indexColor - 1}`,
        isStatic: true,
        render: { fillStyle: 'transparent' },
      })

      multipliersBodies.push(multiplierBody)
    })
    Composite.remove(engine.world, ref.current)
    Composite.add(engine.world, multipliersBodies)

    ref.current = multipliersBodies
  }, [engine, multiplier])

  function drawMultipliers(render: Render) {
    const ctx = render.context
    ref.current.forEach((block) => {
      if (block.label?.startsWith('rates-')) {
        drawRoundedRectangle(ctx, block)
      }
    })
  }

  useEffect(() => {
    if (!render) return
    const redraw = () => drawMultipliers(render)

    Events.on(render, 'afterRender', redraw)
    return () => {
      Events.off(render, 'afterRender', redraw)
    }
  }, [render, ref])

  return { multipliers: ref.current, ref }
}

const drawRoundedRectangle = (ctx: CanvasRenderingContext2D, multi: Body) => {
  const bounds = multi.bounds

  const [_, rate, colorIndex] = multi.label.split('-')

  const colorConfig = multiplierColors[Number(colorIndex)]

  const x = bounds.min.x
  const y = bounds.min.y
  const width = bounds.max.x - bounds.min.x
  const height = bounds.max.y - bounds.min.y
  const radius = 3
  const centerX = x + width / 2
  const centerY = y + height / 2
  const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, Math.max(width, height) / 2)

  gradient.addColorStop(0, colorConfig.fromColor)
  gradient.addColorStop(1, colorConfig.toColor)

  ctx.fillStyle = gradient
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
  ctx.fill()

  ctx.font = '11px "Saira Condensed", sans-serif'
  ctx.fillStyle = colorConfig.textColor
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillText(`${rate}x`, multi.position.x, multi.position.y + 1)
}
