import { useAppSelector } from '@/redux/store'
import { selectPlinkoRows } from '@/redux/store/modules/plinko.slice'
import { Bodies, Body, Composite, Engine } from 'matter-js'
import { useEffect, useRef } from 'react'
import { plinkoGameConfigs } from '../../../config'
import { usePinGap } from './usePinGap'

interface UsePinsProps {
  worldHeight: number
  worldWidth: number
  engine: Engine
}

export function usePins({ worldHeight, worldWidth, engine }: UsePinsProps) {
  const rows = useAppSelector(selectPlinkoRows)
  const ref = useRef<Body[]>([])

  const pinsConfig = plinkoGameConfigs.pins
  const { pinXGap, pinYGap, pinSize } = usePinGap({ worldHeight, worldWidth })

  useEffect(() => {
    const newPins: Body[] = []
    for (let l = 0; l < rows; l++) {
      const linePins = pinsConfig.startPins + l
      const lineWidth = linePins * pinXGap
      const pinY = (pinsConfig.startPins + l) * pinYGap + pinsConfig.headerHeight
      for (let i = 0; i < linePins; i++) {
        const pinX = worldWidth / 2 - lineWidth / 2 + i * pinXGap + pinXGap / 2
        const pin = Bodies.circle(pinX, pinY, pinSize, {
          label: `pin-${l}-${i}`,
          render: {
            fillStyle: pinsConfig.color,
            strokeStyle: pinsConfig.borderColor,
            lineWidth: pinsConfig.borderWidth,
          },
          isStatic: true,
        })
        newPins.push(pin)
      }
    }
    Composite.remove(engine.world, ref.current)
    Composite.add(engine.world, newPins)
    ref.current = newPins
  }, [engine, rows])

  return { pins: ref.current, ref }
}
