import { extendTheme } from '@chakra-ui/react'
import { bgColorApp, bgModalBody } from './color'
import { getInitialLang } from '../i18n'

const customBreakpoints = {
  '340px': '340px',
  '350px': '350px',
  '370px': '370px',
  '375px': '375px',
  '400px': '400px',
  '412px': '412px',
  '414px': '414px',
  '430px': '430px',
  '480px': '480px',
  '500px': '500px',
  'iphone-14-pro-max': '430px',
  'iphone-xr': '414px',
  'galaxy-s20': '412px',
  'galaxy-s8': '360px',
  'galaxy-zfold5': '344px',
  'pixel-7': '412px',
  'pixel-460': '460px',
  'pc-500-up': '500px',
}

function getFontByLang() {
  const langFontMap: Record<string, string> = {
    // en: `"Barlow Semi Condensed", sans-serif`,
    // en: `"PT Sans Narrow", sans-serif`,
    // en: `"Dosis", sans-serif`,
    // en: `"Barlow", sans-serif`,
    // en: `"Abel", sans-serif`,
    en: `"Saira Condensed", sans-serif`,
    // en: `"Bahnschrift", sans-serif`,
    // zh: `'Bahnschrift', 'sans-serif'`,
    other: `"Saira Condensed", sans-serif`,
  }

  return langFontMap[getInitialLang()] ?? langFontMap.other
}

export const CustomTheme = extendTheme({
  breakpoints: customBreakpoints,
  fonts: {
    heading: getFontByLang(),
    body: getFontByLang(),
  },
  styles: {
    global: {
      ':root': {
        '--chakra-sizes-prose': '515px',
      },
      body: {
        bg: bgColorApp,
      },
      'html, body': {
        fontFamily: getFontByLang(),
      },
    },
  },
  textStyles: {
    bold: {
      fontWeight: '700',
    },
    semibold: {
      fontWeight: '600',
    },
    regular: {
      fontWeight: '400',
    },
    semiboldSemicondensed: {
      fontWeight: '600',
      fontStretch: 'condensed',
    },
  },
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          bg: bgColorApp,
        },
        header: {
          color: 'white',
        },
        content: {
          color: 'white',
        },
        body: {
          background: bgModalBody,
        },
      }),
    },
    Drawer: {
      baseStyle: () => ({
        dialog: {
          bg: bgColorApp,
        },
        header: {
          color: 'white',
        },
        content: {
          color: 'white',
        },
        body: {
          background: bgModalBody,
        },
      }),
    },
  },
})

export const ToastOptions = {
  motionVariants: {
    // initial state
    initial: {
      opacity: 0,
      translateY: '50%',
    },
    // mount and anim
    animate: {
      opacity: 1,
      translateY: 0,
      height: '100%',
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    },
    // anim and unmount
    exit: {
      opacity: 0,
      translateY: '-100px',
      height: 0,
      transition: {
        duration: 0.5,
        ease: 'easeIn',
      },
    },
  },

  // portalProps: {
  //   className: "my-toast-portal"
  // },
  //
  // toastSpacing: '0.5rem'
}
