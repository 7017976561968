import { ErrorOptions, popupError } from '@/components/common/app'
import { ApolloError } from '@apollo/client'
import { createAction, isRejected } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { isEmpty, keyBy } from 'lodash'
import { Middleware } from 'redux'
import { useAppDispatch } from '..'
import { AppError, GqlError, GqlErrorItem } from '../models/error.model'
import { getWalletByCurrency } from '../modules/wallet.slice'

interface HandleErrorOptions {
  mute: boolean
}
interface CommonError {
  errors: GqlErrorItem[]
  options?: HandleErrorOptions
}
const commonError = createAction<CommonError>('common/error')

const muteActions = keyBy([getWalletByCurrency.rejected.type])

export const handleErrorMiddleware: Middleware = (storeAPI) => (next) => (action: any) => {
  const result = next(action)

  if (!isRejected(action) && action.type != commonError.type) {
    return result
  }

  const err = getErrorFromAction(action)
  if (err) {
    popupError(err)
    return result
  }

  const errors = getErrorsFromAction(action)
  const options: HandleErrorOptions | undefined = action.payload?.options
  const mute = options?.mute ?? muteActions[action.type]

  if (!isEmpty(errors) && !mute) {
    popupError(errors[0])
  }

  return result
}

function getErrorFromAction(action: any): ErrorOptions | null {
  const { payload, type } = action || {}
  const mute = !!muteActions[type]

  if (payload instanceof AxiosError) {
    const code = payload.response?.data?.code || payload.code
    const message = payload.response?.data?.message || payload.message
    return {
      message,
      code,
      mute,
    }
  }

  if (payload instanceof AppError) {
    const code = payload.code
    const message = payload.message
    return {
      message,
      code,
      mute,
      meta: payload.data,
    }
  }

  return null
}

function getErrorsFromAction(action: any) {
  const payload = action?.payload
  if (payload instanceof AxiosError) {
    const { code, message } = payload?.response?.data || {}
    if (code) {
      return [{ code, message }]
    }
    return undefined
  }
  const errors = payload?.response || action.payload?.errors || action.payload
  return errors
}

export const handleApolloError = (dispatch: ReturnType<typeof useAppDispatch>, options?: HandleErrorOptions) => (
  error: ApolloError | GqlErrorItem[],
) => {
  if (Array.isArray(error)) {
    dispatch(commonError({ errors: error, options }))
  } else {
    dispatch(commonError({ errors: new GqlError(error).errors, options }))
  }
}
