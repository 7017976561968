import React, { useState } from 'react'
import { Box, ButtonProps, Flex, FlexProps, Text } from '@chakra-ui/react'

type TrenballButtonVariant = 'yellow' | 'red' | 'green' | 'blue'

type TrenballButtonProps = ButtonProps & {
  title: string | React.ReactNode,
  variant: TrenballButtonVariant,
  betAmount: number,
  multiplier: number,
  isSelected?: boolean,
  isDisabled?: boolean,
}

type TrenballTabsProps = FlexProps & {
  isFormDisabled?: boolean,
}

const colorByVariant: Record<TrenballButtonVariant, string> = {
  yellow: '#ff9600',
  red: '#d52b4f',
  green: '#27a052',
  blue: '#0a79df',
}

const TrenballButton = ({title, variant, betAmount, multiplier, isSelected, isDisabled, ...rest}: TrenballButtonProps) => {
  return (
    <Box
      overflow="hidden"
      lineHeight="1"
      textAlign="center"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      color="#fefefe"
      boxShadow="0 6px 11px 0 rgba(19,24,31,0.48)"
      borderRadius="7.3px"
      width="calc(25% - 2.15px)"
      minWidth="calc(25% - 2.15px)"
      userSelect="none"
      opacity={isDisabled ? 0.65 : 1}
      {...rest}
    >
      <Box
        padding="11px 8px 8px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={isSelected ? colorByVariant[variant] : 'transparent'}
        borderBottom="none"
        backgroundColor={isSelected ? '#212935' : '#323e51'}
        backgroundImage="url('/rocket/images/btn-trenball-inset.png')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="top center"
        borderTopRadius="7.3px"
      >
        <Text
          fontStyle="italic"
          fontSize="18px"
          fontWeight="600"
          marginBottom="7px"
        >
          {title}
        </Text>
        <Text
          color={isSelected ? '#bdf303' : 'fefefe'}
          fontSize="14px"
        >
          投注{betAmount}元
        </Text>
      </Box>
      <Box
        background={colorByVariant[variant]}
        padding="3.3px 5.3px"
        fontSize="14px"
      >
        赔付 {multiplier}x
      </Box>
    </Box>
  )
}

const TrenballTabs = ({isFormDisabled, ...rest}: TrenballTabsProps) => {
  const [selectedTrenball, setSelectedTrenball] = useState<TrenballButtonVariant>('yellow')

  const handleTrenballButtonClick = (variant: TrenballButtonVariant) => {
    if (isFormDisabled) return

    setSelectedTrenball(variant)
  }

  return (
    <Flex
      alignItems="stretch"
      gap="4.3px"
      {...rest}
    >
      <TrenballButton
        variant="yellow"
        title="Crash"
        betAmount={0}
        multiplier={49.99}
        isSelected={selectedTrenball === 'yellow'}
        onClick={() => handleTrenballButtonClick('yellow')}
        isDisabled={isFormDisabled}
      />
      <TrenballButton
        variant="red"
        title="红色"
        betAmount={10}
        multiplier={1.96}
        isSelected={selectedTrenball === 'red'}
        onClick={() => handleTrenballButtonClick('red')}
        isDisabled={isFormDisabled}
      />
      <TrenballButton
        variant="green"
        title="绿色"
        betAmount={0}
        multiplier={2.00}
        isSelected={selectedTrenball === 'green'}
        onClick={() => handleTrenballButtonClick('green')}
        isDisabled={isFormDisabled}
      />
      <TrenballButton
        variant="blue"
        title="Moon"
        betAmount={0}
        multiplier={49.99}
        isSelected={selectedTrenball === 'blue'}
        onClick={() => handleTrenballButtonClick('blue')}
        isDisabled={isFormDisabled}
      />
    </Flex>
  )
}

export default TrenballTabs;