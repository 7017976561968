import { getSymbolFromCurrency } from '@/const/currency'
import { useAppSelector } from '@/redux/store'
import { selectListCurrentLives } from '@/redux/store/modules/rocketTshow'
import { Flex, Stack, Text, Image } from '@chakra-ui/react'
import { f } from 'fintech-number'
import React from 'react'

const ListPlayerBettingInRound = () => {
  const listPlayers = useAppSelector(selectListCurrentLives)
  // const gameRound = useAppSelector(selectGameRound)
  return (
    <Stack width="25%" position="absolute" top="50px" zIndex="3" left="5px" gap={1} minW="25%" maxW="25%">
      {listPlayers.slice(0, 10).map((item: any, index: number) => (
        <Flex key={index} gap={1} background="#17111133" px={2} py={1} borderRadius={4} justifyContent="space-between">
          <Text fontSize={14} lineHeight={1} className="text-one-line">
            {item.player_name}
          </Text>
          <Text fontSize={12} lineHeight={1} mt="1px">
            {+item?.esc_multiplier > 0
              ? `${f(item?.esc_multiplier, {
                  decimal: 2,
                })}x`
              : ''}
          </Text>
          <Flex gap={1} alignItems="center">
            <Text fontSize={14} lineHeight={1}>
              {f(item.amount, {
                decimal: 2,
              })}
            </Text>
            <Image src={getSymbolFromCurrency(item.currency)} className={`w-3 h-3 rounded-lg`} />
          </Flex>
        </Flex>
      ))}
    </Stack>
  )
}

export default React.memo(ListPlayerBettingInRound)
