import { useEffect, useRef } from 'react'
import { useAppSelector } from '@/redux/store'

export const useCheerSound = () => {
  const cheerSound = useRef<HTMLAudioElement | null>(null)
  const isMusicEnabled = useAppSelector((state) => state.sound.isMuted)
  const isWindowActive = useRef(true)

  useEffect(() => {
    cheerSound.current = new Audio('/sound/rockets/cheer.mp3')
    // cheerSound.current.loop = true

    return () => {
      if (cheerSound.current) {
        cheerSound.current.pause()
        cheerSound.current.currentTime = 0
      }
    }
  }, [])

  useEffect(() => {
    if (!isMusicEnabled) {
      if (cheerSound.current) {
        cheerSound.current.pause()
        cheerSound.current.currentTime = 0
      }
      return
    }

    if (isWindowActive.current) {
      cheerSound.current?.play()
    }
  }, [isMusicEnabled])

  useEffect(() => {
    const handleVisibilityChange = () => {
      isWindowActive.current = document.visibilityState === 'visible'
    }
    const onFocus = () => {
      isWindowActive.current = true
    }
    const onBlur = () => {
      isWindowActive.current = false
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    // listen focus + blur event
    document.addEventListener('focus', onFocus)
    document.addEventListener('blur', onBlur)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      document.removeEventListener('focus', onFocus)
      document.removeEventListener('blur', onBlur)
    }
  }, [])

  return cheerSound
}
