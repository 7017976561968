import { AppDrawer } from '@/components/common/AppDrawer'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { Flex, Link, Menu, MenuButton, MenuItem, MenuList, Stack, Text, Image } from '@chakra-ui/react'
import React, { ReactNode, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface Option {
  value: string
  label: string
  content: ReactNode
  headingPopup?: string
  image: string
}

export default function SelectMenuPopupRocket({ options, icon }: { options: Option[]; icon: any }) {
  const { t } = useCustomTranslation()
  const [popup, setPopup] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [content, setContent] = useState<ReactNode>()
  const openPopup = (popupName: string) => {
    setPopup(popupName)
  }

  const closePopup = () => {
    setPopup('')
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete('param')
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
  }
  return (
    <Stack>
      <Menu>
        <MenuButton
          role="button" 
          aria-expanded="false"
          aria-haspopup="menu"
          aria-controls="menu-list-:rc:"
          mt={2}
          background="#273038"
          as={Link}
          defaultValue="option1"
          alignItems="center"
          w="30px"
          h="22px"
          gap={0}
          className="flex items-center justify-center !w-[32px] !h-[18px] rounded-full"
        >
          {/* {icon} */}
          <Stack flexDirection="row" gap="3px" ml="5px">
            <div className="w-[5px] h-[5px] rounded bg-[#fff]"></div>
            <div className="w-[5px] h-[5px] rounded bg-[#fff]"></div>
            <div className="w-[5px] h-[5px] rounded bg-[#fff]"></div>
          </Stack>
        </MenuButton>
        <MenuList minWidth="10%" background="#1f2836" py={0} borderRadius="12px" overflow="hidden" borderWidth="0" left="-50px" transform="translateX(-50%)">
          {options.map((option: Option, index: number) => (
            <MenuItem
              fontSize={14}
              py="12px"
              px="16px"
              background="#1f2836"
              key={option.value}
              onClick={() => {
                openPopup(option.value)
                setContent(option.content)
                const searchParams = new URLSearchParams(location.search)
                searchParams.set('param', option.value)
                navigate(`?${searchParams.toString()}`, { replace: true })
              }}
              borderBottom={index < options?.length - 1 ? '1px solid #2d3849' : ''}
            >
              <Flex alignItems="center" gap={2}>
                <Image src={option.image} w="16px" h={index === 0 ? '16px': 'auto'} alt="" />
                <Text color="#bacfeb">{t(`menu.${option.label}`)}</Text>
              </Flex>
            </MenuItem>
          ))}
        <Stack transform="rotate(45deg)" background="#1f2836" position="absolute" right="10px" top="-4px" w="16px" height="16px" borderRadius="3px"></Stack>
        </MenuList>
      </Menu>
      <Stack>
        {options.map((option: Option) => (
          <AppDrawer
            key={option.value}
            title={option.headingPopup ? t(`menu.${option.headingPopup}`) : undefined}
            isOpen={option.value === popup}
            onClose={closePopup}
            overlay={true}
          >
            {content}
          </AppDrawer>
        ))}
      </Stack>
    </Stack>
  )
}
