import React from 'react'
import { Box } from '@chakra-ui/react'
import StageBg from '@/bingo/components/GameplayStage/StageBg.tsx'
import StageUIContainer from '@/bingo/components/GameplayStage/StageUIContainer.tsx'
import MiningArea from '@/bingo/components/GameplayStage/MiningArea'
import LotteryPatternsArea from '@/bingo/components/GameplayStage/LotteryPatternsArea'

const GameplayStage = () => {
  return (
    <Box position="relative" zIndex="2">
      <StageBg />
      <StageUIContainer>
        <MiningArea />
        <LotteryPatternsArea />
      </StageUIContainer>
    </Box>
  )
}

export default GameplayStage
