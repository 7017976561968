import React, { ReactNode } from 'react'
import { Image } from '@chakra-ui/react'
import _keyBy from 'lodash/keyBy'

type Currency = {
  name: string
  icon: ReactNode
  decimal: number
}

export const currency: Currency[] = [
  {
    name: 'USD',
    icon: <Image width="18px" height="18px" src="/images/currencies/USD.svg" alt="" />,
    decimal: 2,
  },
  // {
  //   name: 'CNY',
  //   icon: <Image width="18px" height="18px" src="/images/currencies/CNY.svg" alt="" />,
  //   decimal: 1,
  // },
  {
    name: 'USDT',
    icon: <Image width="18px" height="18px" src="/images/usdt.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'CNY',
    icon: <Image width="18px" height="18px" src="/images/currencies/CNY.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'PHP',
    icon: <Image width="16px" height="16px" src="/images/currencies/PHP.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'JPY',
    icon: <Image width="18px" height="18px" src="/images/currencies/JPY.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'BRL',
    icon: <Image width="18px" height="18px" src="/images/currencies/BRL.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'INR',
    icon: <Image width="18px" height="18px" src="/images/currencies/INR.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'LTC',
    icon: <Image width="18px" height="18px" src="/images/currencies/LTC.svg" alt="" />,
    decimal: 2,
  },
  {
    name: 'SOL',
    icon: <Image width="18px" height="18px" src="/images/currencies/SOL.svg" alt="" />,
    decimal: 2,
  },
]

export const currencyMap = _keyBy(currency, 'name')

export const getSymbolFromCurrency = (currency: any) => {
  if (!currency) {
    return '/images/currencies/USD.svg'
  }

  let currencyValue = ''

  if (typeof currency !== 'string' && currency?.code) {
    currencyValue = currency?.code
  } else {
    currencyValue = currency
  }

  const symbols = [
    { label: 'USDT', value: '/images/usdt.svg' },
    { label: 'TRX', value: '/images/currencies/trx1.png' },
    { label: 'BTC', value: '/images/currencies/btc1.png' },
    { label: 'CNY', value: '/images/currencies/CNY.svg' },
    { label: 'PHP', value: '/images/currencies/PHP.svg' },
    { label: 'USD', value: '/images/currencies/USD.svg' },
    { label: 'JPY', value: '/images/currencies/JPY.svg' },
    { label: 'BRL', value: '/images/currencies/BRL.svg' },
    { label: 'INR', value: '/images/currencies/INR.svg' },
    { label: 'LTC', value: '/images/currencies/LTC.svg' },
    { label: 'SOL', value: '/images/currencies/SOL.svg' },
  ].find((obj) => currencyValue?.toUpperCase() === obj?.label?.toUpperCase())

  return symbols?.value || '/images/currency/TETHER.webp'
}
