import useGameId from '@/hooks/useGameId.ts'
import { Text } from '@chakra-ui/react'
import React from 'react'
import useCustomTranslation from '../../../hooks/useCustomTranslation.tsx'
import RuleGameContainer, {
  HexagonHeading,
  NormalParagraph,
  NormalTag,
  UnorderedTextList,
} from './RuleGameContainer.tsx'

export default function RuleHRK() {
  const { t } = useCustomTranslation()
  const gameId = useGameId()
  return (
    <RuleGameContainer
      // title={t('ruleGame.bigsmall.title')}
      title={
        <>
          {/* {t(gameId)} */}
          ROCKET 
          <br />
          {t('ruleGame.bankerPlayer.title')}
        </>
      }
    >
      <HexagonHeading heading={t('ruleGame.bigsmall.howToDetermine')} />
      <NormalParagraph>{t('ruleGame.bigsmall.paragraph1')}</NormalParagraph>
      <NormalParagraph>{t('ruleGame.bigsmall.paragraph2')}</NormalParagraph>

      {/* <UnorderedTextList
        items={[
          <>
            {t('ruleGame.bigsmall.listItem1')}
            <NormalTag>
              ******59e
              <Text as="span" color={'#c3fb04'}>
                1
              </Text>
            </NormalTag>{' '}
            = <NormalTag>{t('ruleGame.bigsmall.cowSeven')}</NormalTag>
          </>,
          <>
            {t('ruleGame.bigsmall.listItem2')}
            <NormalTag>
              ******59e{' '}
              <Text as="span" color={'#c3fb04'}>
                5
              </Text>
            </NormalTag>{' '}
            = <NormalTag>{t('ruleGame.bigsmall.cattleNine')}</NormalTag>
          </>,
        ]}
      />
      <NormalParagraph>{t('ruleGame.bigsmall.rebateAmount')}</NormalParagraph>
      <NormalParagraph>{t('ruleGame.bigsmall.paragraph3')}</NormalParagraph> */}

      {/* <HexagonHeading heading={t('ruleGame.bigsmall.example')} />
      <UnorderedTextList
        items={[
          <>
            {t('ruleGame.bigsmall.exampleItems.betAmount')}
            <NormalTag>
              <Text as="span" color={'#c3fb04'}>
                100
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.bigsmall.exampleItems.gameplayChoice')}{' '}
              <NormalTag>{t('ruleGame.bigsmall.result')}</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.bigsmall.exampleItems.lotteryHash')}
            <NormalTag>
              ******59e
              <Text as="span" color={'#c3fb04'}>
                5
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.bigsmall.exampleItems.lotteryResult')} <NormalTag>5</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.bigsmall.exampleItems.result')}
            <NormalTag>
              <Text as="span" color={'#c3fb04'}>
                {t('ruleGame.bigsmall.result')}
              </Text>
            </NormalTag>
            <Text>
              {t('ruleGame.bigsmall.exampleItems.matchResult')}: <NormalTag color={'#c3fb04'}>{t('WIN')}</NormalTag>
            </Text>
          </>,
          <>
            {t('ruleGame.bigsmall.exampleItems.rewardAmount')}
            <NormalTag>
              100 * 1.95 =
              <Text as="span" color={'#c3fb04'}>
                195
              </Text>
            </NormalTag>
          </>,
        ]}
      /> */}
    </RuleGameContainer>
  )
}
