export function convertHashToLR(hash: string) {
  const hashBytes = new Uint8Array(hash.match(/.{1,2}/g)?.map((byte) => parseInt(byte, 16)) || [])

  if (hashBytes.length === 0) {
    throw new Error('Invalid hash string')
  }

  let result = ''
  let bits = ''

  for (const byte of hashBytes) {
    for (let i = 0; i < 8; i++) {
      if ((byte & (1 << (7 - i))) === 0) {
        bits += '0'
        result += 'L'
      } else {
        bits += '1'
        result += 'R'
      }
    }
  }
  return { bits, result }
}
