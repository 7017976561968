import React, { useEffect, useState } from 'react'
import { Stack, Text } from '@chakra-ui/react'
import RocketButtonBet, { RocketButtonBetProps } from '@components/rocket/NewUI/Common/RocketButtonBet.tsx'
import { CrashOrderCashOutInput } from '@/@generated/gql/graphql-hash.ts'
import { cashOutOrder, ROUND_STATE, selectGameRound, selectListActiveOrders } from '@store/modules/rocketTshow.ts'
import { ImCheckmark } from 'react-icons/im'
import { useAppDispatch, useAppSelector } from '@store'
import useGameId from '@hooks/useGameId.ts'
import useCustomToast from '@hooks/useCustomToast.ts'

const RocketButtonCashOutAll = ({...rest}: RocketButtonBetProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(true)
  const listActiveOrders = useAppSelector(selectListActiveOrders)
  const game_id = useGameId()
  const gameRound = useAppSelector(selectGameRound)
  const dispatch = useAppDispatch()
  const { showToastThrottle } = useCustomToast()

  const handleClickBtn = () => {
    const params: CrashOrderCashOutInput = {
      bet_ids: listActiveOrders.map((item) => item.id),
      game_id: game_id,
      round_id: listActiveOrders?.[0].round_id,
    }
    setIsLoading(true)
    dispatch(cashOutOrder(params))
      .then((res) => {
        console.log('{cashoutOrder}: ', res)
        if (res?.meta?.requestStatus === 'fulfilled') {
          showToastThrottle({
            title: 'Cash out successful',
            status: 'success',
            isClosable: false,
            duration: 2000,
            icon: <ImCheckmark fontSize={32} />,
            containerStyle: {
              width: '60px',
              paddingBlock: '0px',
              pointerEvents: 'none',
            },
          })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if(gameRound.state === ROUND_STATE.RUNNING) {
      timeout = setTimeout(() => {
        setIsDisable(false)
      }, 1500)
    }
    return (() => {
      clearTimeout(timeout)
    })
  }, [gameRound])

  return (
    <RocketButtonBet
      isDisabled={isDisable}
      isLoading={isLoading}
      onClick={handleClickBtn}
      {...rest}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
      >
        <Text
          fontSize="18px"
          fontWeight="600"
          color="#010101"
        >
          Cash Out all
        </Text>
      </Stack>
    </RocketButtonBet>
  )
}

export default RocketButtonCashOutAll;
