import { Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'

type TicketButtonProps = ButtonProps

const TicketButton = ({children, ...rest}: TicketButtonProps) => {
  return (
    <Button
      background="linear-gradient(180deg, #fe543c 0%, #e84731 100%)"
      padding="8px 15px"
      textAlign="center"
      lineHeight="1"
      color="white"
      fontWeight="500"
      fontSize="16px"
      borderRadius="5px"
      minHeight="auto"
      minWidth="68px"
      height="auto"
      _hover={{}}
      _active={{}}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default TicketButton;
