import { Box, Flex, InputProps, Stack, Text } from '@chakra-ui/react'
import React, { HTMLProps } from 'react'
import RocketInputTitle from '@components/rocket/NewUI/RocketTabs/RocketInputTitle.tsx'
import RocketIconCurrency from '@components/rocket/NewUI/Common/RocketIconCurrency.tsx'
import RocketInput from '@components/rocket/NewUI/Common/RocketInput.tsx'
import BtnQuickChange from '@components/UI/quick-form/BtnQuickChange.tsx'
import RocketButtonBet, { RocketButtonBetProps } from '@components/rocket/NewUI/Common/RocketButtonBet.tsx'
import RocketChip from '@components/rocket/NewUI/RocketTabs/RocketChip.tsx'
import RocketButtonCashOutAll from '@components/rocket/NewUI/Common/RocketButtonCashOutAll.tsx'

type BetAmountFormProps = {
  isLoading?: boolean,
  isFormDisabled?: boolean,
  canCashOutAll?: boolean,
  inputProps?: HTMLProps<HTMLInputElement> & InputProps,
  buttonBetProps?: RocketButtonBetProps,
  buttonCashOutAllProps?: RocketButtonBetProps,
  multiplyCallback?: (params?: any) => any,
  divideCallback?: (params?: any) => any,
  quickBetAmountCallback?: (params?: any) => any,
  currentBetAmount?: number,
}

const quickBetAmounts: number[] = [5, 10, 20, 50, 100, 200, 300]

const BetAmountForm = ({isLoading, isFormDisabled, canCashOutAll, inputProps, buttonBetProps, buttonCashOutAllProps, multiplyCallback, divideCallback, quickBetAmountCallback, currentBetAmount}: BetAmountFormProps) => {
  const handleRocketChipClick = (amount: number) => {
    if (isFormDisabled) return

    if (quickBetAmountCallback) {
      quickBetAmountCallback(amount)
    }
  }

  return (
    <Box
      background="#26303e"
      padding="0 16px 5.6px"
    >
      <Flex
        gap="10.3px"
      >
        <Flex
          background="#1a212a"
          padding="3px"
          border="1px solid #2a3444"
          borderRadius="8px"
          flex="1"
        >
          <Stack
            padding="7.6px 0 5.6px 11.6px"
            flex="1"
          >
            <RocketInputTitle
              flex="1"
            >
              投注金额
            </RocketInputTitle>
            <Flex
              flex="1"
            >
              <RocketIconCurrency
                opacity={isFormDisabled ? 0.4 : 1}
              />
              <RocketInput
                height="auto"
                ref={inputProps?.ref}
                disabled={isFormDisabled}
                {...inputProps}
              />
            </Flex>
          </Stack>
          <Stack
            gap="1.3px"
          >
            <BtnQuickChange
              changeType="multiply"
              display="block"
              flex="1"
              onClick={multiplyCallback}
              isDisabled={isFormDisabled}
            />
            <BtnQuickChange
              changeType="divide"
              display="block"
              flex="1"
              onClick={divideCallback}
              isDisabled={isFormDisabled}
            />
          </Stack>
        </Flex>
        {canCashOutAll ? (
          <RocketButtonCashOutAll
            isLoading={isLoading}
            {...buttonCashOutAllProps}
          />
        ) : (
          <RocketButtonBet
            isLoading={isLoading}
            isDisabled={isFormDisabled}
            {...buttonBetProps}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
            >
              <Text
                fontSize="18px"
                fontWeight="600"
                color="#010101"
              >
                立即投注
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                color="#000000"
              >
                （下一回合）
              </Text>
            </Stack>
          </RocketButtonBet>
        )}
      </Flex>
      <Box
        marginLeft="-16px"
        marginRight="-16px"
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          width: '20px',
          background: 'linear-gradient(to right, #26303e, transparent)',
          zIndex: '1',
        }}
        _after={{
          content: '""',
          position: 'absolute',
          top: '0',
          bottom: '0',
          right: '0',
          width: '20px',
          background: 'linear-gradient(to left, #26303e, transparent)',
          zIndex: '1',
        }}
      >
        <Flex
          overflowX="auto"
          gap="8.5px"
          marginTop="8px"
          padding="5.5px 21px"
          sx={{
            '&::webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {quickBetAmounts.map((amount) => (
            <RocketChip
              betAmount={amount}
              key={amount}
              isSelected={currentBetAmount === amount}
              onClick={() => handleRocketChipClick(amount)}
              isDisabled={isFormDisabled}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default BetAmountForm;
