import useCustomTranslation from '@/hooks/useCustomTranslation'
import { multiplierProbabilities } from '@/pages/plinko/config/multipliers'
import { useAppSelector } from '@/redux/store'
import { selectPlinkoBetAmount } from '@/redux/store/modules/plinko.slice'
import { Text } from '@chakra-ui/react'
import React from 'react'

interface MultiplierHighlightProps {
  rows: number
  label: string
}
export function MultiplierHighlight({ label, rows }: MultiplierHighlightProps) {
  const [_, rate, index] = label.split('-')
  const chance = multiplierProbabilities[rows][Number(index)]
  const amount = Number(useAppSelector(selectPlinkoBetAmount)) || 0
  const profit = amount * (Number(rate) - 1)
  const sign = profit < 0 ? '-' : ''
  const { t } = useCustomTranslation()

  const cells = [
    {
      label: 'plinko.profitOnWin',
      value: `${sign}$${Math.abs(profit).toFixed(2)}`,
    },
    {
      label: 'plinko.winChance',
      value: `${chance.toFixed(6)}%`,
    },
  ]
  return (
    <div className="min-w-60 flex flex-row px-2 py-1 rounded-xl bg-gradient-to-b from-[#232327] to-[#232327]">
      {cells.map(({ label, value }) => (
        <div className="flex flex-col flex-1 m-2" key={label}>
          <Text className="mb-1 uppercase text-xs font-medium rounded-lg">{t(label)}</Text>
          <Text className="bg-[#333333] text-sm font-medium px-2 py-1 rounded-lg">{value}</Text>
        </div>
      ))}
    </div>
  )
}
