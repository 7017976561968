import { HiloCashOutInput, HiloRoundInput } from '@/@generated/gql/graphql-hash'
import ButtonBet from '@/components/rocket/QuickForm/ButtonBet'
import QuickFormInput from '@/components/UI/quick-form/QuickFormInput'
import { getSymbolFromCurrency } from '@/const/currency'
import useCustomToast from '@/hooks/useCustomToast'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import useGameId from '@/hooks/useGameId'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { selectAgencyId, selectPlayerName } from '@/redux/store/modules/auth.slice'
import {
  cashoutOrderHilo,
  createNewRoundHilo,
  selectCardHilo,
  selectConfigHilo,
  selectGameRoundHilo,
  selectIsRoundEndHilo,
  selectLoadingHilo,
} from '@/redux/store/modules/hilo'
import { selectWallet } from '@/redux/store/modules/wallet.slice'
import { Image, Stack, Text } from '@chakra-ui/react'
import { f } from 'fintech-number'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ImCheckmark } from 'react-icons/im'

type InputProps = {
  amount: string | null
}

export default function QuickFormHilo() {
  const wallet = useAppSelector(selectWallet)
  const isLoading = useAppSelector(selectLoadingHilo)
  const [isLoadingCashout, setIsLoadingCashout] = useState<boolean>(false)

  const card = useAppSelector(selectCardHilo)
  const game_id = useGameId()
  const agencyId = useAppSelector(selectAgencyId)
  const playerName = useAppSelector(selectPlayerName)
  const gameRound = useAppSelector(selectGameRoundHilo)
  const isRoundEnd = useAppSelector(selectIsRoundEndHilo)
  const config = useAppSelector(selectConfigHilo)
  const { showToastThrottle } = useCustomToast()
  const { t } = useCustomTranslation()

  const dispatch = useAppDispatch()
  const { setValue, getValues, register, handleSubmit, watch } = useForm<InputProps>({
    defaultValues: {
      amount: '1',
    },
  })

  const handleChange = (e: any) => {
    const inputMaxAmount = +config?.maxBetAmount
    let value = e.target.value
    if (value === '') {
      return
    }
    value = e.target.value.replace(/[^0-9.]/g, '') // Remove anything that isn't a number or period
    let formatedValue = formatInputValue(value)
    let valueNumbered = Number(formatedValue.replace(/,/g, ''))

    if (valueNumbered > inputMaxAmount) {
      formatedValue = formatInputValue(inputMaxAmount)
      valueNumbered = inputMaxAmount
    }
    e.target.value = formatedValue
  }

  const formatInputValue = (value: string | number) => {
    // Format number with commas
    if (value.toString().includes('.')) {
      const [intPart, decimalPart] = value.toString().split('.')
      return f(Number(intPart)) + '.' + decimalPart
    } else {
      return f(Number(value)) // Format with commas
    }
  }

  const handleMinus = (name: 'enscape' | 'amount') => {
    //   const inputMinAmount = +configRound?.minBetAmount
    const inputMinAmount = 1
    const currentValue = getValues(name) ? getValues(name).replace(/,/g, '') : 0
    const digitCount = currentValue.toString().split('.')[0].length
    const smallestNumber = 10 ** (digitCount - 1)
    const minus = 10 ** (digitCount - 1) - 10 ** (digitCount - 2)
    const largestNumberWithOneDigitLess = minus < inputMinAmount ? inputMinAmount : minus
    const newValue = Math.max(smallestNumber - 10 ** (digitCount - 1), currentValue - smallestNumber)
    const resultValue = newValue < largestNumberWithOneDigitLess ? largestNumberWithOneDigitLess : newValue

    if (resultValue < 1) {
      return 1
    }
    setValue(name, f(resultValue))
  }

  const handlePlus = (name: 'enscape' | 'amount') => {
    // const inputMaxAmount = configRound?.maxBetAmount
    const inputMaxAmount = 1000000
    const prevVal = getValues(name) ? getValues(name).replace(/,/g, '') : 0
    const intPart = Number(prevVal.toString().split('.')[0])
    const increment = smallestSameDigitCount(intPart)
    let newValue = intPart + increment
    if (newValue < 10) {
      newValue = 10
    }
    const result = inputMaxAmount ? (newValue <= inputMaxAmount ? newValue : inputMaxAmount) : newValue
    setValue(name, f(result))
  }

  function smallestSameDigitCount(number: number) {
    const numStr = number.toString()
    const digitCount = numStr.length
    const smallestNumber = Math.pow(10, digitCount - 1)
    return smallestNumber
  }

  const onSubmit: SubmitHandler<InputProps> = (data) => {
    const param: HiloRoundInput = {
      agency_id: agencyId!,
      amount: data.amount?.replace(/,/g, ''),
      currency: wallet.currency,
      first_card: `${card.value}:${card.suit}`,
      game_id: game_id,
      player_name: playerName!,
      wallet_id: wallet.id,
    }

    dispatch(createNewRoundHilo(param)).then((res) => {})
  }

  const handleClickCashoutOrder = () => {
    const param: HiloCashOutInput = {
      game_id: game_id,
      round_id: gameRound.id,
    }
    setIsLoadingCashout(true)
    dispatch(cashoutOrderHilo(param))
      .then((res) => {
        if (res?.meta?.requestStatus === 'fulfilled') {
          showToastThrottle({
            title: t('notification.successfully'),
            status: 'success',
            isClosable: false,
            duration: 1500,
            icon: <ImCheckmark fontSize={32} />,
            containerStyle: {
              width: '60px',
              paddingBlock: '0px',
              pointerEvents: 'none',
            },
          })
        }
      })
      .finally(() => {
        setIsLoadingCashout(false)
      })
  }

  return (
    <form className="px-[10px] py-[16px] gap-0 mb-[5px] bg-[#25242B] -mt-[14px]" onSubmit={handleSubmit(onSubmit)}>
      <Stack className="flex w-full !flex-row items-start justify-between">
        <Stack>
          <Text fontSize={16} lineHeight={1}>Bet litmit: {config.minBetAmount}-{f(config.maxBetAmount)}</Text>
          <Text fontSize={16} lineHeight={1}>Profit litmit: {config.minBetAmount}-{f(config.maxProfit)}</Text>
        </Stack>
        <QuickFormInput
          leftIcon={<Image w="16px" src={getSymbolFromCurrency(wallet.currency)} alt="" />}
          inputProps={{
            ...register('amount'),
            placeholder: 'Amount',
            type: 'text',
            id: 'amount',
            onChange: handleChange,
          }}
          containerProps={{
            id: 'amountTooltip',
          }}
          minusCallback={() => handleMinus('amount')}
          plusCallback={() => handlePlus('amount')}
        />
      </Stack>
      {isRoundEnd ? (
        <ButtonBet text={'Start'} isLoading={isLoading}></ButtonBet>
      ) : (
        <ButtonBet
          inputProps={{
            type: 'button',
            isDisabled: gameRound?.hilo_bets?.length === 0,
            onClick: () => {
              handleClickCashoutOrder()
            },
          }}
          isLoading={isLoadingCashout}
          text={'Cashout'}
        ></ButtonBet>
      )}
    </form>
  )
}
