import {
  plinkoActions,
  selectPlinkoIsConguration,
  selectPlinkoLastOrder,
  selectPlinkoMuted,
} from '@/redux/store/modules/plinko.slice'
import { Flex, Image, Stack, Text } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@store'
import React, { useEffect } from 'react'
import { CountUp } from 'use-count-up'
import useSound from 'use-sound'
import { PlayFunction } from 'use-sound/dist/types'

export const WinnerConguration = () => {
  const isConguration = useAppSelector(selectPlinkoIsConguration)
  const order = useAppSelector(selectPlinkoLastOrder)
  const [play, audio] = useSound('/sound/mining.mp3')
  const dispatch = useAppDispatch()
  audio.pause()

  const { reward, balls = 0, amount } = order || {}
  const winAmount = +reward - +amount * balls

  useEffect(() => {
    if (!isConguration) return
    const timeout = setTimeout(() => {
      dispatch(plinkoActions.congurateSuccess())
    }, 4000)
    return () => clearTimeout(timeout)
  }, [isConguration])

  if (isConguration) {
    return (
      <Stack
        position="fixed"
        backgroundColor="rgba(0, 0, 0, 0.4)"
        zIndex={1000}
        p={4}
        top="1%"
        left="50%"
        transform="translateX(-50%)"
        gap={2}
        w="100%"
        className="!max-w-[var(--chakra-sizes-prose)]"
      >
        <Stack>
          <Audio play={play} pause={audio.pause} />
          <Image className="m-auto" w="500px" src="/images/win.gif" alt="" />
          <Flex background="rgba(0, 0, 0, 0.6)" marginTop={-30} justifyContent={'center'} alignItems="center" gap={2}>
            <Text fontSize={18} fontWeight={700}>
              Win
            </Text>

            <Text fontSize={24} fontWeight={700} color="#c7fb14">
              +
              <CountUp isCounting end={Number(winAmount.toFixed(2))} duration={2} />
            </Text>
          </Flex>
        </Stack>
      </Stack>
    )
  }
  return null
}

interface AudioProps {
  play: PlayFunction
  pause?: () => void
}

function Audio({ play, pause }: AudioProps) {
  const isMuted = useAppSelector(selectPlinkoMuted)

  useEffect(() => {
    if (isMuted) return
    play()
    return () => {
      if (pause) pause()
    }
  }, [isMuted])

  return <></>
}

export default WinnerConguration
