import { Image, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import { useAppSelector } from '@/redux/store'
import { selectCardHilo, selectGameRoundHilo } from '@/redux/store/modules/hilo'
import { getPositionCard, LIST_TYPE_BET_HILO } from '../ultis/ultis'
import { HiloRound } from '@/@generated/gql/graphql-hash'

export default function FlipList() {
  const cardRef = useRef(null)
  const swiperRef = useRef(null)
  const card = useAppSelector(selectCardHilo)
  const gameRound = useAppSelector(selectGameRoundHilo)
  //Set default card
  useEffect(() => {
    if (card && cardRef && cardRef.current) {
      const { x, y } = getPositionCard(card, 780)
      cardRef.current.style.backgroundPosition = `-${x}px -${y}px`
    }
  }, [card])

  useEffect(() => {
    if(gameRound?.max_index && swiperRef && swiperRef.current) {
      swiperRef.current.slideTo(swiperRef.current.slides.length - 1);
    }
  }, [gameRound?.max_index])
  return (
    <Stack position="relative" bg="#0b0d1d" height="120px" borderRadius="24px">
      <Image
        src="/images/bg-flip-list.webp"
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        width="170px"
      />
      <Stack px={2} py={3} h="100%">
        <Swiper
          slidesPerView={7}
          className={`h-full w-full z-[${1}]`}
          onInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {gameRound?.id ? (
            gameRound?.result?.slice(0, gameRound?.max_index).map((item: any, index: number) => (
              <SwiperSlide key={index} className={`relative`}>
                <FlipItem gameRound={gameRound} item={item} index={index} />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <Stack
                h="82%"
                w="fit-content"
                aspectRatio={125 / 164}
                ref={cardRef}
                backgroundImage="url(/images/cards-spirit.png)"
                backgroundPosition="60px 0px"
                backgroundSize="780px"
                zIndex={1}
                // className="rotate-card-anim-1"
              ></Stack>
              <Text fontSize={14} lineHeight={1} mt="4px" ml="16px">
                Start
              </Text>
            </SwiperSlide>
          )}
          {}
        </Swiper>
      </Stack>
    </Stack>
  )
}

export const FlipItem = ({ item, index, gameRound }: { item: string; index: number; gameRound: HiloRound }) => {
  const cardRef = useRef(null)

  const card = useMemo(() => {
    const [value, suit] = item.split(':')
    return {
      value: value,
      suit: suit,
    }
  }, [item])

  //Set card
  useEffect(() => {
    if (card && cardRef && cardRef.current) {
      const { x, y } = getPositionCard(card, 780)
      cardRef.current.style.backgroundPosition = `-${x}px -${y}px`
    }
  }, [card])

  const hilo_bet = gameRound?.hilo_bets?.filter((item) => item.index === index + 1)?.[0]
  const multiplier = hilo_bet?.multiplier
  const betType = hilo_bet?.bet

  const getPositionXY = useMemo(() => {
    const position = {
      x: !betType ? 0 : 22 * LIST_TYPE_BET_HILO.indexOf(betType), // px
      y: 0, // px
    }
    return `${position.x !== 0 ? '-' : ''}${position.x}px ${position.y}px` //backgroundPosition
  }, [gameRound.id])

  return (
    <>
      {index > 0 && (
        <Stack
          backgroundImage="url(/images/list-status-hilo.png)"
          backgroundPosition={!multiplier ? `44px 0px` : +multiplier > 0 ? `0px 0px` : '-22px 0px'}
          backgroundSize="88px"
          position="absolute"
          top={1}
          right={3}
          w="22px"
          h="22px"
          zIndex={1}
        ></Stack>
      )}

      {index > 0 && (
        <Stack
          background="#232324"
          borderRadius={4}
          position="absolute"
          top="48%"
          transform="translateY(-48%)"
          left="-16px"
          w="22px"
          h="22px"
          zIndex={2}
        >
          <Stack
            backgroundImage="url(/images/list-btn-hilo.png)"
            backgroundPosition={getPositionXY}
            backgroundSize="242px"
            w="100%"
            h="100%"
          ></Stack>
        </Stack>
      )}

      <Stack
        h="82%"
        w="fit-content"
        aspectRatio={125 / 164}
        ref={cardRef}
        //   height="164px"
        backgroundImage="url(/images/cards-spirit.png)"
        backgroundPosition="60px 0px"
        backgroundSize="780px"
        //   position="absolute"
        //   top={0}
        //   left={0}
        //   w="100%"
        //   h="100%"
        zIndex={1}
        // className="rotate-card-anim-1"const isWin = +order?.reward > 0
      ></Stack>
      <Text fontSize={14} lineHeight={1} mt="4px" ml="16px">
        {index === 0 && `Start`}
        {index !== 0 && +multiplier > 0 && `${multiplier}x`}
        {index !== 0 && +multiplier === 0 && `0.00x`}
        {index !== 0 && !multiplier && `1.00x`}
      </Text>
    </>
  )
}
