import React from 'react'
import {
  Container, Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react'

export type BingoDrawerProps = DrawerProps & {
  title?: string | React.ReactNode,
  showOverlay?: boolean,
}

const BingoDrawer = ({title, showOverlay = true, children, ...rest}: BingoDrawerProps) => {
  return (
    <Drawer
      placement="bottom"
      isFullHeight={false}
      preserveScrollBarGap={true}
      {...rest}
    >
      {showOverlay && (
        <DrawerOverlay
          background="rgba(0, 0, 0, 0.7)"
        />
      )}
      <DrawerContent
        background="none"
      >
        <Container
          p={0}
          borderTopRadius={'12px'}
          overflow={'hidden'}
          border="1px solid #fff"
          background="#fde8e8"
          position="relative"
        >
          <DrawerCloseButton />
          {title && (
            <DrawerHeader
              background="linear-gradient(180deg, #f9b8bc 0%, #fdcdb7 100%)"
              color="#e82013"
              fontSize="24px"
              lineHeight="1"
              fontWeight="500"
              textAlign="center"
            >
              {title}
            </DrawerHeader>
          )}
          <DrawerBody
            background="none"
          >
            {children}
          </DrawerBody>
        </Container>
      </DrawerContent>
    </Drawer>
  )
}

export default BingoDrawer;