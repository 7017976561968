import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

type RocketInputSubTitleProps = TextProps

const RocketInputSubTitle = ({children, ...rest}: RocketInputSubTitleProps) => {
  return (
    <Text
      color="#636d7d"
      fontSize="14px"
      lineHeight="1"
      {...rest}
    >
      {children}
    </Text>
  )
}

export default RocketInputSubTitle;
