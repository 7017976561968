import { BingoOrder, CreateCrashOrderInput } from '@/@generated/gql/graphql-hash'
import { BingoGameState, BingoGameStep, LocalBingoTicket } from '@/bingo/types/bingo.ts'
import { BingoRound, ConfigRound } from '@/redux/store/entities/game.entity'
import { createThunk } from '@/redux/store/modules/common'
import { createBingoOrderMutate } from '@/redux/store/services/queries'
import { gqlClient } from '@/utils/apollo-client'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store'

const initialState: BingoGameState = {
  numberOfTickets: 1,
  currentStep: 'randomTicket',
  ticketsGenerated: [],
  ticketsCollected: [],
  ticketUnitPrice: 2,
  winningTicket: undefined,
  gameRound: {} as BingoRound,
  roundConfig: {} as ConfigRound,
}

const bingoSlice = createSlice({
  name: 'bingo',
  initialState,
  reducers: {
    updateNumberOfTickets: (state, action: PayloadAction<number>) => {
      state.numberOfTickets = action.payload
    },
    updateCurrentStep: (state, action: PayloadAction<BingoGameStep>) => {
      state.currentStep = action.payload
    },
    updateTicketsGenerated: (state, action: PayloadAction<LocalBingoTicket[]>) => {
      state.ticketsGenerated = action.payload
    },
    updateTicketsCollected: (state, action: PayloadAction<LocalBingoTicket[]>) => {
      state.ticketsGenerated = action.payload
    },
    updateTicketUnitPrice: (state, action: PayloadAction<number>) => {
      state.ticketUnitPrice = action.payload
    },
    updateWinningTicket: (state, action: PayloadAction<LocalBingoTicket>) => {
      state.winningTicket = action.payload
    },
    updateGameRound: (state, action: PayloadAction<BingoRound>) => {
      state.gameRound = action.payload
    },
    updateRoundConfig: (state, action: PayloadAction<ConfigRound>) => {
      state.roundConfig = action.payload
    },
  },
})

export const selectBingoNumbersOfTickets = (state: RootState) => state.bingo.numberOfTickets
export const selectBingoCurrentStep = (state: RootState) => state.bingo.currentStep
export const selectBingoTicketsGenerated = (state: RootState) => state.bingo.ticketsGenerated
export const selectBingoTicketsCollected = (state: RootState) => state.bingo.ticketsCollected
export const selectBingoTicketUnitPrice = (state: RootState) => state.bingo.ticketUnitPrice
export const selectBingoWinningTicket = (state: RootState) => state.bingo.winningTicket
export const selectBingoGameRound = (state: RootState) => state.bingo.gameRound
export const selectBingoRoundConfig = (state: RootState) => state.bingo.roundConfig

export const createNewOrder = createThunk('orders/createNewOrder', async (input: CreateCrashOrderInput) => {
  const resp = await gqlClient?.mutate<BingoOrder>({
    mutation: createBingoOrderMutate,
    variables: {
      input,
    },
  })
  return resp?.data
})

export const bingoActions = {
  ...bingoSlice.actions,
  createNewOrder,
}

export default bingoSlice
