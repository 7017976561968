import { useAppDispatch, useAppSelector } from '@/redux/store'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice'
import { selectPlinkoIsPreventing } from '@/redux/store/modules/plinko.slice'
import { getWalletById, selectWallet } from '@/redux/store/modules/wallet.slice'
import { useEffect } from 'react'

export function usePlinkoWallet() {
  const dispatch = useAppDispatch()
  const wallet = useAppSelector(selectWallet)
  const isRefetchWallet = useAppSelector(selectIsLoadingAction(getWalletById.pending))
  const isPreventing = useAppSelector(selectPlinkoIsPreventing)

  useEffect(() => {
    if (!wallet?.id || isRefetchWallet || isPreventing) return
    dispatch(getWalletById(wallet.id))
  }, [wallet?.id, isPreventing])
}
