import React from 'react';
import { Box } from '@chakra-ui/react';

type PatternCellProps = {
  variant: 'dark'| 'light',
}

const bgByVariant = {
  dark: '#380604',
  light: '#fff4ca'
}

const PatternCell = ({variant}: PatternCellProps) => {
  return (
    <Box
      background={bgByVariant[variant]}
      width="6px"
      height="6px"
    />
  )
}

export default PatternCell;