import React from 'react';
import { Box, Flex, FlexProps, Text } from '@chakra-ui/react'
import FormButtonBig from '@/bingo/components/FormSection/FormButtonBig.tsx'

type ExtraBallProps = FlexProps & {
  value?: number,
  state: 'waiting' | 'countdown' | 'hasValue',
  countdownSeconds?: number,
}

const backgroundByState: Record<string, string> = {
  waiting: 'url("/bingo/images/extra-ball-waiting.png") no-repeat center',
  countdown: 'white',
  hasValue: '#d9d9d9',
}

const ExtraBall = ({value, countdownSeconds, state = 'waiting'}: ExtraBallProps) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      background={backgroundByState[state]}
      aspectRatio="1/1"
      borderRadius="50%"
      backgroundSize="cover"
      border={state !== 'waiting' ? '2.5px solid #d9d9d9' : ''}
      fontSize="20px"
      width="calc(20% - 10px)"
      minWidth="calc(20% - 10px)"
    >
      {state === 'hasValue' && (value)}
      {state === 'countdown' && (
        <Flex
          width="70%"
          height="70%"
          backgroundColor="#d9d9d9"
          borderRadius="50%"
          lineHeight="1"
          alignItems="center"
          justifyContent="center"
          color="#00c504"
        >
          3
        </Flex>
      )}
    </Flex>
  )
}

const ExtraBallsDrawer = () => {
  return (
    <Box>
      <Box
        position="relative"
        textAlign="center"
        color="#560f40"
        fontSize="20px"
        lineHeight="1"
        padding="10px"
        zIndex="0"
      >
        <Flex
          position="absolute"
          top="50%"
          left="0"
          transform="translateY(-50%)"
          alignItems="center"
        >
          <Text color="#ff4218">4</Text>/10
        </Flex>
        <Text>Total bonus: 294</Text>
      </Box>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap="20px"
      >
        <Flex
          flexWrap="wrap"
          flex="1"
          gap="10px"
        >
          <ExtraBall state="hasValue" value={15} />
          <ExtraBall state="hasValue" value={55} />
          <ExtraBall state="hasValue" value={57} />
          <ExtraBall state="countdown" countdownSeconds={10} />
          <ExtraBall state="waiting" />
          <ExtraBall state="waiting" />
          <ExtraBall state="waiting" />
          <ExtraBall state="waiting" />
          <ExtraBall state="waiting" />
          <ExtraBall state="waiting" />
        </Flex>
        <FormButtonBig
          padding="40px 20px"
        >
          Extra ball<br />73.83
        </FormButtonBig>
      </Flex>
    </Box>
  )
}

export default ExtraBallsDrawer;
