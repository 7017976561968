import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createThunk } from './common'
import {
  FilterInput,
  HiloCashOutInput,
  HiloOrderInput,
  HiloRound,
  HiloRoundInput,
  HiloSwitchInput,
  RoundStatus,
} from '@/@generated/gql/graphql-hash'
import { gqlClient } from '@/utils/apollo-client'
import { gql } from '@apollo/client'
import { RootState } from '..'
import ls from '@/libs/local-storage'

export interface HiloState {
  card: HiloCardType
  gameRound: HiloRound
  configRound: any
  lastOrder: HiloRound
  myOrders: HiloRound[]
  isLoading: boolean
  isLoadingCashout: boolean
  isRoundEnd: boolean
  totals: number
  treasure: any
}

const initialState: HiloState = {
  card: {} as HiloCardType,
  gameRound: {} as HiloRound,
  lastOrder: {} as HiloRound,
  myOrders: [] as HiloRound[],
  configRound: {},
  isLoading: false,
  isLoadingCashout: false,
  isRoundEnd: true,
  totals: 0,
  treasure: '',
}

export const createRoundMutate = gql`
  mutation createHiloRound($input: HiloRoundInput!) {
    createHiloRound(input: $input) {
      id
      game_id
      server_seed
      client_seed
      random_value
      proof
      result
      player_id
      player_name
      amount
      currency
      switch_time
      win_streak
      reward
      odds
      treasures
      last_time
      created_at
      max_index
      round_status
      pay_for_switch
      hilo_bets {
        id
        round_id
        bet
        index
        multiplier
        created_at
      }
    }
  }
`

export const createOrderMutate = gql`
  mutation createHiloOrder($input: HiloOrderInput!) {
    createHiloOrder(input: $input) {
      id
      game_id
      server_seed
      client_seed
      random_value
      proof
      result
      player_id
      player_name
      currency
      amount
      switch_time
      win_streak
      reward
      odds
      treasures
      last_time
      created_at
      max_index
      round_status
      pay_for_switch
      hilo_bets {
        id
        round_id
        bet
        index
        multiplier
        created_at
      }
    }
  }
`

export const switchCardInRoundMutate = gql`
  mutation switchHiloOrder($input: HiloSwitchInput!) {
    switchHiloOrder(input: $input) {
      id
      game_id
      server_seed
      client_seed
      random_value
      proof
      result
      player_id
      player_name
      amount
      switch_time
      win_streak
      currency
      reward
      odds
      treasures
      last_time
      created_at
      max_index
      round_status
      pay_for_switch
      hilo_bets {
        id
        round_id
        bet
        index
        multiplier
        created_at
      }
    }
  }
`

export const cashoutOrderMutate = gql`
  mutation cashOutHiloRound($input: HiloCashOutInput!) {
    cashOutHiloRound(input: $input) {
      id
      game_id
      server_seed
      client_seed
      random_value
      currency
      proof
      result
      player_id
      player_name
      amount
      switch_time
      win_streak
      reward
      odds
      treasures
      last_time
      created_at
      max_index
      round_status
      pay_for_switch
      hilo_bets {
        id
        round_id
        bet
        index
        multiplier
        created_at
      }
    }
  }
`

export const getLastRoundQuery = gql`
  query hiloLastRound {
    hiloLastRound {
      id
      game_id
      server_seed
      client_seed
      currency
      random_value
      proof
      result
      player_id
      player_name
      amount
      switch_time
      win_streak
      reward
      odds
      treasures
      last_time
      created_at
      max_index
      round_status
      pay_for_switch
      hilo_bets {
        id
        round_id
        bet
        index
        multiplier
        created_at
      }
    }
  }
`

export const getListOrdersQuery = gql`
  query hiloRoundHistory($input: FilterInput!) {
    hiloRoundHistory(input: $input) {
      data {
        id
        game_id
        server_seed
        client_seed
        random_value
        proof
        result
        player_id
        player_name
        amount
        currency
        switch_time
        win_streak
        reward
        odds
        treasures
        last_time
        created_at
        max_index
        round_status
        pay_for_switch
        hilo_bets {
          id
          round_id
          bet
          index
          multiplier
          created_at
        }
      }
      limit
      page
      total
    }
  }
`

export const createNewRoundHilo = createThunk('hilo/createNewRound', async (input: HiloRoundInput) => {
  const resp = await gqlClient?.mutate<any>({
    mutation: createRoundMutate,
    variables: {
      input,
    },
  })
  return resp?.data
})

export const createNewOrderHilo = createThunk('hilo/createNewOrderHilo', async (input: HiloOrderInput) => {
  const resp = await gqlClient?.mutate<any>({
    mutation: createOrderMutate,
    variables: {
      input,
    },
  })
  return resp?.data
})

export const switchCardInRoundHilo = createThunk('hilo/switchCardInRoundHilo', async (input: HiloSwitchInput) => {
  const resp = await gqlClient?.mutate<any>({
    mutation: switchCardInRoundMutate,
    variables: {
      input,
    },
  })
  return resp?.data
})

export const cashoutOrderHilo = createThunk('hilo/cashoutOrderHilo', async (input: HiloCashOutInput) => {
  const resp = await gqlClient?.mutate<any>({
    mutation: cashoutOrderMutate,
    variables: {
      input,
    },
  })
  return resp?.data
})

export const getLastRoundHilo = createThunk('hilo/getLastRound', async (input: any) => {
  const resp = await gqlClient?.query<any>({
    query: getLastRoundQuery,
    // variables: {
    //   input,
    // },
  })
  return resp?.data
})

export const getListOrdersHilo = createThunk('betting/getListOrdersHilo', async (input: FilterInput) => {
  const resp = await gqlClient?.query<any>({
    query: getListOrdersQuery,
    variables: {
      input: input,
    },
  })
  return resp?.data?.hiloRoundHistory
})

export const hiloSlice = createSlice({
  name: 'hilo',
  initialState,
  reducers: {
    updateGameRound: (state, action: PayloadAction<HiloRound>) => {
      state.gameRound = action.payload
    },
    updateCard: (state, action: PayloadAction<any>) => {
      state.card = action.payload
    },
    updateMyOrders: (state, action: PayloadAction<any>) => {
      state.myOrders = [action.payload, ...state.myOrders]
    },
    updateIsRoundEnd: (state, action: PayloadAction<any>) => {
      state.isRoundEnd = action.payload
    },
    updateConfigRound: (state, action: PayloadAction<any>) => {
      state.configRound = action.payload
    },
    updateTreasure: (state, action: PayloadAction<any>) => {
      state.treasure = action.payload
    },
    updateMyOrdersFromMqtt: (state, action: PayloadAction<any>) => {
      const order = action.payload
      state.myOrders = state.myOrders.filter((item) => item?.id !== order?.id)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewRoundHilo.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(createNewRoundHilo.fulfilled, (state, action) => {
        console.log('{gameRound, createHiloRound}: ', action?.payload)
        const resp = action.payload?.createHiloRound
        state.myOrders = [resp, ...state.myOrders]
        state.gameRound = resp
        state.isLoading = false
        state.isRoundEnd = false
      })
      .addCase(createNewRoundHilo.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(createNewOrderHilo.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(createNewOrderHilo.fulfilled, (state, action) => {
        console.log('{lastOrder, createHiloOrder}: ', action?.payload)
        const resp = action.payload?.createHiloOrder
        state.gameRound = resp

        // eslint-disable-next-line no-unsafe-optional-chaining
        const [value, suit] = resp?.result?.[resp?.max_index - 1]?.split(':')
        const card = {
          value: value,
          suit: suit,
        }
        state.card = card
        ls.set('card-hilo', card)
        state.isLoading = false
        if (resp?.round_status === RoundStatus.Lose) {
          state.isRoundEnd = true
          state.treasure = ''
          state.myOrders = [resp, ...state.myOrders.slice(1, state.myOrders.length)]
        }
      })
      .addCase(createNewOrderHilo.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(cashoutOrderHilo.fulfilled, (state, action) => {
        console.log('{gameRound, cashoutOrderHilo}: ', action?.payload)
        state.gameRound = action.payload?.cashOutHiloRound
        state.myOrders = [action.payload?.cashOutHiloRound, ...state.myOrders.slice(1, state.myOrders.length)]
        state.isRoundEnd = true
        state.treasure = ''
      })
      .addCase(getLastRoundHilo.fulfilled, (state, action) => {
        if (action.payload?.hiloLastRound?.round_status === RoundStatus.Running) {
          const resp = action.payload?.hiloLastRound
          state.gameRound = resp
          state.isRoundEnd = false
          // eslint-disable-next-line no-unsafe-optional-chaining
          const [value, suit] = resp?.result?.[resp?.max_index - 1]?.split(':')
          const card = {
            value: value,
            suit: suit,
          }
          state.card = card
          ls.set('card-hilo', card)
        }
      })
      .addCase(getListOrdersHilo.fulfilled, (state, action) => {
        state.myOrders = action.payload?.data
        state.totals = action.payload?.total
      })
      .addCase(switchCardInRoundHilo.fulfilled, (state, action) => {
        const resp = action.payload?.switchHiloOrder
        state.gameRound = resp

        // eslint-disable-next-line no-unsafe-optional-chaining
        const [value, suit] = resp?.result?.[resp?.max_index - 1]?.split(':')
        const card = {
          value: value,
          suit: suit,
        }
        state.card = card
        ls.set('card-hilo', card)
      })
  },
})

export const selectGameRoundHilo = (state: RootState) => state.hilo.gameRound
export const selectCardHilo = (state: RootState) => state.hilo.card
export const selectLoadingHilo = (state: RootState) => state.hilo.isLoading
export const selectIsRoundEndHilo = (state: RootState) => state.hilo.isRoundEnd
export const selectConfigHilo = (state: RootState) => state.hilo.configRound
export const selectMyOrdersHilo = (state: RootState) => state.hilo.myOrders
export const selectTotalOrdersHilo = (state: RootState) => state.hilo.totals
export const selectTreasureHilo = (state: RootState) => state.hilo.treasure

export const hiloActions = {
  ...hiloSlice.actions,
}

export default hiloSlice
