import { bgInput } from '@/const/color'
import { currencyMap } from '@/const/currency'
import { formatNumberInput } from '@/const/format'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { selectIsLoadingAction } from '@/redux/store/modules/common.slice'
import {
  createPlinkoOrder,
  plinkoActions,
  selectPlinkoBallCount,
  selectPlinkoBetAmount,
  selectPlinkoIsPreventing,
  selectPlinkoMuted,
  selectPlinkoRiskLevel,
  selectPlinkoRows,
} from '@/redux/store/modules/plinko.slice'
import { selectActiveWallet } from '@/redux/store/modules/wallet.slice'
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { lineConfigs, plinkoGameConfigs, RiskLevel } from '../../config'

interface InputAmountProps {
  disabled?: boolean
  className?: string
}

function InputAmount({ disabled, className = '' }: InputAmountProps) {
  const amount = useAppSelector(selectPlinkoBetAmount)

  const dispatch = useAppDispatch()
  const onChange = (value: number) => dispatch(plinkoActions.betAmountUpdated(value + ''))
  const currency = useAppSelector(selectActiveWallet)?.currency
  const audioRef: any = useRef<HTMLAudioElement | null>(null)
  const isMuted = useAppSelector(selectPlinkoMuted)

  const configRule = {
    maxAmount: 1000000,
    minAmount: 0.1,
  }

  useEffect(() => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [isMuted])

  function handleChange(val: string): void {
    onChange(Number(val.replace(/[^0-9]/g, '')))
  }

  return (
    <div className={className}>
      <audio autoPlay ref={audioRef} src="/sound/click.mp3" />
      <Flex
        backgroundColor={bgInput}
        alignItems="center"
        justifyContent="space-between"
        p={1}
        border="1px solid #29292f"
        className="h-full rounded-md"
      >
        <button
          disabled={disabled}
          aria-label="Minus"
          aria-labelledby="Minus"
          className="h-full w-12 flex items-center justify-center"
          onClick={() => {
            if (audioRef.current) {
              audioRef.current.play()
            }
            const currentValue = Number(amount)
            const digitCount = currentValue.toString().length
            const smallestNumber = 10 ** (digitCount - 1)
            const munis = 10 ** (digitCount - 1) - 10 ** (digitCount - 2)
            const largestNumberWithOneDigitLess = munis < configRule.minAmount ? configRule.minAmount : munis
            const newValue = Math.max(smallestNumber - 10 ** (digitCount - 1), currentValue - smallestNumber)
            const resultValue = newValue < largestNumberWithOneDigitLess ? largestNumberWithOneDigitLess : newValue
            onChange(resultValue)
          }}
          style={{ padding: '3px', background: '#29292f', color: '#68686f', borderRadius: 4 }}
        >
          <FaMinus />
        </button>

        <InputGroup position="relative">
          <InputLeftElement top="50%" transform="translateY(-50%)">
            {currencyMap[currency]?.icon}
          </InputLeftElement>
          <Input
            max={configRule.maxAmount}
            value={formatNumberInput(amount)}
            onChange={(e) => handleChange(e.target.value)}
            textAlign="center"
            placeholder="Bet Amount"
            _placeholder={{ fontWeight: 500, color: '#2e2e2e', fontSize: 12 }}
            type="text"
            fontSize={18}
            fontWeight={700}
            border="none"
            outline="none !important"
            boxShadow="none !important"
            position="relative"
            inputMode="decimal"
            isDisabled={disabled}
          />
        </InputGroup>
        <button
          disabled={disabled}
          aria-label="Add"
          aria-labelledby="Add"
          className="h-full w-12 flex items-center justify-center"
          onClick={() => {
            if (audioRef.current) {
              audioRef.current.play()
            }
            const increment = smallestSameDigitCount(Number(amount))
            let newValue = Number(amount) + increment
            if (newValue < 10) {
              newValue = 10
            }
            const value = newValue <= configRule.maxAmount ? newValue : configRule.maxAmount
            onChange(value)
          }}
          style={{ background: '#29292f', color: '#68686f', borderRadius: 4 }}
        >
          <FaPlus />
        </button>
      </Flex>
    </div>
  )
}
function smallestSameDigitCount(number: number) {
  const numStr = number.toString()
  const digitCount = numStr.length
  const smallestNumber = Math.pow(10, digitCount - 1)
  return smallestNumber
}

interface GameSliderProps {
  label: string
  min: number
  max: number
  value: number
  isShowValue: boolean
  onChange: (value: number) => void
  disabled?: boolean
}

function GameSlider({ label, min, max, isShowValue, value, disabled, onChange }: GameSliderProps) {
  return (
    <div className="flex items-center w-full">
      <Text isTruncated className="mr-4 uppercase font-medium">
        {label}
      </Text>
      <div className="flex items-center flex-1 pl-4 pr-6 rounded-lg bg-[#33333380] h-full">
        {isShowValue && <Box className="mr-2 w-6 text-center font-bold">{value}</Box>}
        <Slider
          aria-label="slider"
          min={min}
          max={max}
          step={1}
          value={value}
          onChange={(val) => onChange(val)}
          colorScheme="blue"
          className="flex-1 h-8"
          isDisabled={disabled}
        >
          <SliderTrack bg="blue.100">
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
      </div>
    </div>
  )
}

const riskLevelToValue: Record<RiskLevel, number> = {
  low: 0,
  mid: 1,
  high: 2,
}
const riskValueToLevel: RiskLevel[] = ['low', 'mid', 'high']

export function BetActions() {
  const dispatch = useAppDispatch()
  const lines = useAppSelector(selectPlinkoRows)
  const balls = useAppSelector(selectPlinkoBallCount)
  const riskLevel = useAppSelector(selectPlinkoRiskLevel)
  const risk = riskLevelToValue[riskLevel]
  const setRisk = (value: number) => {
    dispatch(plinkoActions.riskLevelUpdated(riskValueToLevel[value]))
  }
  const { t } = useCustomTranslation()

  const isBetting = useAppSelector(selectIsLoadingAction(createPlinkoOrder.pending))
  const isPreventing = useAppSelector(selectPlinkoIsPreventing)

  async function handleRunBet() {
    if (isBetting || isPreventing) return

    dispatch(createPlinkoOrder())
  }
  const textButton = balls == 1 ? t('plinko.roll1Ball') : t('plinko.rollBall', { balls })

  return (
    <div className="mt-3 w-full rounded-xl p-2 bg-[#232327]">
      <div className="flex flex-row h-10">
        <InputAmount disabled={isPreventing} className="flex-1" />
        <Stack
          className="h-full pb-1 flex-1 ml-4 "
          borderBottom="1px solid #c3fb04"
          borderBottomColor={isBetting || isPreventing ? '#c3fb0480' : '#c3fb04'}
          borderRadius={8}
          position="relative"
        >
          <Button
            colorScheme="c3fb04"
            style={{ height: '90%' }}
            className="bg-[#c3fb04]"
            width="100%"
            color="black"
            borderRadius={8}
            boxShadow="0px 3px 0px  #799c00 !important"
            _active={{
              transform: 'translateY(5px)',
              // boxShadow: '0px 0px 0px  #80a500',
            }}
            _disabled={{
              opacity: 0.5,
              cursor: 'not-allowed',
            }}
            _after={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              right: 0,
              transform: 'translateX(50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            _before={{
              content: `""`,
              position: 'absolute',
              width: 2,
              height: 4,
              backgroundColor: '#000',
              left: 0,
              transform: 'translateX(-50%)',
              borderRadius: 12,
              background: '#6b8a00',
            }}
            sx={{
              background: 'url(/images/btnBet.webp) no-repeat top center !important',
              backgroundSize: 'cover',
            }}
            loadingText={textButton}
            isLoading={isBetting}
            onClick={handleRunBet}
            isDisabled={isBetting || isPreventing}
          >
            {textButton}
          </Button>
        </Stack>
      </div>
      <div className="flex flex-row h-10 mt-2">
        <GameSlider
          label={t('plinko.rows')}
          min={lineConfigs.minLines}
          max={lineConfigs.maxLines}
          value={lines}
          onChange={(rows) => dispatch(plinkoActions.rowsUpdated(rows))}
          isShowValue
          disabled={isPreventing}
        />
        <div className="w-8" />
        <GameSlider
          label={t('plinko.risk')}
          min={0}
          max={2}
          value={risk}
          onChange={setRisk}
          isShowValue={false}
          disabled={isPreventing}
        />
      </div>
      <div className="flex flex-row h-10 mt-2">
        <GameSlider
          label={t('plinko.balls')}
          min={plinkoGameConfigs.balls.min}
          max={plinkoGameConfigs.balls.max}
          value={balls}
          onChange={(balls) => dispatch(plinkoActions.ballsUpdated(balls))}
          isShowValue
          disabled={isPreventing}
        />
      </div>
    </div>
  )
}
