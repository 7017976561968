import { Flex, FlexProps, InputProps } from '@chakra-ui/react'
import React from 'react'
import { HTMLProps, ReactNode } from 'react'
import BtnQuickChange from '@components/UI/quick-form/BtnQuickChange.tsx'
import RocketInput from '@components/rocket/NewUI/Common/RocketInput.tsx'

type RocketInputProps = {
  plusCallback?: (params?: any) => any,
  minusCallback?: (params?: any) => any,
  containerProps?: HTMLProps<HTMLDivElement> & FlexProps,
  inputProps?: HTMLProps<HTMLInputElement> & InputProps,
  leftIcon?: ReactNode,
  isFormDisabled?: boolean,
}

const AutoEscapeInput = (props: RocketInputProps) => {
  const { plusCallback, minusCallback, containerProps, inputProps, leftIcon, isFormDisabled } = props

  const handleOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const char = e.key
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']
    if ((e.ctrlKey || e.metaKey) && char === 'r') {
      console.log('Ctrl+R or Cmd+R pressed!')
      return true
    }

    if (controlKeys.includes(char)) {
      return true
    }
    const input = e.currentTarget.value
    const [integerPart, decimalPart] = input.split('.')

    if (decimalPart && decimalPart.replace(/[^0-9.]/g, '').length >= 2) {
      e.preventDefault()
    }

    if (char >= '0' && char <= '9') {
      return true
    }

    if (char === '.') {
      return e.currentTarget.value.indexOf('.') === -1
    }

    e.preventDefault()
    return false
  }

  return (
    <Flex
      backgroundColor="#1a212a"
      alignItems="center"
      justifyContent="space-between"
      padding="5.3px"
      border="1px solid #2a3444 "
      borderRadius={8}
      height="auto"
      position="relative"
      gap="5px"
      lineHeight="1"
      {...containerProps}
    >
      {leftIcon && leftIcon}
      <RocketInput
        // onChange={(e) => handleChange(e)}
        onKeyDown={handleOnInput}
        {...inputProps}
      />
      <Flex alignItems="center" gap="4.6px">
        <BtnQuickChange changeType="minus" onBtnClick={minusCallback} isDisabled={isFormDisabled} />
        <BtnQuickChange changeType="plus" onBtnClick={plusCallback} isDisabled={isFormDisabled} />
      </Flex>
    </Flex>
  )
}

export default AutoEscapeInput

