import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

type GuideButtonsContainerProps = BoxProps & {
  children?: React.ReactNode,
}

const GuideButtonsContainer = ({children, ...rest}: GuideButtonsContainerProps) => {
  return (
    <Box
      position="absolute"
      top="5px"
      left="50%"
      transform="translateX(-50%)"
      {...rest}
    >
      {children}
    </Box>
  )
}

export default GuideButtonsContainer;
