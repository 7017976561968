import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import ButtonCircle from '@/bingo/components/Common/ButtonCircle.tsx';

const ResultHash = () => {
  return (
    <Box
      background="url('/bingo/images/bg-bingo-ribbon.png') no-repeat center"
      backgroundSize="contain"
      aspectRatio="703/259"
      position="absolute"
      top="-40px"
      left="0"
      right="0"
      zIndex="1"
      pointerEvents="none"
    >
      <Flex
        position="absolute"
        left="70px"
        right="70px"
        bottom="60px"
        alignItems="center"
        justifyContent="space-between"
        gap="2px"
      >
        <ButtonCircle>
          ?
        </ButtonCircle>
        <Box
          color="#c31"
          fontSize="13px"
          lineHeight="1.05"
          wordBreak="break-word"
          whiteSpace="normal"
        >
          <Text>
            Verify Result hash:
          </Text>
          <Text>
            4101fe9a56577c6d83b6261341f821a714775fb6f30961137e0421513e1d03e8
          </Text>
        </Box>
        <ButtonCircle>
          b
        </ButtonCircle>
      </Flex>
    </Box>
  )
}

export default ResultHash;
