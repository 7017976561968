export const fairnessZh = `
<h1>如何通过哈希验证球路径?</h1>
<img src="/images/plinko/plinko.ex.0.png" />
<div>
  在Plinko游戏中，当球被释放时，系统会使用哈希函数生成一个 <tag>随机数</tag> 字符串，并将其与
  <tag>球索引</tag> 结合，转换为 <tag>路径位</tag>，从而确定
  <tag>球路径</tag>。这一过程确保了球的路径是不可预测的、公平的，并且对所有玩家都是透明的。
</div>
<h2>例子：</h2>
<div>假设你正在玩Plinko游戏，并且你释放了5个球，游戏有8行。每个球将拥有一个独特的球索引（0, 1, 2, 3, 4）。</div>
<img src="/images/plinko/plinko.ex.1.png" />
<img src="/images/plinko/plinko.ex.2.png" />
<h3>步骤 1：球索引</h3>
<ul>
  <li><b>球 0:</b> 球索引 = 0</li>
  <li><b>球 1:</b> 球索引 = 1</li>
  <li><b>球 2:</b> 球索引 = 2</li>
  <li><b>球 3:</b> 球索引 = 3</li>
  <li><b>球 4:</b> 球索引 = 4</li>
</ul>

<h3>步骤 2：随机数字符串</h3>
<div>
  在Plinko游戏的每一轮开始时，系统会生成一个随机数字符串。这个随机数字符串将与球索引结合，用来生成每个球的最终
  <tag>随机数字符串</tag>。
</div>
<div>例如：</div>
<ul>
  <li>
    假设本轮生成的随机数为
    <b>122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6</b>
  </li>
  <img src="/images/plinko/plinko.ex.3.png" />
  <li>
    对于球
    0，系统将随机数与球索引（0）结合，生成随机数字符串：122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b
      >0</b
    >
  </li>
  <li>
    对于球
    1，系统将随机数与球索引（1）结合，生成随机数字符串：122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b
      >1</b
    >
  </li>
</ul>

<h3>步骤 3：生成哈希</h3>
<div>系统通过哈希函数处理随机数字符串。该函数生成一个固定长度的哈希值，代表输入的随机数据。</div>
<div>例如：</div>

<ul>
  <li>
    <b>球 0 的随机数字符串</b> (122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b>0</b>)
    生成的哈希值如下：57a0df686fc842a7c930492feafa337b1129b99583f16e5303613151b8b4ae96.
  </li>
  <li>
    <b>球 1 的随机数字符串</b> (122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc6<b>1</b>)
    生成的哈希值如下：a4b716af92f0d050b12068e3659d99f5ccd3f8475813b87e6306e039bb5ce283.
  </li>
</ul>

<div>你可以使用此哈希验证工具来验证系统生成的哈希值：</div>
<a href="https://emn178.github.io/online-tools/sha256.html" target="_blank"
  >https://emn178.github.io/online-tools/sha256.html</a
>

<h3>步骤 4：将哈希值转换为路径位</h3>
<div>
  然后，将哈希值转换为路径位 <tag>Path Bit</tag>，即一系列二进制数字（0 和 1）。这些数字将决定球在 Plinko
  游戏板上的路径。所需的位数取决于 Plinko 游戏板的行数（钉子的数量）。在这个例子中：
</div>
<ul>
  <li><b>8 行 → 8 位。</b></li>
  <li><b>0</b>表示球向左 (L)。</li>
  <img src="/images/plinko/plinko.ex.4.png" />
  <li><b>1</b>表示球向右 (R)。</li>
  <img src="/images/plinko/plinko.ex.5.png" />
</ul>

<div>例如：</div>
<ul>
  <li>
    <b>球 0 的哈希值 </b>(<code>122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc60</code>) 可以转换为路径位字符串，如 <code>01010111</code>, 这表示路径：L R L R
    L R R R.
  </li>
  <li>
    <b>球 1 的哈希值</b> (<code>122715f20500991dd39e0dd16fd5d3c7313c68fa8764955411202f2c9ec93cc61</code>) 可能会生成一个不同的路径位字符串，如 <code>10100100</code>,
    这表示路径：R L R L L R L L.
  </li>
</ul>

<div>你可以使用此路径位验证工具来验证哈希值的路径位：</div>
<a href="https://www.rapidtables.com" target="_blank">https://www.rapidtables.com</a>
`
