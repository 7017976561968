import { AppDrawer } from '@/components/common/AppDrawer'
import { Configs } from '@/const/configs'
import useCustomTranslation from '@/hooks/useCustomTranslation'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { plinkoActions, selectPlinkoTotalOrder } from '@/redux/store/modules/plinko.slice'
import { Button, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import AuthGame from './components/AuthGame'
import { BetActions } from './components/BetActions'
import { BettingHistory } from './components/BetActions/BettingHistory'
import ControlSection from './components/BetActions/ControlSection'
import { PlinkoGameBody } from './components/GameBody'
import { usePlinkoWallet } from './components/GameBody/hooks/usePlinkoWallet'

export function PlinkoGame() {
  return (
    <AuthGame>
      <Stack backgroundColor="transparent" borderBottomRadius={12}>
        <>
          <MainGame />
          <MultiplierSubscription />
        </>
      </Stack>
    </AuthGame>
  )
}

function MultiplierSubscription() {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(plinkoActions.getPlinkoMultipliers())
  }, [])

  useEffect(() => {
    dispatch(plinkoActions.createPlinkoRound())
  }, [])

  return <></>
}

export function MainGame() {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)

  usePlinkoWallet()

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth } = containerRef.current
      setWidth(offsetWidth)
    }
  }, [])

  return (
    <div className="flex flex-col relative">
      <ControlSection />
      <div ref={containerRef} className="flex flex-1 items-center justify-center relative">
        {width > 0 && <PlinkoGameBody width={width} />}
      </div>

      <BetActions />
      <BettingHistoryOnHomePage />
    </div>
  )
}

function BettingHistoryOnHomePage() {
  const { t } = useCustomTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const totalOrder = useAppSelector(selectPlinkoTotalOrder)
  const hasMore = totalOrder > Configs.pageSize

  return (
    <>
      <Flex
        className="mt-4"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid #1a1a1a"
        boxShadow="0px 1px 0px 0px #2b2b2f"
        pb="6px"
      >
        <Text fontSize="15px" color="#E8E8EA" textStyle="semibold">
          {t('bettingRecord')}
        </Text>
        <Button
          aria-label="All"
          aria-labelledby="All"
          _focus={{ bg: 'none' }}
          _hover={{ bg: 'none' }}
          rightIcon={<FaAngleRight style={{ marginBottom: '1px' }} />}
          bg="none"
          color="#56555B"
          onClick={() => {
            onOpen()
          }}
          p={0}
          minWidth="auto"
          height="auto"
          fontSize="12px"
          // position="relative"
          // top="3px"
        >
          {t('all')}
        </Button>
      </Flex>
      <BettingHistory noPaging className="p-0 pt-4" />
      {hasMore && (
        <Stack>
          <Text
            marginBlock={4}
            marginInline={'auto'}
            w={'70px'}
            color="#56555B"
            textAlign={'center'}
            height="auto"
            fontSize="12px"
            cursor={'pointer'}
            onClick={() => {
              onOpen()
            }}
          >
            {t('viewmore')}
          </Text>
        </Stack>
      )}
      <AppDrawer title={t('menu.bettingListing')} isOpen={isOpen} onClose={onClose} overlay>
        <BettingHistory />
      </AppDrawer>
    </>
  )
}
