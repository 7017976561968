import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react'

type RocketGameBackgroundProps = BoxProps

const RocketGameBackground = ({children, ...rest}: RocketGameBackgroundProps) => {
  return (
    <Box
      backgroundColor="#11151b"
      paddingBottom="42px"
      {...rest}
    >
      {children}
    </Box>
  )
}

export default RocketGameBackground;
