import React from 'react';
import { Box, BoxProps, Image } from '@chakra-ui/react'

type MinerProps = BoxProps

const Miner = ({...rest}: MinerProps) => {
  return (
    <Box
      {...rest}
    >
      <Image src="/bingo/images/miner-idling.png" userSelect="none" minWidth="138px" width="138px" height="138px" />
    </Box>
  )
}

export default Miner;