import axios from 'axios'

export function extractGameId(path?: string) {
  if (!path) return ''
  return path.replace(/^\/|\/$/g, '') || ''
}

export const locationCheck = async () => {
  try {
    const cloudflareRes = await axios.get('https://www.cloudflare.com/cdn-cgi/trace')
    const data = cloudflareRes?.data
      ?.trim()
      ?.split('\n')
      ?.reduce((obj: Record<string, string>, value: string) => {
        const pair = value.split('=')
        obj[pair[0]] = pair[1]
        return obj
      }, {})

    return data?.loc
  } catch (error) {
    return undefined
  }
}

export const getAvatar = (playerId: string) => {
  if (!playerId) {
    return '/images/avatar/12.webp'
  }

  const firstNumberObj = playerId.match(/\d/)

  if (!firstNumberObj) {
    return '/images/avatar/11.webp'
  }

  let avatar = firstNumberObj[0]
  if (avatar === '0') {
    avatar = '10'
  }

  return `/images/avatar/${avatar}.webp`
}
